<ng-container [formGroup]="form">
  <ng-container *ngTemplateOutlet="dynamicFieldFormAreas; context : {passFields: getFormFields(), form: form}">
  </ng-container>

  <ng-container *ngIf="separators?.length > 0">

    <ng-container *ngFor="let separator of separators">

      <div class="control-full separator" *ngIf="separator.conditionVisibility">
        <div fxLayout="column" class="separator-header">
          <div fxLayout="row" fxLayoutGap="16px" class="py-2 rounded-t bar cursor-pointer h-[56px]"
            [ngClass]="{'rounded-b': !separator.expandFieldFlag}"
            (click)="separator.expandFieldFlag = !separator.expandFieldFlag">
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" fxLayoutGap.xs="8px">

              <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                <div class="rounded-full separator-icon flex items-center justify-center bg-yellow-500">
                  <net-iconify-icon class="opacity-50 text-white" size="8" [icon]="icCircle"
                    style="color:#f4bd24"></net-iconify-icon>
                </div>
                <h2 class="separator-title">{{separator.displayName}}</h2>
              </div>
              <div fxFlex></div>

              <button fxFlex="5" type="button" mat-icon-button matSuffix>
                <net-iconify-icon [icon]="separator?.expandFieldFlag ? icKeyboardArrowUp : icKeyboardArrowDown"
                  size="20"></net-iconify-icon>
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="separator?.expandFieldFlag">
          <div class="form-fields control-full separator-content">
            <ng-container
              *ngTemplateOutlet="dynamicFieldFormAreas; context : {passFields: separator.separatorFields, form: form}"></ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

</ng-container>

<ng-template #dynamicFieldFormAreas let-passFields="passFields" let-passForm="form">

  <ng-container *ngFor="let field of passFields">
    <mat-form-field *ngIf="fieldTypeIds.SINGLE_LINE_TEXT === field.dynamicFieldTypeId && field.conditionVisibility"
      class="control-grid">
      <mat-label>{{ (entityName + 'DynamicField.' + field.name) | translate }}</mat-label>
      <input [formControl]="passForm.get(field.dynamicFieldId)" [maxlength]="field.maxLength" [readonly]="isDisabled"
        matInput>
    </mat-form-field>

    <mat-form-field *ngIf="fieldTypeIds.URL === field.dynamicFieldTypeId && field.conditionVisibility"
      class="control-grid">
      <mat-label>{{ (entityName + 'DynamicField.' + field.name) | translate }}</mat-label>
      <input [formControl]="passForm.get(field.dynamicFieldId)" [maxlength]="field.maxLength" [readonly]="isDisabled"
        matInput type="url">
      <button (click)="onOpenUrl(field)" *ngIf="passForm.get(field.dynamicFieldId).valid" [disabled]="isDisabled"
        mat-icon-button matSuffix type="button" class="flex items-center justify-center">
        <net-iconify-icon [icon]="icLink" size="13.6"></net-iconify-icon>
      </button>
    </mat-form-field>

    <mat-form-field *ngIf="fieldTypeIds.EMAIL === field.dynamicFieldTypeId && field.conditionVisibility"
      class="control-grid">
      <mat-label>{{ (entityName + 'DynamicField.' + field.name) | translate }}</mat-label>
      <input [formControl]="passForm.get(field.dynamicFieldId)" [readonly]="isDisabled" matInput type="email">
    </mat-form-field>

    <mat-form-field *ngIf="fieldTypeIds.PHONE === field.dynamicFieldTypeId && field.conditionVisibility"
      class="control-grid">
      <mat-label>{{ (entityName + 'DynamicField.' + field.name) | translate }}</mat-label>
      <input [formControl]="passForm.get(field.dynamicFieldId)" [maxlength]="field.dynamicFieldType.maxLength"
        [readonly]="isDisabled" matInput type="phone">
    </mat-form-field>

    <mat-form-field *ngIf="fieldTypeIds.NUMBER === field.dynamicFieldTypeId && field.conditionVisibility"
      class="control-grid">
      <mat-label>{{ (entityName + 'DynamicField.' + field.name) | translate }}</mat-label>
      <input [formControl]="passForm.get(field.dynamicFieldId)" [max]="field.maxValue" [min]="field.minValue"
        [readonly]="isDisabled" matInput mask="separator.{{field.precision ?? 0}}"
        [thousandSeparator]="authService.thousandSeparator" type="text">
      <span *ngIf="field.unit" matSuffix>{{ field.unit }}</span>
      <mat-error *ngIf="passForm.get(field.dynamicFieldId).hasError('min')">
        {{ 'GENERAL.MIN_VALUE' | translate : {value: field.minValue | normalizeDecimalSeparator} }}
      </mat-error>
      <mat-error *ngIf="passForm.get(field.dynamicFieldId).hasError('max')">
        {{ 'GENERAL.MAX_VALUE' | translate : {value: field.maxValue ?? 9007199254740991 | normalizeDecimalSeparator} }}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="isDecimalField(field.dynamicFieldTypeId) && field.conditionVisibility" class="control-grid">
      <mat-label>{{ (entityName + 'DynamicField.' + field.name) | translate }}</mat-label>
      <input [formControl]="passForm.get(field.dynamicFieldId)" [max]="field.maxValue" [min]="field.minValue"
        [readonly]="isDisabled" matInput mask="separator.{{field.precision}}"
        [thousandSeparator]="authService.thousandSeparator" [decimalMarker]="authService.decimalMarker" type="text">
      <span *ngIf="field.dynamicFieldType.symbol" matSuffix>{{ field.dynamicFieldType.symbol }}</span>
      <span *ngIf="field.unit" matSuffix style="margin-left: 5px;">{{ field.unit }}</span>
      <mat-error *ngIf="passForm.get(field.dynamicFieldId).hasError('min')">
        {{ 'GENERAL.MIN_VALUE' | translate : {value: (field.minValue | normalizeDecimalSeparator)} }}
      </mat-error>
      <mat-error *ngIf="passForm.get(field.dynamicFieldId).hasError('max')">
        {{ 'GENERAL.MAX_VALUE' | translate : {value: (field.maxValue | normalizeDecimalSeparator)} }}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="fieldTypeIds.DATE === field.dynamicFieldTypeId && field.conditionVisibility"
      class="control-grid">
      <mat-label>{{ (entityName + 'DynamicField.' + field.name) | translate }}</mat-label>
      <input (click)="datePicker.open()" [formControl]="passForm.get(field.dynamicFieldId)" [matDatepicker]="datePicker"
        [disabled]="isDisabled" matInput readonly>
      <button (click)="onClearValue(field)" *ngIf="passForm.get(field.dynamicFieldId).value" [disabled]="isDisabled"
        mat-icon-button matSuffix readonly type="button">
        <net-iconify-icon [icon]="icClear" size="13.6" class="flex items-center justify-center"></net-iconify-icon>
      </button>
      <mat-datepicker #datePicker [disabled]="isDisabled"></mat-datepicker>
      <mat-datepicker-toggle [disabled]="isDisabled" [for]="datePicker" matSuffix></mat-datepicker-toggle>
    </mat-form-field>

    <mat-form-field *ngIf="fieldTypeIds.DATETIME === field.dynamicFieldTypeId && field.conditionVisibility"
      [ngClass]="isAccountHome ? 'control-full-date-time-account' : 'control-grid'">
      <mat-label>{{ (entityName + 'DynamicField.' + field.name) | translate }}</mat-label>
      <net-input-datetime [formControl]="passForm.get(field.dynamicFieldId)"
        [isDisabled]="isDisabled"></net-input-datetime>
    </mat-form-field>

    <mat-form-field *ngIf="fieldTypeIds.RICH_TEXT === field.dynamicFieldTypeId && field.conditionVisibility"
      class="control-full ck-editor-overflow" style="overflow: hidden;">
      <mat-label>{{ (entityName + 'DynamicField.' + field.name) | translate }}</mat-label>
      <net-input-editor-ck [autoHeight]="true" [formControl]="passForm.get(field.dynamicFieldId)"
        [isDisabled]="isDisabled" [type]="uploadType ?? field.dynamicFieldEntity?.name" [required]="field.requiredFlag"
        class="w-full"></net-input-editor-ck>
    </mat-form-field>

    <mat-form-field *ngIf="fieldTypeIds.MULTI_LINE_TEXT === field.dynamicFieldTypeId && field.conditionVisibility"
      class="control-full">
      <mat-label>{{ (entityName + 'DynamicField.' + field.name) | translate }}</mat-label>
      <textarea [formControl]="passForm.get(field.dynamicFieldId)" [maxlength]="field.maxLength" [readonly]="isDisabled"
        matInput></textarea>
    </mat-form-field>

    <mat-form-field *ngIf="fieldTypeIds.PICKLIST === field.dynamicFieldTypeId && field.conditionVisibility"
      [ngClass]="field.multipleValueFlag ? 'control-full' : 'control-grid'" class="mat-form-picklist">
      <mat-label>{{ (entityName + 'DynamicField.' + field.name) | translate }}</mat-label>
      <mat-select #select (closed)="onClosedSelectPicklist(field)"
        [placeholder]="(entityName + 'DynamicField.' + field.name) | translate"
        (infiniteScroll)="picklistValuesSearchWithScroll(field)" (opened)="field.fieldSelectElement = select"
        [complete]="field.currentPage === field.pageCount" [disabled]="isDisabled"
        [formControl]="passForm.get(field.dynamicFieldId)" [multiple]="field.multipleValueFlag || false"
        msInfiniteScroll panelClass="custom-panel-class">
        <mat-select-trigger *ngIf="field.selectedDynamicFieldPicklistValues?.length > 2">
          {{ 'GENERAL.COUNT_VALUES_SELECTED' | translate:
          {count: field.selectedDynamicFieldPicklistValues.length} }}
        </mat-select-trigger>
        <div class="block py-2 px-4">
          <input (keydown)="checkKey($event)" (keydown.control.a)="$event.stopPropagation()"
            [formControl]="field.picklistValuesKeywordFormControl" [readonly]="isDisabled"
            class="border-0 outline-none w-full bg-card block" [placeholder]="'GENERAL.SEARCH' | translate" type="text">
        </div>
        <div *ngIf="field.multipleValueFlag" class="mat-option-button-group">
          <button (click)="onSelectAllPicklistValues(field)"
            [disabled]="field.picklistValuesLoading || field.picklistValues?.length === 1 || isDisabled"
            mat-raised-button>{{"GENERAL.SELECT_ALL" | translate}}</button>
          <button (click)="onDeselectAllPicklistValues(field)" [disabled]="field.picklistValuesLoading || isDisabled"
            mat-raised-button>{{"GENERAL.REMOVE_ALL" | translate}}</button>
        </div>
        <mat-option (click)="onSelectedPickList(item.dynamicFieldPickListId, field)"
          *ngFor="let item of getPicklistValuesFiltered(field)"
          [class.mdc-list-item--disabled]="(!item.activeFlag || !item.conditionVisibility) && isDisabledPicklistValues(item, field)"
          [disabled]="isDisabled" [value]="item.dynamicFieldPickListId">
          {{ (entityName +'_'+ field.name +'.'+item.name) | translate }}
        </mat-option>
        <mat-option *ngIf="field.picklistValues?.length === 0" [disabled]="isDisabled"
          disabled>{{'GENERAL.NO_RESULT_FOUND' | translate }}</mat-option>
      </mat-select>
      <mat-progress-spinner *ngIf="field.picklistValuesLoading" class="custom-spinner" diameter="16"
        mode="indeterminate"></mat-progress-spinner>
    </mat-form-field>
  </ng-container>
</ng-template>