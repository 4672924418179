import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BoardDetailState } from './board-detail.reducer';

export const boardDetailState = createFeatureSelector('boardDetail');

export const getBoardDetail = createSelector(
  boardDetailState,
  (state: BoardDetailState) => state
);
export const getBoardDetailSystemUsers = createSelector(
  boardDetailState,
  (state: BoardDetailState) => state.boardSystemUsers
);

export const getBoardDetailCreatedUsers = createSelector(
  boardDetailState,
  (state: BoardDetailState) => state.createdUsers
);

export const getBoardDetailAssignedUsers = createSelector(
  boardDetailState,
  (state: BoardDetailState) => state.assignedUsers
);

export const getBoardDetailHashtags = createSelector(
  boardDetailState,
  (state: BoardDetailState) => state.boardHashtags
);

export const getBoardDetailBoardInfos = createSelector(
  boardDetailState,
  (state: BoardDetailState) => state.boardInfos
);

export const getBoardDetailBoard = createSelector(
  boardDetailState,
  (state: BoardDetailState) => state.board
);
