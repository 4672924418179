<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="m-0" fxFlex="auto">
        <span>{{ 'GENERAL.PHONE' | translate }}</span>
    </h2>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <net-iconify-icon [icon]="icClose"></net-iconify-icon>
    </button>
</div>
<mat-divider class="-mx-6 mb-2 text-border"></mat-divider>
<mat-dialog-content fxLayout="column">
    <ng-container [formGroup]="phoneForm">
        <mat-form-field fxFlex floatLabel="always">
            <mat-label>{{ 'GENERAL.PHONE' | translate }}</mat-label>
            <net-input-customer-phones formControlName="phone" [type]="'customer'" [customer]="customer"
                [hideInternal]="true" [hideControls]="true"></net-input-customer-phones>
        </mat-form-field>
    </ng-container>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
    <net-progress-button (btnClick)="onSubmit()" [active]="loading" [color]="'primary'" [icon]="icSave"
        [disabled]="!phoneForm.valid" [text]="'GENERAL.SAVE' | translate"></net-progress-button>
</mat-dialog-actions>