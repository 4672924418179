import { CustomerExperienceLinkType } from '@core/api';
import { TranslateService } from '@ngx-translate/core';

export function getTicketLinkTypeName(mailLinkTypeId: string, translate: TranslateService): string {
    switch (mailLinkTypeId) {
        case CustomerExperienceLinkType.CARD: return translate.instant('GENERAL.MAIL_LINK_TYPE_CARD');
        case CustomerExperienceLinkType.CONTACT: return translate.instant('GENERAL.MAIL_LINK_TYPE_CONTACT');
        case CustomerExperienceLinkType.CUSTOMER: return translate.instant('GENERAL.MAIL_LINK_TYPE_CUSTOMER');
        case CustomerExperienceLinkType.ACTIVITY: return translate.instant('GENERAL.MAIL_LINK_TYPE_ACTIVITY');
        case CustomerExperienceLinkType.LEAD_DRAFT: return translate.instant('GENERAL.MAIL_LINK_TYPE_LEAD_DRAFT');
        case CustomerExperienceLinkType.ASSIGNMENT: return translate.instant('GENERAL.MAIL_LINK_TYPE_ASSIGNMENT');
        case CustomerExperienceLinkType.OPPORTUNITY: return translate.instant('GENERAL.MAIL_LINK_TYPE_OPPORTUNITY');
    }

    return 'N/A';
}
