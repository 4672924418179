import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    CustomerExperienceSLA, CustomerExperienceSLADeleteRequest, CustomerExperienceSLAInsertRequest, CustomerExperienceSLASearchRequest,
    CustomerExperienceSLASetOrderRequest,
    CustomerExperienceSLASetStatusRequest, CustomerExperienceSLAupdateRequest, FilterRequest, ListCreatedUser, PagedResponse, Response,
    SLABeginTimeType,
    SLAEndTimeType
} from '@core/api';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CustomerExperienceSLAService {

    static getTimeDefinitionTranslateKey(typeId: string) {
        switch (typeId) {
            case SLABeginTimeType.ASSIGNED_DATE:
                return 'ASSIGNED_DATE';
            case SLABeginTimeType.CREATION_DATE:
                return 'CREATION_DATE';
            case SLAEndTimeType.FIRST_RESPONSE_DATE:
                return 'FIRST_RESPONSE_DATE';
            case SLAEndTimeType.RESOLUTION_DATE:
                return 'COMPLETION_DATE';
            default:
                return undefined;
        }
    }

    static calculateSlaValue(passingTimeSeconds: number, slaTimeSeconds: number) {
        if (typeof passingTimeSeconds !== 'number' || typeof slaTimeSeconds !== 'number') {
            return 0;
        }

        if (passingTimeSeconds > slaTimeSeconds) {
            return 100;
        }

        return Math.round((passingTimeSeconds / slaTimeSeconds) * 100);
    }

    constructor(private http: HttpClient) { }

    search(request: CustomerExperienceSLASearchRequest): Observable<PagedResponse<CustomerExperienceSLA>> {
        return this.http.post<PagedResponse<CustomerExperienceSLA>>(environment.apiUrl + '/api/CxSla/Search', request);
    }

    get(uuid: string): Observable<Response<CustomerExperienceSLA>> {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<CustomerExperienceSLA>>(environment.apiUrl + '/api/CxSla/Get', JSON.stringify(uuid), { headers });
    }

    insert(request: CustomerExperienceSLAInsertRequest): Observable<Response<CustomerExperienceSLA>> {
        return this.http.post<Response<CustomerExperienceSLA>>(environment.apiUrl + '/api/CxSla/Insert', request);
    }

    update(request: CustomerExperienceSLAupdateRequest): Observable<Response<CustomerExperienceSLA>> {
        return this.http.post<Response<CustomerExperienceSLA>>(environment.apiUrl + '/api/CxSla/Update', request);
    }

    updateStatus(request: CustomerExperienceSLASetStatusRequest): Observable<Response<CustomerExperienceSLA>> {
        return this.http.post<Response<CustomerExperienceSLA>>(environment.apiUrl + '/api/CxSla/SetStatus', request);
    }

    setOrderBy(request: CustomerExperienceSLASetOrderRequest): Observable<Response<CustomerExperienceSLA>> {
        return this.http.post<Response<CustomerExperienceSLA>>(environment.apiUrl + '/api/CxSla/SetOrderBy', request);
    }

    delete(request: CustomerExperienceSLADeleteRequest): Observable<Response<CustomerExperienceSLA>> {
        return this.http.post<Response<CustomerExperienceSLA>>(environment.apiUrl + '/api/CxSla/Delete', request);
    }

    listCreatedUsers(filter?: FilterRequest): Observable<Response<ListCreatedUser[]>> {
        return this.http.post<Response<ListCreatedUser[]>>(environment.apiUrl + '/api/CxSla/ListCreatedUsers', filter);
    }

}
