import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import {
  Response,
  PagedResponse,
  FilterRequest,
  ListCreatedUser,
} from '@core/api';
import {
  SurveyMail,
  SurveyMailRequest,
  SurveyMailFilterRequest,
  MailContactCount
} from './survey-mail.model';

@Injectable({
  providedIn: 'root'
})
export class SurveyMailService {
  constructor(private http: HttpClient) { }

  search(request: SurveyMailFilterRequest): Observable<PagedResponse<SurveyMail>> {
    return this.http.post<PagedResponse<SurveyMail>>(environment.apiUrl + '/api/SurveyMailList/Search', request);
  }

  list(): Observable<PagedResponse<SurveyMail>> {
    return this.http.post<PagedResponse<SurveyMail>>(environment.apiUrl + '/api/SurveyMailList/List', null);
  }

  get(uuid: string): Observable<Response<SurveyMail>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<SurveyMail>>(environment.apiUrl + '/api/SurveyMailList/Get', JSON.stringify(uuid), { headers });
  }

  insert(request: SurveyMailRequest): Observable<Response<SurveyMail>> {
    return this.http.post<Response<SurveyMail>>(environment.apiUrl + '/api/SurveyMailList/Insert', request);
  }

  update(request: SurveyMailRequest): Observable<Response<SurveyMail>> {
    return this.http.post<Response<SurveyMail>>(environment.apiUrl + '/api/SurveyMailList/Update', request);
  }

  updateStatus(request: SurveyMailRequest): Observable<Response<SurveyMail>> {
    return this.http.post<Response<SurveyMail>>(environment.apiUrl + '/api/SurveyMailList/UpdateActiveStatus', request);
  }

  delete(surveyMailListId): Observable<Response<SurveyMail>> {
    return this.http.post<Response<SurveyMail>>(environment.apiUrl + '/api/SurveyMailList/Delete', { surveyMailListId });
  }

  getActivitySurveyStatus(salesOrganizationId: string, activityTypeId: string): Observable<Response<boolean>> {
    return this.http.post<Response<boolean>>(environment.apiUrl + '/api/SurveyMailList/GetActivitySurveyStatus', { salesOrganizationId, activityTypeId });
  }

  getMailContactCountLimit(request: SurveyMailRequest): Observable<Response<MailContactCount>> {
    return this.http.post<Response<MailContactCount>>(environment.apiUrl + '/api/SurveyMailList/GetMailContactCountLimit', request);
  }

  listCreatedUsers(filter?: FilterRequest): Observable<Response<ListCreatedUser[]>> {
    return this.http.post<Response<ListCreatedUser[]>>(environment.apiUrl + '/api/SurveyMailList/ListCreatedUsers', filter);
  }
}
