import { AbstractControl, ValidationErrors } from '@angular/forms';

export class NoSpaceValidator {
    static validator(control: AbstractControl): ValidationErrors | null {
        const value = control.value || '';
        const hasSpaces = /\s/.test(value);

        return hasSpaces ? { noSpaces: true } : null;
    }
}
