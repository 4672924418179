import { Injectable } from '@angular/core';
import { BoardService } from '@core/api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BoardDetailActionTypes, GetBoardDetailAssignedUsers, GetBoardDetailCreatedUsers, SetBoardDetailAssignedUsers, SetBoardDetailCreatedUsers } from './board-detail.action';
import { mergeMap, of, switchMap } from 'rxjs';

@Injectable()
export class BoardDetailEffect {

    constructor(
        private boardService: BoardService,
        private actions$: Actions,
    ) { }

    createdUserlist$ = createEffect(() => this.actions$.pipe(
        ofType(BoardDetailActionTypes.GET_BOARD_DETAIL_CREATED_USERS),
        mergeMap((request: GetBoardDetailCreatedUsers) => this.boardService.listCreatedUsers({ filter: { boardId: request.boardId } })),
        switchMap(response => {
            return of(new SetBoardDetailCreatedUsers(response.data));
        })

    ));

    assignedUserlist$ = createEffect(() => this.actions$.pipe(
        ofType(BoardDetailActionTypes.GET_BOARD_DETAIL_ASSIGNED_USERS),
        mergeMap((request: GetBoardDetailAssignedUsers) => this.boardService.listAssignedUsers({ filter: { boardId: request.boardId } })),
        switchMap(response => {
            return of(new SetBoardDetailAssignedUsers(response.data));
        })

    ));

}
