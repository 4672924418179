import { debounceTime, startWith, skip } from 'rxjs/operators';

import { Component, EventEmitter, Input, Output, OnInit, AfterViewInit, ChangeDetectorRef, inject, DestroyRef } from '@angular/core';
import { AbstractControl, ValidatorFn, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  Account,
  Assignment,
  AssignmentService,
  AssignmentStatusOptions,
  DocumentTypeEnum,
  DocumentTypeOption,
  DynamicFieldEntityId,
  SystemUserParameter,
  User,
  processDynamicFieldValues,
  AssignmentBulkInsertRequest,
  AssignmentRequest,
  Opportunity,
  SalesOrganization,
  SystemUserParameterService,
  AssignmentBulkInsert,
  MailLinkType,
  AssignmentDateProperty,
  AssignmentRepeat,
  CronObject, CustomerExperienceLinkType,
  AccountService,
  AssignmentStatusService,
  DynamicFieldEntityAsString
} from '@core/api';
import { Permission } from '@core/auth/auth.enum';
import { getUser, hasUserPermission } from '@core/store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { scrollToError } from 'src/app/shared/utils/scroll-to-error';
import { DocumentUploadType } from '../document-upload/document-upload.component.enum';
import { DocumentUploadOptions } from '../document-upload/document-upload.component.model';
import moment from 'moment';
import { KeyValue } from '@angular/common';
import { DayOption, EnumRepeatCycle, FirstLastOption } from 'src/app/assignment/assignment.models';
import { Icon } from '@shared/enums';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SharedModule } from '@shared/modules';
import { SelectSalesOrganizationComponent } from '../select-sales-organization/select-sales-organization.component';
import { SelectAccountComponent } from '../select-account/select-account.component';
import { MultiSelectUserComponent } from '../multi-select-user/multi-select-user.component';
import { InputDynamicFieldValuesComponent } from '../input-dynamic-field-values/input-dynamic-field-values.component';
import { SelectAssignmentPriorityComponent } from '../select-assignment-priority/select-assignment-priority.component';
import { MailLinkListComponent } from '../mail-link-list/mail-link-list.component';
import { TicketLinkListComponent } from '../ticket-link-list/ticket-link-list.component';
import { AssignmentDocumentComponent } from '../assignment-document/assignment-document.component';
import { DocumentUploadComponent } from '../document-upload/document-upload.component';
import { isEqual } from 'lodash';

@Component({
  selector: 'net-assignment-form[account]',
  templateUrl: './assignment-form.component.html',
  styleUrls: ['./assignment-form.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    SelectSalesOrganizationComponent,
    SelectAccountComponent,
    MultiSelectUserComponent,
    InputDynamicFieldValuesComponent,
    SelectAssignmentPriorityComponent,
    MailLinkListComponent,
    TicketLinkListComponent,
    AssignmentDocumentComponent,
    DocumentUploadComponent
  ]
})
export class AssignmentFormComponent implements OnInit, AfterViewInit {

  icSave = Icon.IC_SAVE;
  icClose = Icon.IC_TWOTONE_CLOSE;
  icInfo = Icon.IC_INFO_OUTLINE;
  assignmentEditPermission = false;
  assignmentUpdateAllUserPermission = false;
  accountSearchPermission = false;
  recurringTaskInsertPermission = false;
  salesOrganization: SalesOrganization;
  openSeparator = false;

  @Input() loading = false;
  @Input() opportunity: Opportunity;
  @Input() isAccountEnabled: boolean;
  @Input() dialogClose: boolean;

  @Output() save = new EventEmitter<AssignmentBulkInsert | Assignment>();

  submit = '';
  uploadType = DocumentUploadType;
  mailLinkTypes = MailLinkType;
  ticketLinkTypes = CustomerExperienceLinkType;
  uploaderOptions: DocumentUploadOptions = { maxFileSize: 26214400 };
  systemUserParameter: SystemUserParameter;
  documentTypeOption = DocumentTypeOption;
  DynamicFieldEntityId = DynamicFieldEntityId;
  DynamicFieldEntityAsString = DynamicFieldEntityAsString;
  form: UntypedFormGroup;
  isPage = false;
  isDialog = false;
  user: User;
  hasDocumentViewPermission = false;
  hasMailLinkSearchPermission = false;
  hasSearchCXLinkPermitted = false;
  confirmButtonText: string;
  assignmentStatus = AssignmentStatusOptions;
  private _assignment: Assignment = undefined;
  private _account: Account = undefined;
  today = moment(new Date()).toDate();

  EnumRepeatCycle: typeof EnumRepeatCycle = EnumRepeatCycle;
  FirstLastOption: typeof FirstLastOption = FirstLastOption;
  DayOption: typeof DayOption = DayOption;

  repeatValues: KeyValue<number, string>[] = [
    { key: EnumRepeatCycle.Weekly, value: this.translate.instant('TASK.Weekly') },
    { key: EnumRepeatCycle.Monthly, value: this.translate.instant('TASK.Monthly') }
  ];

  deadlineDays: KeyValue<number, string>[] = [
    { key: 1, value: this.translate.instant('GENERAL.Monday') },
    { key: 2, value: this.translate.instant('GENERAL.Tuesday') },
    { key: 3, value: this.translate.instant('GENERAL.Wednesday') },
    { key: 4, value: this.translate.instant('GENERAL.Thursday') },
    { key: 5, value: this.translate.instant('GENERAL.Friday') },
    { key: 6, value: this.translate.instant('GENERAL.Saturday') },
    { key: 7, value: this.translate.instant('GENERAL.Sunday') }
  ];

  options: KeyValue<number, string>[] = [
    { key: FirstLastOption.First, value: this.translate.instant('TASK.FIRST') },
    { key: FirstLastOption.Last, value: this.translate.instant('TASK.LAST') }
  ];

  dayOptions: KeyValue<number, string>[] = [
    { key: DayOption.Day, value: this.translate.instant('TASK.DAY') },
    { key: DayOption.Weekday, value: this.translate.instant('TASK.WEEKDAY') }
  ];

  assignmentDates: AssignmentDateProperty[] = [];

  isStartDateChange = false;
  skipFirstValueIfTicketHasAccount = undefined;
  assignmentStatuses: KeyValue<string, string>[];

  @Input()
  set formChanged(value: boolean) {
    this._formChanged = value;
  }
  get formChanged(): boolean {
    return this._formChanged;
  }

  @Output() isFormChange = new EventEmitter<boolean>();
  @Input() closeAssignmentFormDialog: () => void;
  initialFormValue: any;
  isInitialized = false;
  private _formChanged = false;

  readonly destroyRef = inject(DestroyRef);
  constructor(
    private store: Store,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private assignmentService: AssignmentService,
    private accountService: AccountService,
    private systemUserParameterService: SystemUserParameterService,
    private assigmentStatusService: AssignmentStatusService,
    private cdr: ChangeDetectorRef
  ) {
    this.store.select(getUser).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => this.user = user);
    this.store.select(hasUserPermission(Permission.DOCUMENT_SEARCH))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(response => this.hasDocumentViewPermission = response);
    this.store.select(hasUserPermission(Permission.ASSIGNMENT_UPDATE)).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(permissionCode => this.assignmentEditPermission = permissionCode);

    this.store.select(hasUserPermission(Permission.ASSIGNMENT_UPDATE_ALL_USER_AUTHORIZED_ASSIGNMENTS)).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(permissionCode => this.assignmentUpdateAllUserPermission = permissionCode);

    this.store
      .select(hasUserPermission(Permission.MAIL_LINK_LIST_SEARCH))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(response => this.hasMailLinkSearchPermission = response);

    this.store.select(hasUserPermission(Permission.RECURRING_TASK_INSERT))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(permissionCode => this.recurringTaskInsertPermission = permissionCode);

    this.store.select(hasUserPermission(Permission.CUSTOMER_EXPERIENCE_LINK_LIST_SEARCH))
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(hasSearchCXLinkPermitted => this.hasSearchCXLinkPermitted = hasSearchCXLinkPermitted);

    this.store.select(hasUserPermission(Permission.ACCOUNT_SEARCH))
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(permission => this.accountSearchPermission = permission);

    this.form = this.formBuilder.group({
      description: [null, Validators.required],
      assignedUser: [{ value: [], disabled: true }, Validators.required],
      dueDate: [null, Validators.required],
      assignmentStatusId: [AssignmentStatusOptions.ASSIGNED, Validators.required],
      explanation: [null],
      salesOrganization: [null, Validators.required],
      account: [null],
      assignmentPriorityId: [null],
      dynamicFieldValues: [null],

      isRecurring: [false],
      repeatOption: [{ value: EnumRepeatCycle.Weekly, disabled: true }, Validators.required],
      deadlineDays: [{ value: null, disabled: true }, Validators.required], // weekly option
      repeatEvery: [{ value: 1, disabled: true }, [Validators.required, Validators.min(1)]], // monthly option
      selectedMonthlyOption: ['1'], // monthly option
      onDay: [{ value: 1, disabled: true }, [Validators.max(31), Validators.min(1)]], // monthly option
      monthlyOption2_1: [FirstLastOption.First], // monthly option
      monthlyOption2_2: [this.deadlineDays[0].key], // monthly option
      monthlyOption3_1: [FirstLastOption.First], // monthly option
      monthlyOption3_2: [DayOption.Weekday], // monthly option
      assignmentDate: [{ value: null, disabled: true }, Validators.required], // switch true
      recurringStartDate: [{ value: this.today, disabled: true }, Validators.required], // switch true
      recurringEndDate: [{ value: this.getInitialEndDateWeekly(), disabled: true }, Validators.required], // switch true
    }, { validator: this.dateRangeValidator(2) });

    this.form.get('repeatOption').valueChanges.subscribe(value => {
      if (value === EnumRepeatCycle.Weekly && this.form.get('isRecurring').value) { // weekly
        this.enableControls(['deadlineDays']);
        this.disableControls(['repeatEvery', 'onDay']);
        this.form.patchValue({
          recurringEndDate: this.getInitialEndDateWeekly(this.form.get('recurringStartDate').value)
        });
      }

      if (value === EnumRepeatCycle.Monthly && this.form.get('isRecurring').value) { // monthly
        this.disableControls(['deadlineDays']);
        this.enableControls(['repeatEvery', 'onDay']);
        this.form.patchValue({
          recurringEndDate: this.getInitialEndDateMonthly(this.form.get('recurringStartDate').value)
        });
      }
    });

    this.form.get('salesOrganization').valueChanges.pipe(debounceTime(400)).subscribe(value => {
      if (!this.assignment) {
        if (value) {
          this.form.get('assignedUser').enable();
        } else {
          this.form.get('assignedUser').disable();
        }

        if (this.skipFirstValueIfTicketHasAccount === false) {
          if (this.form.get('account').value) {
            this.form.patchValue({ account: null });
          }
        }

        this.skipFirstValueIfTicketHasAccount = false;
      }
    });

    this.form.get('account').valueChanges
      .pipe(startWith(null as string))
      .subscribe(value => {
        if (value && value?.salesRouteDefinition) {
          this.form.patchValue({
            assignedUser: [value.salesRouteDefinition.assignedUser]
          });
        }
      });

    this.confirmButtonText = this.translate.instant('GENERAL.CONFIRM');

    this.assignmentService.getAssignmentDateProperty().subscribe(res => {
      this.assignmentDates = res.data;
      if (!this.form.get('assignmentDate').value) {
        this.form.patchValue({
          assignmentDate: this.assignmentDates[0].assignmentDatePropertyId
        });
      }
    });
  }

  get account(): Account {
    return this._account;
  }

  @Input()
  set account(account: Account) {

    if (account) {
      this.form.patchValue({
        salesOrganization: account.salesOrganization,
        account
      });

      if (!this.isAccountEnabled) {
        this.form.get('account').disable();
        this.form.get('salesOrganization').disable();
      }
    }
  }

  @Input()
  set mode(value: 'page' | 'dialog') {
    if (value === 'page') {
      this.isPage = true;
    }

    if (value === 'dialog') {
      this.isDialog = true;
    }
  }

  get assignment(): Assignment {
    return this._assignment;
  }

  @Input()
  set assignment(assignment: Assignment) {
    this._assignment = assignment;

    if (assignment) {
      this.form.patchValue({
        description: this.assignment.description,
        assignedUser: [this.assignment.assignedUser],
        dueDate: this.assignment?.dueDate,
        assignmentStatusId: this.assignment.assignmentStatusId,
        explanation: this.assignment.explanation,
        salesOrganization: this.assignment.salesOrganization,
        account: this.assignment.account,
        assignmentPriorityId: this.assignment.assignmentPriorityId,
        dynamicFieldValues: this.assignment?.dynamicFieldValues ?? [],
        isRecurring: this.assignment.recurringTaskFlag
      });

      if (this.isReadOnly()) {
        this.form.disable();
      } else {

        if (!this.assignmentUpdateAllUserPermission && !this.isUserIncludesAssignment()) {
          this.form.disable();
          return;
        }

        if ((this.assignment.createdBy === this.user.userId && this.assignmentEditPermission) || this.assignmentUpdateAllUserPermission) {
          this.form.get('description').enable();
        } else {
          this.form.get('description').disable();
        }

        this.form.get('salesOrganization').disable();
        if (this.user.userId !== this.assignment.createdBy && !this.assignmentUpdateAllUserPermission) {
          this.form.get('dueDate').disable();
        }
        this.form.get('assignedUser').disable();
        this.form.get('account').disable();

        if ((this.assignmentEditPermission && this.assignment.createdBy === this.user.userId) || this.assignmentUpdateAllUserPermission) {
          this.form.get('assignmentPriorityId').enable();
        } else {
          this.form.get('assignmentPriorityId').disable();
        }

        if (
          this.user.userId !== this.assignment.actionUser?.systemUserId
          && this.user.userId !== this.assignment.assignedUser?.systemUserId
          && this.user.userId !== this.assignment.createdBy && !this.assignmentUpdateAllUserPermission
        ) {
          this.disableControls(['assignmentStatusId']);
        }
      }
    }

    if (!this.isAddForm() || !this.recurringTaskInsertPermission) {
      this.form.get('isRecurring').disable();
    }
  }

  @Input()
  set ticketDetail(ticketDetail: { subject: string, account?: Account, ticketId: string }) {
    if (ticketDetail) {
      this.form.patchValue({
        description: ticketDetail.subject,
      });

      if (ticketDetail.account) {
        this.accountService.get(ticketDetail.account.accountId).subscribe(res => {
          this.form.patchValue({
            salesOrganization: ticketDetail.account.salesOrganization,
            account: res.data
          });
          this.skipFirstValueIfTicketHasAccount = true;
        });
      }
    }

    this._ticketDetail = ticketDetail;
  }

  get ticketDetail(): { subject: string, account?: Account, ticketId: string } {
    return this._ticketDetail;
  }

  private _ticketDetail: { subject: string, account?: Account, ticketId: string };

  ngOnInit() {
    if (this.opportunity) {
      const param = `opportunityId=${this.opportunity.opportunityId}`;
      this.uploaderOptions.queryString = `?documentTypeId=${DocumentTypeEnum.ASSIGNMENT}&${param}`;
      this.systemUserParameterService.search({
        systemUserId: this.user.userId,
        opportunityId: this.opportunity.opportunityId
      }).subscribe(response => {
        this.systemUserParameter = response.data;
      });
      this.form.patchValue({
        salesOrganization: this.opportunity.account.salesOrganization,
        account: this.opportunity.account,
        assignedUser: [this.opportunity.opportunitySystemUsers[0]?.systemUser ?? this.opportunity.createdUser]
      });
      this.form.get('account').disable();
      this.form.get('salesOrganization').disable();
    }

    this.form.get('isRecurring').valueChanges.subscribe(value => {
      this.disableControls(['deadlineDays', 'repeatEvery', 'onDay']);
      if (this.isAddForm()) {
        if (value) {
          this.enableControls(['repeatOption', 'assignmentDate', 'recurringStartDate', 'recurringEndDate']);
          this.disableControls(['dueDate']);
        }
        else {
          this.disableControls(['repeatOption', 'assignmentDate', 'recurringStartDate', 'recurringEndDate']);
          this.enableControls(['dueDate']);
        }
      }
    });

    this.form.get('assignmentDate').valueChanges.subscribe(value => {
      // kullanıcı manuel date seçti ise değer değiştirilmeyecek
      // Task - Recurring task için Start date alanında gelen default değerin, Assignment date alanında yazan değer kadar arttırılması
      if (!this.isStartDateChange) {
        const dayDifference = this.getAssignmentDateValue(value);

        const newDate = new Date(this.today);
        newDate.setDate(this.today.getDate() + dayDifference);

        this.form.patchValue({ recurringStartDate: newDate });
      }
    });

    this.form.get('recurringStartDate').valueChanges.subscribe(value => {
      // moment ise kullanıcı seçimidir
      if (moment.isMoment(value)) {
        this.isStartDateChange = true;
      }
    });

    this.getAssignmentStatuses();
  }


  ngAfterViewInit(): void {
    if (!this.isInitialized) {
      this.initialFormValue = this.form.getRawValue();
      this.isInitialized = true;
      this.formChangeDetection();
    }
  }

  private formChangeDetection() {
    this.form.valueChanges.pipe(
      debounceTime(400),
      takeUntilDestroyed(this.destroyRef),
      skip(1)
    ).subscribe(() => {
      const currentValue = this.form.getRawValue();
      const hasChanges = !isEqual(this.initialFormValue, currentValue);

      if (this.formChanged !== hasChanges) {
        this.formChanged = hasChanges;
        this.isFormChange.emit(hasChanges);
      }
    });
  }

  getAssignmentStatuses() {
    this.assigmentStatusService.list({ filter: {} }).subscribe(res => {
      this.assignmentStatuses = res.data.map(status => ({ key: status.assignmentStatusId, isActive: status.isActive, value: this.translate.instant('AssignmentStatus.' + status.name) }));
    });
  }

  isReadOnly() {
    const readOnly = [this.assignmentStatus.CANCEL, this.assignmentStatus.COMPLETE, this.assignmentStatus.REJECTED, this.assignmentStatus.SERIES_CANCELLED];
    return readOnly.includes((this.assignment?.assignmentStatusId || this.assignment?.assignmentStatus?.assignmentStatusId) as AssignmentStatusOptions);
  }

  isUserIncludesAssignment() {
    return [this.assignment.createdBy, this.assignment.assignedUser.systemUserId].includes(this.user.userId);
  }

  checkStatusVisibility(status: { key?: string, isActive?: boolean, value?: string }) {
    if (this.form.disabled) {
      return true;
    }

    const isSelected = this.form.get('assignmentStatusId').value === status.key;

    if (isSelected && !status.isActive) {
      this.form.get('assignmentStatusId').setErrors({ required: true, disableItemSelected: true });
    }

    switch (status.key) {
      case this.assignmentStatus.COMPLETE:
      case this.assignmentStatus.REJECTED:
        return (this.assignment.assignedUser && this.user.userId === this.assignment.assignedUser.systemUserId) || this.assignmentUpdateAllUserPermission;
      case this.assignmentStatus.CANCEL:
        return (this.assignment.assignedUser && this.assignment.createdBy === this.user.userId) || this.assignmentUpdateAllUserPermission;
      case this.assignmentStatus.SERIES_CANCELLED:
        return ((this.assignment.assignedUser && this.assignment.createdBy === this.user.userId) || this.assignmentUpdateAllUserPermission) &&
          this.assignment.recurringTaskFlag;
      default:
        return isSelected || status.isActive;
    }
  }

  private getAssignmentDateValue(propertyId: string) {
    const assignmentDate = this.assignmentDates.find(ad => ad.assignmentDatePropertyId === propertyId);

    return assignmentDate?.value || 0;
  }

  insertAssignment() {
    this.loading = true;
    const request = this.getInsertRequest();
    this.assignmentService.bulkInsert(request).subscribe(response => {
      if (response.success) {
        this.submit = `?assignmentId=${response.data[0].assignmentId}&documentTypeId=${DocumentTypeEnum.Other}`;
        this.save.emit(response.data);
      } else {
        this.loading = false;
      }
    });
  }

  getAssignedUsers() {

    const users = this.form.get('assignedUser').value.map(item => {
      return item.systemUserId;
    });

    return users;
  }

  updateAssignment() {
    this.loading = true;
    const request = this.getRequest();

    this.assignmentService.update(request).subscribe(response => {
      this.loading = false;
      if (response.success) {
        this.save.emit(response.data);
      }
    });
  }

  private getRequest(): AssignmentRequest {
    // Create request object
    const request: AssignmentRequest = {
      description: this.form.get('description').value,
      assignedUserId: this.form.get('assignedUser').value[0].systemUserId,
      dueDate: moment(this.form.get('dueDate').value).format('YYYY/MM/DD'),
      assignmentStatusId: this.form.get('assignmentStatusId').value,
      explanation: this.form.get('explanation').value,
      actionUserId: this.assignment ? this.assignment.actionUser.systemUserId : this.user.userId,
      actionDate: this.assignment ? this.assignment.actionDate : new Date().toISOString(),
      salesOrganizationId: this.form.get('salesOrganization').value.salesOrganizationId,
      accountId: this.form.get('account').value?.accountId,
      assignmentPriorityId: this.form.get('assignmentPriorityId').value,
      dynamicFieldValues: processDynamicFieldValues(this.form.get('dynamicFieldValues')?.value),
      recurringTaskFlag: this.assignment.recurringTaskFlag
    };
    if (this.opportunity) {
      request.opportunityId = this.opportunity.opportunityId;
    }

    if (this.assignment) {
      request.assignmentId = this.assignment.assignmentId;

      if (this.assignment.assignmentStatusId !== this.form.get('assignmentStatusId').value) {
        request.actionUserId = this.user.userId;
        request.actionDate = new Date().toISOString();
      }
    }
    return request;
  }

  private getInsertRequest(): AssignmentBulkInsertRequest {
    // Create request object
    const request: AssignmentBulkInsertRequest = {
      description: this.form.get('description').value,
      assignedUserIds: this.getAssignedUsers(),
      dueDate: this.form.get('isRecurring').value ? null : moment(this.form.get('dueDate').value).format('YYYY/MM/DD'),
      assignmentStatusId: this.form.get('assignmentStatusId').value,
      explanation: this.form.get('explanation').value,
      actionUserId: this.assignment ? this.assignment.actionUser.systemUserId : this.user.userId,
      actionDate: this.assignment ? this.assignment.actionDate : new Date().toISOString(),
      salesOrganizationId: this.form.get('salesOrganization').value.salesOrganizationId,
      accountId: this.form.get('account').value?.accountId,
      assignmentPriorityId: this.form.get('assignmentPriorityId').value,
      dynamicFieldValues: processDynamicFieldValues(this.form.get('dynamicFieldValues')?.value),
      recurringTaskFlag: this.form.get('isRecurring').value,
      repeat: this.getRepeatField(),
      ...this.ticketDetail ? { ticketId: this.ticketDetail.ticketId } : {}
    };
    if (this.opportunity) {
      request.opportunityId = this.opportunity.opportunityId;
    }
    if (this.assignment) {
      request.assignmentId = this.assignment.assignmentId;

      if (this.assignment.assignmentStatusId !== this.form.get('assignmentStatusId').value) {
        request.actionUserId = this.user.userId;
        request.actionDate = new Date().toISOString();
      }
    }
    return request;
  }

  private getRepeatField() {
    if (this.form.get('isRecurring').value) {
      const repeatValue: AssignmentRepeat = {
        assignmentDatePropertyId: this.form.get('assignmentDate').value,
        startDate: `${moment(this.form.get('recurringStartDate').value).format('YYYY-MM-DD')}T00:00:00.000Z`,
        endDate: `${moment(this.form.get('recurringEndDate').value).format('YYYY-MM-DD')}T00:00:00.000Z`,
        repeat: EnumRepeatCycle[this.form.get('repeatOption').value],
        cron: this.getCronString(),
        cronObject: this.getCronObject()
      };
      return repeatValue;
    }
    return null;
  }

  private getCronString() {
    const repeatOption = this.form.get('repeatOption').value;

    if (repeatOption === EnumRepeatCycle.Weekly) {
      return `0 0 * * ${this.form.get('deadlineDays').value.join(',')}`; // Example: 0 0 * * 1,2,5
    }

    if (repeatOption === EnumRepeatCycle.Monthly) {
      switch (this.form.get('selectedMonthlyOption').value) {
        case '1':
          return `0 0 ${this.form.get('onDay').value} */${this.form.get('repeatEvery').value} *`; // Example: 0 0 27 */3 *
        case '2':
          return `0 0 * */${this.form.get('repeatEvery').value} ${this.getDayCron(2)}`; // Example: 0 0 * */3 1L || 0 0 * */3 3#1
        case '3':
          return `0 0 ${this.getDayCron(3)} */${this.form.get('repeatEvery').value} *`; // Example: 0 0 1W */3 * || 0 0 LW */3 *
        default:
          break;
      }
    }

    return null;
  }

  private getDayCron(option: 2 | 3) {
    if (option === 2) {
      if (this.form.get('monthlyOption2_1').value === FirstLastOption.First) {
        return `${this.form.get('monthlyOption2_2').value}#1`;
      }

      if (this.form.get('monthlyOption2_1').value === FirstLastOption.Last) {
        return `${this.form.get('monthlyOption2_2').value}L`;
      }
    }
    else {
      if (this.form.get('monthlyOption3_1').value === FirstLastOption.First) {
        return this.form.get('monthlyOption3_2').value === DayOption.Day ? '1' : '1W';
      }

      if (this.form.get('monthlyOption3_1').value === FirstLastOption.Last) {
        return this.form.get('monthlyOption3_2').value === DayOption.Day ? 'L' : 'LW';
      }
    }
  }

  private getCronObject() {
    const cronObj: CronObject = {
      deadlineDays: null,
      firstLast: null,
      monthlyOptionDayOption: null,
      monthlyOptionWeekday: null,
      onDay: null,
      repeatEvery: null,
      selectedMonthlyOption: null
    };

    const repeatOption = this.form.get('repeatOption').value;
    const selectedMonthlyOption = this.form.get('selectedMonthlyOption').value;
    const repeatEvery = this.form.get('repeatEvery').value;

    switch (true) {
      case repeatOption === EnumRepeatCycle.Weekly:
        this.setWeeklyCron(cronObj);
        break;

      case selectedMonthlyOption === '1':
        this.setMonthlyOption1Cron(cronObj, repeatEvery);
        cronObj.selectedMonthlyOption = selectedMonthlyOption;
        break;

      case selectedMonthlyOption === '2':
        this.setMonthlyOption2Cron(cronObj, repeatEvery);
        cronObj.selectedMonthlyOption = selectedMonthlyOption;
        break;

      case selectedMonthlyOption === '3':
        this.setMonthlyOption3Cron(cronObj, repeatEvery);
        cronObj.selectedMonthlyOption = selectedMonthlyOption;
        break;
    }
    return cronObj;
  }

  private setWeeklyCron(cronObj: CronObject) {
    cronObj.deadlineDays = this.form.get('deadlineDays').value;
  }

  private setMonthlyOption1Cron(cronObj: CronObject, repeatEvery: any) {
    cronObj.repeatEvery = repeatEvery;
    cronObj.onDay = this.form.get('onDay').value;
  }

  private setMonthlyOption2Cron(cronObj: CronObject, repeatEvery: any) {
    cronObj.repeatEvery = repeatEvery;
    cronObj.firstLast = this.form.get('monthlyOption2_1').value;
    cronObj.monthlyOptionWeekday = this.form.get('monthlyOption2_2').value;
  }

  private setMonthlyOption3Cron(cronObj: CronObject, repeatEvery: any) {
    cronObj.repeatEvery = repeatEvery;
    cronObj.firstLast = this.form.get('monthlyOption3_1').value;
    cronObj.monthlyOptionDayOption = this.form.get('monthlyOption3_2').value;
  }

  getTooltip() {
    if (this.isAddForm() || !this.form.get('isRecurring').value) {
      return null;
    }

    if (this.assignment.repeat.repeat === EnumRepeatCycle[EnumRepeatCycle.Weekly]) {
      return this.getWeeklyTooltip();
    }

    if (this.assignment.repeat.repeat === EnumRepeatCycle[EnumRepeatCycle.Monthly]) {
      return this.getMonthlyTooltip();
    }

    return null;
  }

  private getWeeklyTooltip() {
    return `
        ${this.translate.instant('TASK.REPEATS')} : ${this.translate.instant(`TASK.${this.assignment?.repeat?.repeat}`)}
        ${this.translate.instant('TASK.DEADLINE_DAYS')} : ${this.getDayString(this.assignment?.repeat?.cronObject?.deadlineDays)}
        ${this.translate.instant('TASK.ASSIGNMENT_DATE')}: ${this.translate.instant(`AssignmentDateProperty.${this.getAssignmentDateName(this.assignment?.repeat?.assignmentDatePropertyId)}`)}
        ${this.translate.instant('GENERAL.START_DATE')}: ${this.toLocaleDateString(new Date(this.assignment?.repeat?.startDate))}
        ${this.translate.instant('GENERAL.END_DATE')}: ${this.toLocaleDateString(new Date(this.assignment?.repeat?.endDate))}
      `;
  }

  private getMonthlyTooltip() {
    let monthlyOptionText = '';
    const cronObj = this.assignment?.repeat?.cronObject;
    switch (cronObj?.selectedMonthlyOption) {
      case 1:
        monthlyOptionText = this.translate.instant('TASK.ON_DAY', { day: cronObj.onDay });
        break;
      case 2:
        monthlyOptionText = this.translate.instant('TASK.ON_THE_WEEKDAYS', {
          firstLastOption: this.options.find(op => op.key === cronObj.firstLast)?.value,
          weekdays: this.deadlineDays.find(wd => wd.key === cronObj.monthlyOptionWeekday)?.value
        });
        break;
      case 3:
        monthlyOptionText = this.translate.instant('TASK.ON_THE_DAYOPTION', {
          firstLastOption: this.options.find(op => op.key === cronObj.firstLast)?.value,
          dayOption: this.dayOptions.find(dOp => dOp.key === cronObj.monthlyOptionDayOption)?.value
        });
        break;
      default:
        break;
    }

    return `
        ${this.translate.instant('TASK.REPEATS')} : ${this.translate.instant(`TASK.${this.assignment?.repeat?.repeat}`)}
        ${this.translate.instant('TASK.REPEAT_EVERY_MONTH', { numberOfRepetitions: cronObj?.repeatEvery })}
        ${monthlyOptionText}
        ${this.translate.instant('TASK.ASSIGNMENT_DATE')}: ${this.translate.instant(`AssignmentDateProperty.${this.getAssignmentDateName(this.assignment?.repeat?.assignmentDatePropertyId)}`)}
        ${this.translate.instant('GENERAL.START_DATE')}: ${this.toLocaleDateString(new Date(this.assignment?.repeat?.startDate))}
        ${this.translate.instant('GENERAL.END_DATE')}: ${this.toLocaleDateString(new Date(this.assignment?.repeat?.endDate))}
      `;
  }

  private getDayString(deadlineDaysArray: number[] | null) {
    const selectedDayNames = deadlineDaysArray?.map(day => this.deadlineDays.find(d => d.key === day)?.value);
    return selectedDayNames?.join(', ');
  }

  private getAssignmentDateName(propertyId: string) {
    return this.assignmentDates.find(ad => ad.assignmentDatePropertyId === propertyId)?.name;
  }

  onSubmit() {
    if (this.form.valid) {
      this.assignment ? this.updateAssignment() : this.insertAssignment();
    } else {
      // Touch all form fields and trigger validate
      this.openSeparator = true;
      this.cdr.detectChanges();
      this.form.markAllAsTouched();
      scrollToError();
      this.openSeparator = false;
    }
  }

  isAddForm() {
    return !this.assignment ? true : false;
  }

  // Custom validator function for date range
  dateRangeValidator(maxYears: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const startDateControl = control.get('recurringStartDate');
      const endDateControl = control.get('recurringEndDate');

      if (startDateControl.disabled || endDateControl.disabled) {
        return null;
      }

      const startDate = moment(startDateControl.value);
      const endDate = moment(endDateControl.value);
      const diffDuration = moment.duration(endDate.diff(startDate));

      if (startDate && endDate) {
        if (diffDuration.years() >= maxYears) {
          startDateControl.markAsTouched();
          endDateControl.markAsTouched();
          startDateControl.setErrors({ incorrect: true });
          endDateControl.setErrors({ incorrect: true });
          return { dateRangeError: true };
        }

        if (diffDuration.days() < 0 || diffDuration.minutes() < 0) {
          endDateControl.markAsTouched();
          endDateControl.setErrors({ incorrect: true });
          return { minDateError: true };
        }
      }

      startDateControl.markAsTouched();
      endDateControl.markAsTouched();
      startDateControl.setErrors(null);
      endDateControl.setErrors(null);

      return null; // Validation passed
    };
  }

  getInitialEndDateWeekly(date?: string | Date) {
    const nextMonth = date ? new Date(date) : new Date();
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    return nextMonth;
  }

  getInitialEndDateMonthly(date?: string | Date) {
    const nextYear = date ? new Date(date) : new Date();
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    return nextYear;
  }

  toLocaleDateString(date: Date) {
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  }

  private disableControls(controlNames: string[]): void {
    controlNames.forEach(controlName => this.form.get(controlName).disable());
  }

  private enableControls(controlNames: string[]): void {
    controlNames.forEach(controlName => this.form.get(controlName).enable());
  }

}
