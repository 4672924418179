import { AccountTypeString, StaticField, StaticFieldEnum } from '@core/api';

function leadDetailHomeGridSystem(
  generalInfoStaticFields: StaticField[],
  coordinatorAndRegion: boolean,
  accountType: AccountTypeString,
  accountTypeColumnVisible: boolean,
  accountSourceVisible: boolean,
  selectableCustomerType: boolean,
): { [key: string]: { [key: string]: string | number } } {
  let gridSystem = {
    status: { 'grid-area': 'unset', order: 1 },
    regionMain: { 'grid-area': 'unset', order: 2 },
    sectorMain: { 'grid-area': 'unset', order: 3 }, // static field
    leadType: { 'grid-area': 'unset', order: 4 },
    customerRelationsName: { 'grid-area': 'unset', order: 5 }, // static field
    region: { 'grid-area': 'unset', order: 6 },
    sector: { 'grid-area': 'unset', order: 7 }, // static field
    customerShortName: { 'grid-area': 'unset', order: 8 },
    accountSource: { 'grid-area': 'unset', order: 9 },
    hashtags: { 'grid-area': 'unset', order: 10 }, // static field
    customerType: { 'grid-area': 'unset', order: 11 },
  };

  const sectorMain = generalInfoStaticFields.find(field => field.entityFieldId === StaticFieldEnum.MAIN_INDUSTRY)?.activeFlag
    && accountType !== AccountTypeString.CONSUMER;

  const sector = generalInfoStaticFields.find(field => field.entityFieldId === StaticFieldEnum.SUB_INDUSTRY)?.activeFlag;

  const customerRelationsName = generalInfoStaticFields.find(field => field.entityFieldId === StaticFieldEnum.AUTHORIZED_CUSTOMER_SERVICE)?.activeFlag;

  const hashtag = generalInfoStaticFields.find(field => field.entityFieldId === StaticFieldEnum.HASHTAGS)?.activeFlag;

  const showLeadTypeColumn = accountTypeColumnVisible && accountType === AccountTypeString.CONSUMER;

  const showAccountTypeColumn = accountTypeColumnVisible && accountType === AccountTypeString.BUSINESS;

  if (showAccountTypeColumn) {
    gridSystem = {
      ...gridSystem,
      customerType: {
        ...gridSystem.customerType,
        order: 2
      }
    };
  }

  if (coordinatorAndRegion) {
    // grid system for coordinator and region as 1/3

    gridSystem = {
      ...gridSystem,
      regionMain: {
        ...gridSystem.regionMain,
        order: showAccountTypeColumn ? 3 : 2
      }
    };

  }

  if (sectorMain) {
    gridSystem = {
      ...gridSystem,
      sectorMain: {
        ...gridSystem.sectorMain,
        order: coordinatorAndRegion ? gridSystem.regionMain.order + 1 : (showAccountTypeColumn ? gridSystem.customerType.order + 1 : 3)
      }
    };
  }

  if (showLeadTypeColumn) {
    gridSystem = {
      ...gridSystem,
      leadType: {
        ...gridSystem.leadType,
        order: sectorMain ? gridSystem.sectorMain.order + 1 :
          (coordinatorAndRegion ? gridSystem.regionMain.order + 1 :
            (showAccountTypeColumn ? gridSystem.customerType.order + 1 : 2

            )
          )
      }
    };
  }

  if (customerRelationsName) {
    gridSystem = {
      ...gridSystem,
      customerRelationsName: {
        ...gridSystem.customerRelationsName,
        order: showLeadTypeColumn ? gridSystem.leadType.order + 1 :
          (sectorMain ? gridSystem.sectorMain.order + 1 :
            (coordinatorAndRegion ? gridSystem.regionMain.order + 1 :
              (showAccountTypeColumn ? gridSystem.customerType.order + 1 : 2)
            )
          )
      }
    };
  }

  if (coordinatorAndRegion) {
    gridSystem = {
      ...gridSystem,
      region: {
        ...gridSystem.region,
        order: sectorMain ? gridSystem.regionMain.order + 3 : gridSystem.regionMain.order + 1
      }
    };
  }

  if (sectorMain) {
    gridSystem = {
      ...gridSystem,
      sector: {
        ...gridSystem.sector,
        order: gridSystem.sectorMain.order + (coordinatorAndRegion ? 3 : 2)
      }
    };
  }

  gridSystem = {
    ...gridSystem,
    customerShortName: {
      'grid-area': 'auto / 1 / auto / 3',
      order: sectorMain ? gridSystem.sector.order + 1 :
        (coordinatorAndRegion ? gridSystem.regionMain.order + 1 :
          (customerRelationsName ? gridSystem.customerRelationsName.order + 1 :
            (showLeadTypeColumn ? gridSystem.leadType.order + 1 :
              (showAccountTypeColumn ? gridSystem.customerType.order + 1 : 2)
            )
          )
        )
    }
  };

  if (accountSourceVisible) {
    gridSystem = {
      ...gridSystem,
      accountSource: {
        ...gridSystem.accountSource,
        order: gridSystem.customerShortName.order + 1
      }
    };
  }

  if (hashtag) {
    gridSystem = {
      ...gridSystem,
      hashtags: {
        'grid-area': accountSourceVisible ? (coordinatorAndRegion ? 'auto / 1 / auto / 3' : 'unset') : 'unset',
        order: accountSourceVisible ? gridSystem.accountSource.order + 1 : gridSystem.customerShortName.order + 1
      }
    };
  }

  if (selectableCustomerType) {
    gridSystem = {
      ...gridSystem,
      customerType: {
        ...gridSystem.customerType,
        order: hashtag ? gridSystem.hashtags.order + 1 : (accountSourceVisible ? gridSystem.accountSource.order + 1 : gridSystem.customerShortName.order + 1)
      }
    };
  }

  return gridSystem;
}

export default leadDetailHomeGridSystem;
