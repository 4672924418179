import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  AssignmentSearchRequest,
  PagedResponse,
  Assignment,
  Response,
  AssignmentRequest,
  AssignmentCountRequest,
  AssignmentBulkInsertRequest,
  AssignmentBulkInsert,
  AssignmentDateProperty,
  AssignmentDownload,
  FilterRequest,
  ListCreatedUser
} from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class AssignmentService {

  private closeDialogSubject = new BehaviorSubject<boolean>(false);
  closeDialog$ = this.closeDialogSubject.asObservable();

  constructor(private http: HttpClient) { }

  search(request: AssignmentSearchRequest): Observable<PagedResponse<Assignment>> {
    return this.http.post<PagedResponse<Assignment>>(environment.apiUrl + '/api/Assignment/Search', request);
  }

  assignmentDownload(request: AssignmentSearchRequest): Observable<Response<AssignmentDownload[]>> {
    return this.http.post<Response<AssignmentDownload[]>>(environment.apiUrl + '/api/Assignment/Download', request.filter);
  }

  get(uuid: string): Observable<Response<Assignment>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<Assignment>>(environment.apiUrl + '/api/Assignment/Get', JSON.stringify(uuid), { headers });
  }

  insert(request: AssignmentRequest): Observable<Response<Assignment>> {
    return this.http.post<Response<Assignment>>(environment.apiUrl + '/api/Assignment/Insert', request);
  }

  bulkInsert(request: AssignmentBulkInsertRequest): Observable<Response<AssignmentBulkInsert>> {
    return this.http.post<Response<AssignmentBulkInsert>>(environment.apiUrl + '/api/Assignment/BulkInsert', request);
  }

  update(request: AssignmentRequest): Observable<Response<Assignment>> {
    return this.http.post<Response<Assignment>>(environment.apiUrl + '/api/Assignment/Update', request);
  }

  getAssignedCount(request: AssignmentCountRequest): Observable<Response<any>> {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/Assignment/GetAssignedCount', request);
  }

  getAssignmentDateProperty(): Observable<Response<AssignmentDateProperty[]>> {
    return this.http.post<Response<AssignmentDateProperty[]>>(environment.apiUrl + '/api/AssignmentDateProperty/List', {});
  }

  listCreatedUsers(filter?: FilterRequest): Observable<Response<ListCreatedUser[]>> {
    return this.http.post<Response<ListCreatedUser[]>>(environment.apiUrl + '/api/Assignment/ListCreatedUsers', filter);
  }

  listAssignedUsers(filter?: FilterRequest): Observable<Response<ListCreatedUser[]>> {
    return this.http.post<Response<ListCreatedUser[]>>(environment.apiUrl + '/api/Assignment/ListAssignedUsers', filter);
  }

  closeDialog(isClose = false) {
    this.closeDialogSubject.next(isClose);
  }
}
