import { Component, DoCheck, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';

import {
  Address,
  SalesOrganization,
  SalesRouteDefinition,
  SalesRouteDefinitionService,
  SalesRouteDefinitionStatusType
} from '@core/api';
import { MatSelect } from '@angular/material/select';
import { replaceTurkishCharacters } from '../../utils/replace-turkish-characters';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-select-sales-route',
  templateUrl: './select-sales-route.component.html',
  styleUrls: ['./select-sales-route.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectSalesRouteComponent
    }
  ],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SelectSalesRouteComponent implements DoCheck, OnDestroy, ControlValueAccessor, MatFormFieldControl<SalesRouteDefinition | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-sales-route';
  describedBy = '';
  stateChanges = new Subject<void>();
  searchText = '';
  selectedValue: string;

  filteredSalesRoutes: SalesRouteDefinition[];

  salesRouteDefinitions: SalesRouteDefinition[] = [];
  selectCtrl = new UntypedFormControl();

  salesRouteDefinitionStatus = SalesRouteDefinitionStatusType;
  @Input() tabIndex;
  @Input()
  get salesOrganization(): SalesOrganization {
    return this._salesOrganization;
  }

  set salesOrganization(value: SalesOrganization) {
    this._salesOrganization = value;
    this.fetchList();
  }

  private _salesOrganization: SalesOrganization;

  @Input()
  get revenue(): number {
    return this._revenue;
  }

  set revenue(value: number) {
    if (typeof (value) === 'string') {
      value = 0;
    }
    if (this._revenue === value) {
      return;
    }
    this._revenue = value;
    this.fetchList();
  }

  private _revenue: number;

  @Input()
  get address(): Address {
    return this._address;
  }

  set address(value: Address) {
    this._address = value;
    this.fetchList();
  }

  private _address: Address;

  @Input()
  get withoutAddress(): boolean {
    return this._withoutAddress;
  }

  set withoutAddress(value: boolean) {
    this._withoutAddress = value;
    this.fetchList();
  }

  private _withoutAddress: boolean;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get value(): SalesRouteDefinition | null {
    return this.selectCtrl.value;
  }

  set value(value: SalesRouteDefinition | null) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @Output() salesRouteValue = new EventEmitter();

  @HostBinding('id') id = `select-sales-route-${SelectSalesRouteComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get empty() {
    return !this.value;
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private salesRouteDefinitionService: SalesRouteDefinitionService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private fetchList() {
    this.salesRouteDefinitions = [];
    this.filteredSalesRoutes = [];
    // console.table({addr: this.address, rev: this.revenue, so: this.salesOrganization})

    if ((!this.address && !this.withoutAddress) || this.revenue === null || this.revenue === undefined || !this.salesOrganization) {
      this.value = null;
      this.salesRouteDefinitions = [];
      return;
    }
    this.loading = true;
    this.salesRouteDefinitionService.search({
      filter: {
        ...!this.withoutAddress ? {
          countyId: this.address.countyId,
          countryId: this.address.countryId || this.address.country?.countryId
        } : {},
        revenue: this.revenue,
        salesOrganizationId: this.salesOrganization.salesOrganizationId,
        salesRouteDefinitionStatusId: this.salesRouteDefinitionStatus.ACTIVE,
      }
    }).subscribe(response => {
      this.salesRouteDefinitions = response.data.results;
      this.filteredSalesRoutes = this.salesRouteDefinitions;

      if (this.value && !this.salesRouteDefinitions.some(
        route => route.salesRouteDefinitionId === this.value?.salesRouteDefinitionId)
      ) {
        this.value = null;
        this.salesRouteValue.emit(true);
      }

      // Auto select first element
      if (this.salesRouteDefinitions.length === 1) {
        this.value = this.salesRouteDefinitions[0];
      }

      this.onSelectionChange();
      this.loading = false;
    });
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  clearFilteredList() {
    this.searchText = '';
    this.filteredSalesRoutes = this.salesRouteDefinitions;
  }

  checkKey(event) {
    if (event.code === 'Space') {
      event.stopPropagation();
    }
  }

  onKey(value) {
    this.searchText = value;
    this.filteredSalesRoutes = this.search(value);

    if (this.selectCtrl.value?.length === 1) {
      const selectedRoute = this.salesRouteDefinitions.find((user) => user.salesRouteDefinitionId === this.value[0]);
      this.selectedValue = selectedRoute.name;
    }
  }

  search(value: any) {
    const keyWord = replaceTurkishCharacters(value);
    return this.salesRouteDefinitions
      .filter(option => {
        const filterName = `${option.name} ${option.assignedUser?.firstName} ${option.assignedUser?.lastName}`;
        return replaceTurkishCharacters(filterName).toLocaleUpperCase().includes(keyWord.toLocaleUpperCase());
      });
  }

  scrollTop(select: MatSelect) {
    select.panel.nativeElement.scrollTop = 0;
  }

  onChange = (_: any) => {
  }

  onTouched = () => {
  }

  compareWith = (one: SalesRouteDefinition, two: SalesRouteDefinition) => one && two && one.salesRouteDefinitionId === two.salesRouteDefinitionId;
  onContainerClick = () => {
  }

  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: SalesRouteDefinition) => this.value = value;
  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

}
