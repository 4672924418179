import { Component, DestroyRef, inject, Input, OnInit, Renderer2, RendererFactory2 } from '@angular/core';
import {
  Account,
  CXChannelType,
  Contact,
  CustomerExperienceChatMessageType,
  CustomerExperienceChatMessageTypeStringEnum,
  CustomerExperienceChatModel,
  CustomerExperienceLinkType,
  CustomerExperienceTicketDetailChatHeaderModel,
  CustomerExperienceTicketService,
  SplitTicketCodesModel,
  User
} from '@core/api';
import { ClipboardService } from 'src/app/shared/services/clipboard.service';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { getCustomerExperienceTicketHeader, getUser } from '@core/store';
import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { getChannelIcon } from 'src/app/shared/utils/channel-icons';
import { DomSanitizer } from '@angular/platform-browser';
import { ImagePipe } from 'src/app/shared/pipes/image-pipe';
import { environment } from '@env/environment';
import { validate as uuidValidate } from 'uuid';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Icon } from '@shared/enums';
import { CustomerExperienceNewObjectDialogComponent } from '../customer-experience-links-object/customer-experience-new-object-dialog/customer-experience-new-object-dialog.component';
import { firstValueFrom } from 'rxjs';
import { CustomerExperienceMailViewerComponent } from '../customer-experience-mail-viewer/customer-experience-mail-viewer.component';
import { SharedModule } from '@shared/modules';
import { CommonModule } from '@angular/common';
import { CustomerExperienceMailAttachmentComponent } from '../customer-experience-mail-attachment/customer-experience-mail-attachment.component';

@Component({
  selector: 'net-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  providers: [ImagePipe],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    CustomerExperienceMailAttachmentComponent
  ]
})
export class ChatMessageComponent implements OnInit {

  @Input() self = true;
  @Input() followUp = false;
  @Input() isLog = false;
  @Input() isPrivateNote = false;
  @Input() permissions: { createTask: boolean, createTicket: boolean, splitTicket: boolean } = {
    createTask: false,
    createTicket: false,
    splitTicket: false
  };
  @Input() set message(message: CustomerExperienceChatModel) {
    if (message?.ticketMessage && message.ticketMessage?.cxChannel && message.ticketMessage?.cxChannel?.cxChannelTypeId === CXChannelType.EMAIL) {
      this._message = this.getMessageTextForMail(message);
    }
    else {
      this._message = message;
    }
  }

  get message(): CustomerExperienceChatModel {
    return this._message;
  }

  private _message: CustomerExperienceChatModel;

  @Input() cxChannelId: string;
  @Input() headerInfo: CustomerExperienceTicketDetailChatHeaderModel;
  @Input() isPreview = false;


  icWaiting = Icon.MDI_CLOCK_TIME_THREE_OUTLINE;
  icCheck = Icon.MDI_CHECK;
  icDoubleCheck = Icon.MDI_CHECK_ALL;
  icMoreHoriz = Icon.IC_MORE_HORIZ;
  icCopy = Icon.AKAR_ICONS_COPY;
  icCreateTask = Icon.MATERIAL_SYMBOLS_ASSIGNMENT_ADD_OUTLINE;
  icCreateTicket = Icon.MDI_TICKET_CONFIRMATION_OUTLINE;
  icPerson = Icon.IC_BASELINE_PERSON_OUTLINE;
  icSplit = Icon.IC_BASELINE_CALL_SPLIT;
  icDownload = Icon.MDI_DOWNLOAD;
  icPrivateNote = Icon.OOUI_SPEECH_BUBBLE_ADD_RTL;

  user: User;
  systemUser: User;
  contacts: Contact[];
  translations: any;
  splitTicketCodes: SplitTicketCodesModel = {
    sourceTicketCode: undefined,
    ticketCode: undefined
  };

  cxChannelType = CXChannelType;
  private renderer: Renderer2;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private translate: TranslateService,
    private ticketService: CustomerExperienceTicketService,
    private clipboardService: ClipboardService,
    private store: Store,
    private rendererFactory: RendererFactory2,
    private sanitizer: DomSanitizer,
    private imagePipe: ImagePipe
  ) {
    this.translate.get(['CX', 'GENERAL']).subscribe(translations => {
      this.translations = translations;
    });

    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  ngOnInit(): void {
    if (this.isAgentMessage()) {
      this.user = this.message.ticketMessage?.senderUser ?? null;
      return;
    }

    if (this.isPrivateNote) {
      this.user = this.message.ticketPrivateNote?.createdUser ?? null;
      return;
    }

    if (this.isLog) {
      this.splitTicketCodes = { ...this.getMergedTicketCode(JSON.parse(this.message.ticketLog?.construct)) };
    }
  }

  // decide whether to show or hide avatar next to the image.
  hideAvatar() {
    if (this.isLog || this.followUp) {
      return true;
    }

    return false;
  }

  getBubbleColor(): string {
    if (this.isPrivateNote) {
      return 'private-note-bubble';
    }

    switch (this.message?.ticketMessage?.cxChannel.cxChannelTypeId) {

      case CXChannelType.WHATSAPP:
        return this.self ? 'agent-message-bubble' : 'user-message-bubble';
      case CXChannelType.EMAIL:
        return this.self ? 'agent-mail-bubble' : 'user-mail-bubble';
      default:
        return this.self ? 'agent-message-bubble' : 'user-message-bubble';

    }

  }

  getBubbleClass() {
    return {
      'ml-4 order-2': this.self,
      'mr-4': !this.self,
      [this.getBubbleColor()]: true
    };
  }


  copy(message: CustomerExperienceChatModel) {
    if (this.isPrivateNote) {
      const note = this.extractTextFromHTML(message.ticketPrivateNote?.note ?? '');
      this.clipboardService.copyMessage(note);
      return;
    }

    if (this.isImage()) {
      this.clipboardService.copyMessage(message.ticketMessage?.message?.caption ?? '');
      return;
    } else if (message.ticketMessage?.cxChannel?.cxChannelTypeId === CXChannelType.EMAIL) {
      this.clipboardService.copyMessage(message.ticketMessage?.message?.text['changingThisBreaksApplicationSecurity'] ?? message.ticketMessage?.message?.text);
      return;
    } else if (message.ticketMessage?.message?.messageType === CustomerExperienceChatMessageType[CustomerExperienceChatMessageType.Button_Reply]) {
      this.clipboardService.copyMessage(message.ticketMessage?.message['title'] ?? '');
      return;
    }


    this.clipboardService.copyMessage((message.ticketMessage?.message?.text ?? '') as string);
  }

  private extractTextFromHTML(htmlString: string): string {
    if (!htmlString) {
      return '';
    }

    const tempElement = this.renderer.createElement('div');
    this.renderer.setProperty(tempElement, 'innerHTML', htmlString);

    return tempElement.innerText || tempElement.textContent || '';
  }

  async createTask(message: CustomerExperienceChatModel) {
    const ticketDetail: { subject: string, account?: Account, ticketId: string } = { subject: '', ticketId: this.activatedRoute.snapshot.params.ticketId };

    this.systemUser = await this.getSystemUser();
    this.contacts = await this.getContacts();

    if (this.contacts.length > 0) {
      const accounts = this.getAccounts(this.contacts);
      if (this.areAccountsInSingleVisibleSalesOrganization(accounts, this.systemUser.salesOrganizations)) {
        ticketDetail.account = this.contacts[0].customer?.accounts?.find(acc => this.systemUser.salesOrganizations.includes(acc.salesOrganizationId));
      }
    }

    ticketDetail.subject = this.adjustMessage(this.getMessageContent(message) || '');

    this.dialog
      .open(CustomerExperienceNewObjectDialogComponent, {
        autoFocus: false, data: {
          ticketDetail,
          type: CustomerExperienceLinkType.ASSIGNMENT
        }, disableClose: false
      })
      .addPanelClass('cdk-full-overlay');
  }

  openMailViewer() {
    this.dialog.open(CustomerExperienceMailViewerComponent, {
      autoFocus: false,
      data: {
        self: this.self,
        headerInfo: this.headerInfo,
        ...this.message
      }
    });
  }

  private areAccountsInSingleVisibleSalesOrganization(accounts: Account[], salesOrganizationIds: string[]): boolean {
    const accountSalesOrgIds = new Set(accounts
      .filter(acc => acc !== undefined && acc.salesOrganizationId !== null)
      .map(acc => acc.salesOrganizationId)
    );
    const matchingSalesOrgIds = salesOrganizationIds.filter(id => accountSalesOrgIds.has(id));
    // Return true if there is less than 2 matching sales organization IDs, otherwise false
    return matchingSalesOrgIds.length < 2;
  }

  private getAccounts(contacts: Contact[]): Account[] {
    return contacts.reduce((acc, c) => acc.concat(c.customer.accounts), []);
  }

  private async getSystemUser() {
    return firstValueFrom(this.store.select(getUser).pipe(takeUntilDestroyed(this.destroyRef)));
  }

  private async getContacts() {
    const info = await firstValueFrom(this.store.select(getCustomerExperienceTicketHeader).pipe(take(1)));
    return info.contacts ?? [];
  }

  private getMessageContent(message: CustomerExperienceChatModel): string {
    if (this.isPrivateNote) {
      return this.extractTextFromHTML(message.ticketPrivateNote?.note ?? '');
    }

    switch (message.ticketMessage.cxChannel.cxChannelTypeId) {
      case CXChannelType.WHATSAPP:
        const messageContent = this.isImage()
          ? message.ticketMessage?.message?.caption ?? ''
          : message.ticketMessage?.message?.text ?? '';
        return messageContent as string;
      case CXChannelType.EMAIL:
        return !!message.ticketMessage.message.subject ? message.ticketMessage.message.subject : this.headerInfo.ticket.subject;
      default:
        return message.ticketMessage.message.text as string;
    }


  }

  private adjustMessage(str: string): string {
    const adjustedStr = str.length > 198 ? str.substring(0, 195) + '...' : str;
    return `(${adjustedStr})`;
  }

  async createTicket(message: CustomerExperienceChatModel) {
    const confirmed = await this.showConfirmationDialog();
    if (confirmed) {
      const request = {
        ticketMessageId: message.ticketMessage.ticketMessageId,
        cxChannelId: this.cxChannelId,
        contactInfo: this.headerInfo.phoneNumber,
        ...this.headerInfo.contactId ? { contactId: this.headerInfo.contactId } : {},
        contactName: this.headerInfo.contactName
      };
      this.ticketService.insert(request).subscribe();
    }
  }

  private async showConfirmationDialog(): Promise<boolean> {
    const result = await Swal.fire({
      html: this.translate.instant('CX.NEW_TICKET_WILL_BE_CREATED'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4caf50',
      confirmButtonText: this.translations?.GENERAL.CONFIRM,
      cancelButtonText: this.translations?.GENERAL.CANCEL,
    });

    return (result.value as boolean) || false;
  }

  getChannelIcon() {
    return getChannelIcon(this.message?.ticketMessage?.cxChannel?.cxChannelTypeId);
  }

  isImage() {
    return this.message.ticketMessage.message.messageType === CustomerExperienceChatMessageType[CustomerExperienceChatMessageType.Image];
  }

  isDocument() {
    return this.message.ticketMessage.message.messageType === CustomerExperienceChatMessageType[CustomerExperienceChatMessageType.Document];
  }

  isVideo() {
    return this.message.ticketMessage.message.messageType === CustomerExperienceChatMessageType[CustomerExperienceChatMessageType.Video];
  }

  isSticker() {
    return this.message.ticketMessage.message.messageType === CustomerExperienceChatMessageType[CustomerExperienceChatMessageType.Sticker];
  }

  isButtonReply() {
    return this.message.ticketMessage.message.messageType === CustomerExperienceChatMessageType[CustomerExperienceChatMessageType.Button_Reply];
  }

  isAgentMessage() {
    return this.message.chatMessageType === CustomerExperienceChatMessageTypeStringEnum.AgentMessage;
  }

  isQuickReply() {
    return [
      CustomerExperienceChatMessageType[CustomerExperienceChatMessageType.Quick_Reply],
      CustomerExperienceChatMessageType[CustomerExperienceChatMessageType.List]
    ].includes(this.message.ticketMessage.message.messageType);
  }

  splitTicket() {
    this.ticketService.emitSplitTicketsEvent(this.splitTicketCodes);
  }

  canBeSplit() {
    // ticket mergelenmiş durumda ise ve log merge ile ilgiliyse true dön
    return this.splitTicketCodes.sourceTicketCode !== undefined;
  }

  private getMergedTicketCode(data: any): SplitTicketCodesModel {
    const paramArray = data?.description?.param;
    let mergedTicketCode: string;
    let ticketCode: string;
    if (paramArray && Array.isArray(paramArray)) {
      for (const param of paramArray) {
        if (param.mergedticketcode) {
          mergedTicketCode = param.mergedticketcode.value;
        }

        if (param.ticketcode) {
          ticketCode = param.ticketcode.value;
        }
      }
    }

    return {
      sourceTicketCode: mergedTicketCode,
      ticketCode
    };
  }

  handleDocument(url: string) {
    window.open(url, '_blank');
  }

  formattedRecipients(email: string, cc: boolean = false): string {
    const cxChannelInfo = this.message?.ticketMessage?.cxChannel?.cxChannelInfo;

    if (cc) {
      return email.split(',').map(mail => mail.trim()).join(', ');
    }

    if (email) {
      const formattedMail = email.split(',').map(mail => mail.trim()).join(', ');
      return cxChannelInfo ? `${formattedMail}, ${cxChannelInfo}` : formattedMail;
    }
    return cxChannelInfo || '';
  }

  getMessageTextForMail(message: CustomerExperienceChatModel): CustomerExperienceChatModel {

    const text = message.ticketMessage.message.text['changingThisBreaksApplicationSecurity'] ?? message.ticketMessage.message.text;

    const commentEl = document.createElement('div');
    commentEl.innerHTML = text;
    const imagesOfComment = Array.from(commentEl?.getElementsByTagName('img'));

    if (imagesOfComment?.length > 0) {
      imagesOfComment.forEach(async (image: HTMLImageElement) => {
        const attachmentId = image.alt;

        if (attachmentId && uuidValidate(attachmentId)) {
          this.imagePipe.transform(environment.apiUrl + `/api/Attachment/GetPicMedia?attachmentId=${attachmentId}`).subscribe((value: string) => {
            image.src = value;
            message.ticketMessage.message.text = this.sanitizer.bypassSecurityTrustHtml(commentEl.innerHTML);
          });
        }
      });
    }
    return message;
  }
}
