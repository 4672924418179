import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, ElementRef, HostBinding,
  Input, OnDestroy, OnInit, Optional, Self
} from '@angular/core';
import { UntypedFormControl, NgControl, ControlValueAccessor } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Customer, ApiService, PhoneSearch, CustomerPhoneType } from '@core/api';
import { Icon } from '@shared/enums';
import { Subject } from 'rxjs';
import { MatFormFieldControl } from '@angular/material/form-field';
import { SharedModule } from '@shared/modules';
import { PhoneInsertDialogComponent } from '../phone-insert-dialog/phone-insert-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'net-input-customer-phones-with-autocomplete',
  templateUrl: './input-customer-phones-with-autocomplete.component.html',
  styleUrl: './input-customer-phones-with-autocomplete.component.scss',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputCustomerPhonesWithAutocompleteComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class InputCustomerPhonesWithAutocompleteComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<PhoneSearch> {
  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-phone';
  describedBy = '';
  stateChanges = new Subject<void>();

  icAdd = Icon.IC_TWOTONE_ADD;
  icEdit = Icon.IC_EDIT;
  icClose = Icon.IC_TWOTONE_CLOSE;
  icSave = Icon.IC_SAVE;

  phones: PhoneSearch[] = [];
  inputCtrl = new UntypedFormControl();
  dialogRef: MatDialogRef<any>;

  @Input() tabIndex;

  @Input()
  get customer() { return this._customer; }
  set customer(customer: Customer) {
    this._customer = customer;
    this.reset();

    if (customer) {
      this.loading = true;
      this.inputCtrl.disable();
      this.initDefaultOptions().finally(() => {
        this.loading = false;

        if (!this.disabled) {
          this.inputCtrl.enable();
        }

        this.cdr.detectChanges();
      });
    }
  }
  private _customer;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): PhoneSearch | null { return this._value; }
  set value(value: PhoneSearch | null) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: PhoneSearch;

  @HostBinding('id') id = `select-phone-${InputCustomerPhonesWithAutocompleteComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  private initDefaultOptions(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.api.phone.search({ filter: { customerId: this.customer.customerId } }).toPromise()
        .then((response) => {
          this.phones = response.data.results.filter(phone => phone.phoneTypeId !== CustomerPhoneType.FAX);
          this.selectFirst();
          this.cdr.detectChanges();
        })
        .finally(() => resolve());
    });
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private api: ApiService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private translate: TranslateService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.selectFirst();
  }

  add() {
    this.dialog.open(PhoneInsertDialogComponent, {
      autoFocus: false,
      width: '500px',
      data: this.customer
    }).afterClosed().subscribe((phone: PhoneSearch) => {
      if (phone?.phoneId) {
        this.phones.push(phone);
        this.value = phone;
        this.inputCtrl.setValue(this.value);
        this.onSelectionChange();

        this.cdr.detectChanges();
      }
    });
  }

  reset() {
    if (this.inputCtrl.pristine) {
      return;
    }

    this.value = null;
    this.phones = [];
    this.inputCtrl.setValue(null);
    this.inputCtrl.markAsPristine();

    this.initDefaultOptions();
  }

  selectFirst() {
    if (this.phones.length === 1) {
      this.value = this.phones[0];
      this.inputCtrl.setValue(this.value);
    } else if (!this.value) {
      this.value = null;
      this.inputCtrl.setValue(this.value);
    }
  }

  onSelectionChange() {
    this.value = this.inputCtrl.value;
    this.onChange(this.value);
    this.stateChanges.next();
  }

  getPhoneType = (phone: PhoneSearch) => {
    switch (phone.phoneTypeId) {
      case 1: return this.translate.instant('PhoneTypeId.' + CustomerPhoneType.OFFICE);
      case 3: return this.translate.instant('PhoneTypeId.' + CustomerPhoneType.MOBILE);
      case 4: return this.translate.instant('PhoneTypeId.' + CustomerPhoneType.FAX);
      case 5: return this.translate.instant('PhoneTypeId.' + CustomerPhoneType.OTHER);
      default: return '';
    }
  }

  onChange = (_: any) => { };
  selectionChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  compareWith(one: PhoneSearch, two: PhoneSearch) {
    return one && two && one.phoneId === two.phoneId;
  }
  writeValue(value: PhoneSearch) {
    this.value = value;
    this.inputCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
