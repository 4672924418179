import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import {
  BaseResponse,
  ContractBuilder,
  ContractBuilderApproval,
  ContractBuilderApprovalInsertRequest,
  ContractBuilderApprovalSearchRequest,
  ContractBuilderApprovalUpdateRequest,
  ContractBuilderInsertRequest,
  ContractBuilderSearchRequest,
  ContractBuilderUpdateRequest,
  ContractParameter,
  ContractParameterSearchRequest,
  ExternalContractBuilderApproval,
  ExternalContractBuilderApprovalInsertRequest,
  ExternalContractBuilderApprovalSearchRequest,
  ExternalContractBuilderApprovalUpdateRequest,
  PagedResponse,
  Response,
} from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class ContractGeneratorService {
  constructor(private http: HttpClient) { }

  builder = {
    get: (uuid: string): Observable<Response<any>> => {
      const headers = new HttpHeaders({ 'content-type': 'application/json' });
      return this.http.post<Response<any>>(environment.apiUrl + '/api/ContractBuilder/Get', JSON.stringify(uuid), { headers });
    },
    insert: (request: ContractBuilderInsertRequest): Observable<Response<any>> => {
      return this.http.post<Response<any>>(environment.apiUrl + '/api/ContractBuilder/Insert', request);
    },
    update: (request: ContractBuilderUpdateRequest): Observable<Response<any>> => {
      return this.http.post<Response<any>>(environment.apiUrl + '/api/ContractBuilder/Update', request);
    },
    delete: (uuid: string): Observable<Response<any>> => {
      const headers = new HttpHeaders({ 'content-type': 'application/json' });
      return this.http.post<Response<any>>(environment.apiUrl + '/api/ContractBuilder/Delete', JSON.stringify(uuid), { headers });
    },
    list: (): Observable<Response<ContractBuilder>> => {
      return this.http.post<Response<ContractBuilder>>(environment.apiUrl + '/api/ContractBuilder/List', {});
    },
    search: (request: ContractBuilderSearchRequest): Observable<PagedResponse<ContractBuilder>> => {
      return this.http.post<PagedResponse<ContractBuilder>>(environment.apiUrl + '/api/ContractBuilder/Search', request);
    }
  };

  parameter = {
    get: (uuid: string): Observable<Response<any>> => {
      const headers = new HttpHeaders({ 'content-type': 'application/json' });
      return this.http.post<Response<any>>(environment.apiUrl + '/api/ContractParameter/Get', JSON.stringify(uuid), { headers });
    },
    insert: (request: any): Observable<Response<any>> => {
      return this.http.post<Response<any>>(environment.apiUrl + '/api/ContractParameter/Insert', request);
    },
    update: (request: any): Observable<Response<any>> => {
      return this.http.post<Response<any>>(environment.apiUrl + '/api/ContractParameter/Update', request);
    },
    delete: (request: any): Observable<Response<any>> => {
      return this.http.post<Response<any>>(environment.apiUrl + '/api/ContractParameter/Delete', request);
    },
    list: (): Observable<Response<ContractParameter[]>> => {
      return this.http.post<Response<ContractParameter[]>>(environment.apiUrl + '/api/ContractParameter/List', {});
    },
    search: (request: ContractParameterSearchRequest): Observable<PagedResponse<ContractParameter>> => {
      return this.http.post<PagedResponse<ContractParameter>>(environment.apiUrl + '/api/ContractParameter/Search', request);
    }
  };

  builderApproval = {
    get: (uuid: string): Observable<Response<any>> => {
      const headers = new HttpHeaders({ 'content-type': 'application/json' });
      return this.http.post<Response<any>>(environment.apiUrl + '/api/ContractBuilderApproval/Get', JSON.stringify(uuid), { headers });
    },
    insert: (request: ContractBuilderApprovalInsertRequest): Observable<Response<ContractBuilderApproval>> => {
      return this.http.post<Response<ContractBuilderApproval>>(environment.apiUrl + '/api/ContractBuilderApproval/Insert', request);
    },
    update: (request: ContractBuilderApprovalUpdateRequest): Observable<Response<any>> => {
      return this.http.post<Response<any>>(environment.apiUrl + '/api/ContractBuilderApproval/Update', request);
    },
    delete: (request: any): Observable<Response<any>> => {
      return this.http.post<Response<any>>(environment.apiUrl + '/api/ContractBuilderApproval/Delete', request);
    },
    list: (): Observable<Response<any>> => {
      return this.http.post<Response<any>>(environment.apiUrl + '/api/ContractBuilderApproval/List', {});
    },
    search: (request: ContractBuilderApprovalSearchRequest): Observable<PagedResponse<ContractBuilderApproval>> => {
      return this.http.post<PagedResponse<ContractBuilderApproval>>(environment.apiUrl + '/api/ContractBuilderApproval/Search', request);
    }
  };

  builderApprovalExternal = {
    get: (uuid: string): Observable<Response<any>> => {
      const headers = new HttpHeaders({ 'content-type': 'application/json' });
      return this.http.post<Response<any>>(environment.apiUrl + '/api/ExternalContractBuilderApproval/Get', JSON.stringify(uuid), { headers });
    },
    insert: (request: ExternalContractBuilderApprovalInsertRequest): Observable<Response<ExternalContractBuilderApproval>> => {
      return this.http.post<Response<ExternalContractBuilderApproval>>(environment.apiUrl + '/api/ExternalContractBuilderApproval/Insert', request);
    },
    update: (request: ExternalContractBuilderApprovalUpdateRequest): Observable<Response<ExternalContractBuilderApproval>> => {
      return this.http.post<Response<ExternalContractBuilderApproval>>(environment.apiUrl + '/api/ExternalContractBuilderApproval/Update', request);
    },
    delete: (uuid: string): Observable<BaseResponse> => {
      const headers = new HttpHeaders({ 'content-type': 'application/json' });
      return this.http.post<BaseResponse>(environment.apiUrl + '/api/ExternalContractBuilderApproval/Delete', JSON.stringify(uuid), { headers });
    },
    list: (): Observable<Response<any>> => {
      return this.http.post<Response<any>>(environment.apiUrl + '/api/ExternalContractBuilderApproval/List', {});
    },
    search: (request: ExternalContractBuilderApprovalSearchRequest): Observable<Response<any>> => {
      return this.http.post<Response<any>>(environment.apiUrl + '/api/ExternalContractBuilderApproval/Search', request);
    }
  };
}

