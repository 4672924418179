@if (!disabled && !hideControls) {
  <button type="button" class="add-phone" mat-icon-button (click)="add()">
    <net-iconify-icon [icon]="icAdd" size="16"></net-iconify-icon>
  </button>
}

<ng-container [formGroup]="form">

  <div fxLayout="column" formArrayName="phones" matTooltip="{{'GENERAL.PLEASE_SELECT_CUSTOMER_FIRST' | translate}}"
    [matTooltipDisabled]="!!customer">

    <div class="input-phone" fxLayout="row" fxLayoutGap="5px" [formGroupName]="index"
      *ngFor="let control of getPhonesFormControls(); let index = index">

      <mat-form-field fxFlex="15" class="grouped-zone">
        <mat-select formControlName="type" placeholder="{{'GENERAL.TYPE' | translate}}"
          (selectionChange)="control.controls.internal.patchValue(null)">
          <mat-option *ngFor="let item of typeOptions" [value]="item.type">{{ item.label }}</mat-option>
        </mat-select>
      </mat-form-field>

      <net-select-country [disabled]="disabled" [tooltip]="control.value.country?.name"
        placeholder="{{'GENERAL.COUNTRY' | translate}}" [panelWidth]="'auto'"
        (optionSelected)="onChangeCountry(control)" fxFlex="25" formControlName="country"></net-select-country>

      <div fxFlex="60" fxLayout="row">
        <input #phone class="hide-input-number-arrow"
          [type]="control.value.country? (typeControl(control.value.country?.sortName) ? 'tel' :'number') :'tel'"
          fxFlex="65" matInput formControlName="phone" placeholder="{{'GENERAL.PHONE_NUMBER' | translate}}"
          [mask]="control.value.phoneMask" [prefix]="control.value.prefix" [showMaskTyped]="control.value.showedMask"
          (keyup)="onKeyupCountryCode(control)" (focus)="focusInInput(phone, index)" (focusout)="focusOutInput(index)" [dropSpecialCharacters]="false">
        <input *ngIf="checkIsInputTypeOffice(control) && !hideInternal" class="ml-4 hide-input-number-arrow" type="number" fxFlex="35"
          matInput (keydown)="onKeydown($event)" formControlName="internal"
          placeholder="{{'GENERAL.INTERNAL_NUMBER' | translate}}">
      </div>

      @if (!hideControls) {
        <button type="button" class="remove-phone" mat-icon-button (click)="remove(index)">
          <net-iconify-icon [icon]="icMinus" size="16"></net-iconify-icon>
        </button>
      }

    </div>
  </div>

</ng-container>