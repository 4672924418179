import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    CustomerExperienceAssignCategory, CustomerExperienceAssignCategoryDeleteRequest,
    CustomerExperienceAssignCategoryInsertRequest, CustomerExperienceAssignCategorySearchRequest,
    CustomerExperienceAssignCategorySetOrderRequest, CustomerExperienceAssignCategorySetStatusRequest,
    CustomerExperienceAssignCategoryUpdateRequest, FilterRequest, ListCreatedUser, PagedResponse, Response
} from '@core/api';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CustomerExperienceAssignCategoryService {

    constructor(
        private http: HttpClient
    ) { }

    search(request: CustomerExperienceAssignCategorySearchRequest): Observable<PagedResponse<CustomerExperienceAssignCategory>> {
        return this.http.post<PagedResponse<CustomerExperienceAssignCategory>>(environment.apiUrl + '/api/CxAssignCategoryRule/Search', request);
    }

    insert(request: CustomerExperienceAssignCategoryInsertRequest): Observable<Response<CustomerExperienceAssignCategory>> {
        return this.http.post<Response<CustomerExperienceAssignCategory>>(environment.apiUrl + '/api/CxAssignCategoryRule/Insert', request);
    }

    update(request: CustomerExperienceAssignCategoryUpdateRequest): Observable<Response<CustomerExperienceAssignCategory>> {
        return this.http.post<Response<CustomerExperienceAssignCategory>>(environment.apiUrl + '/api/CxAssignCategoryRule/Update', request);
    }

    setStatus(request: CustomerExperienceAssignCategorySetStatusRequest): Observable<Response<CustomerExperienceAssignCategory>> {
        return this.http.post<Response<CustomerExperienceAssignCategory>>(environment.apiUrl + '/api/CxAssignCategoryRule/SetStatus', request);
    }

    delete(request: CustomerExperienceAssignCategoryDeleteRequest): Observable<Response<CustomerExperienceAssignCategory>> {
        return this.http.post<Response<CustomerExperienceAssignCategory>>(environment.apiUrl + '/api/CxAssignCategoryRule/Delete', request);
    }

    get(uuid: string): Observable<Response<CustomerExperienceAssignCategory>> {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<CustomerExperienceAssignCategory>>(environment.apiUrl + '/api/CxAssignCategoryRule/Get',
            JSON.stringify(uuid), { headers });
    }

    setOrderBy(request: CustomerExperienceAssignCategorySetOrderRequest): Observable<Response<CustomerExperienceAssignCategory>> {
        return this.http.post<Response<CustomerExperienceAssignCategory>>(environment.apiUrl + '/api/CxAssignCategoryRule/SetOrderBy', request);
    }

    listCreatedUsers(filter?: FilterRequest): Observable<Response<ListCreatedUser[]>> {
        return this.http.post<Response<ListCreatedUser[]>>(environment.apiUrl + '/api/CxAssignCategoryRule/ListCreatedUsers', filter);
    }
}
