<mat-select [formControl]="inputCtrl" (selectionChange)="onSelectionChange()" [compareWith]="compareWith">
    <mat-option *ngFor="let phone of phones" [value]="phone">
        {{ phone.phoneNumber }}
        <span class="phone-type"> - {{getPhoneType(phone)}}</span>
    </mat-option>
</mat-select>

@if (!disabled) {
<button type="button" class="add-phone-button" mat-icon-button [hidden]="this.loading" (click)="add()">
    <net-iconify-icon [icon]="icAdd" size="16"></net-iconify-icon>
</button>
}

@if (loading === true) {
<mat-progress-spinner diameter="16" mode="indeterminate"></mat-progress-spinner>
}