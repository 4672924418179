import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import { SalesOrganization, SalesOrganizationRequest, FilterRequest, Response } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class SalesOrganizationService {
  constructor(private http: HttpClient) { }

  get(salesOrganizationId?: string): Observable<Response<SalesOrganization>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<SalesOrganization>>(environment.apiUrl + '/api/SalesOrganization/Get', JSON.stringify(salesOrganizationId), { headers });
  }

  list(request?: FilterRequest & { salesOrganizationAccountTypeId?: string }): Observable<Response<SalesOrganization[]>> {
    return this.http.post<Response<SalesOrganization[]>>(environment.apiUrl + '/api/SalesOrganization/List', request ?? {});
  }

  update(request: SalesOrganizationRequest) {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/SalesOrganization/Update', request);
  }

  insert(request: SalesOrganizationRequest) {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/SalesOrganization/Insert', request);
  }

  delete(request: SalesOrganizationRequest) {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/SalesOrganization/Delete', request);
  }

  search(request: SalesOrganizationRequest) {
    return this.http.post<Response<SalesOrganization[]>>(environment.apiUrl + '/api/SalesOrganization/Search', request);
  }
}
