import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { environment } from '@env/environment';
import * as signalR from '@microsoft/signalr';
import { ReplaySubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomConsole } from 'src/app/shared/utils/custom-console';

@Injectable({
    providedIn: 'root'
})
export class CustomerExperienceChannelSignalrService {

    public static connectionId: string;

    console = new CustomConsole();

    public loadingSubject = new ReplaySubject<boolean>();
    public loading$ = this.loadingSubject.asObservable();

    public connectionSubject = new ReplaySubject<boolean>();
    public connection$ = this.connectionSubject.asObservable();

    private hubConnection: signalR.HubConnection;
    joinedGroupList: string[] = [];

    constructor(private auth: AuthService) { }

    public startConnection = () => {

        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${environment.socketUrl}/cxchannelhub?x-client=${environment.socketTenant}`, {
                accessTokenFactory: async () => {
                    await this.getToken(); return AuthService.getToken();
                }, withCredentials: true, transport: signalR.HttpTransportType.WebSockets, skipNegotiation: true
            })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();

        this.hubConnection.keepAliveIntervalInMilliseconds = 1000 * 60 * 10;
        this.hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 60;

        this.hubConnection.onclose((err) => {
            if (err) {
                if (err.stack.includes('WebSocket closed with status code: 1006 ()')) { setTimeout(() => { location.reload(); }, 1000); }
                setTimeout(() => {
                    this.hubConnection.start()
                        .then(() => this.console.info('Websocket Connection Established'))
                        .catch(error => this.console.error('SignalR Connection Error: ', error));
                }, 500);
            }
        });

        this.hubConnection
            .start()
            .then(() => {
                if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
                    this.getConnectionId();
                    this.listenJoinGroup();
                }
            })
            .catch(err => this.console.info('Error while starting connection: ' + err));
    }

    getConnectionId() {
        this.hubConnection.on('GetConnectionId', (connectionId: string) => {
            this.console.info('[CustomerExperienceChannelSignalR] received GetConnectionId.', { connectionId });
            CustomerExperienceChannelSignalrService.connectionId = connectionId;
            this.console.info('Connection Id', connectionId);
        });
    }

    listenJoinGroup(): void {
        this.hubConnection.on('JoinGroup', (response: any) => {
            this.loadingSubject.next(false);
        });
    }

    stopConnection(): void {
        if (!this.hubConnection) {
            return;
        }

        this.hubConnection.stop();
        this.loadingSubject.next(false);
        this.connectionSubject.next(false);
    }

    joinGroup(cxChannelId: string): void {
        this.hubConnection?.invoke('JoinGroup', cxChannelId)
            .catch(err => this.console.error('Error joining group: ', err))
            .finally(() => {
                this.connectionSubject.next(true);
                this.loadingSubject.next(false);
            });
    }

    removeFromGroup(cxChannelId: string) {
        if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
            this.hubConnection.invoke('RemoveFromGroup', cxChannelId);
        }
    }

    getHubConnection() {
        return this.hubConnection;
    }

    private getToken(): Promise<void> {
        return new Promise((resolve) => {
            this.auth.refreshToken().pipe(
                catchError(error => {
                    if (error instanceof HttpErrorResponse) {
                        switch (error.status) {
                            case 401:
                                this.auth.logout(true, true);
                                break;
                        }
                    }
                    return throwError(error);
                })
            ).subscribe(() => resolve());
        });
    }

}
