import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService, Contact, ContactRequest, Customer } from '@core/api';

import { ContactDialogData } from './contact-form-dialog.component.model';
import { ContactFormComponent } from '../contact-form/contact-form.component';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'net-contact-form-dialog',
  templateUrl: './contact-form-dialog.component.html',
  styleUrls: ['./contact-form-dialog.component.scss'],
  standalone: true,
  imports: [
    ContactFormComponent
  ]
})
export class ContactFormDialogComponent {

  contact: Contact;
  newContact: Contact;
  customer: Customer;
  prefill: ContactDialogData['prefill'] | null;
  permissionControl: boolean;
  loading = false;
  formChanged = false;
  translations: { [key: string]: string };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ContactDialogData,
    private dialogRef: MatDialogRef<ContactFormDialogComponent>,
    private api: ApiService,
    private translate: TranslateService
  ) {
    // Add general form dialog panel
    this.dialogRef.addPanelClass('form-dialog-pane');

    if (data && data.prefill) {
      this.prefill = data.prefill;
    }

    if (data && data.customer) {
      this.customer = data.customer;
    }

    if (data && data.contact) {
      this.getContact(data.contact.contactId, data.checkPermissions);
    } else {
      this.contact = null;
    }

    this.permissionControl = typeof data?.checkPermissions === 'boolean' ? data.checkPermissions : true;

    if (data && data.newContact) {
      this.newContact = data.newContact;
    }

    translate.get(['GENERAL', 'CONTACT']).subscribe(translations => {
      this.translations = {
        ...translations.GENERAL,
        ...translations.CONTACT
      };
    });

    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseDialog();
    });
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.confirmCloseDialog();
      }
    });
  }

  getContact(contactId: string, checkPermissions: boolean = true) {
    this.api.contact.get(contactId, checkPermissions).subscribe(response => {
      // Assign contact data
      this.contact = response.data;

      if (this.data?.emails?.length > 0) {

        this.contact.emails = [
          ...this.contact.emails,
          ...this.data.emails
        ];

      }

      if (this.data?.phones?.length > 0) {

        this.contact.phones = [
          ...this.contact.phones,
          ...this.data.phones
        ];

      }

    });
  }

  onSave(request: ContactRequest) {
    this.dialogRef.disableClose = true;
    this.loading = true;

    if (this.contact) {
      request.contactId = this.contact.contactId;

      this.api.contact.update(request).subscribe(
        response => {
          this.dialogRef.close(response.data);
        }
      ).add(() => {
        this.loading = false;
        this.dialogRef.disableClose = false;
      });
      return;
    }
    request.enabled = true;
    this.api.contact.save(request).subscribe(
      response => {
        this.dialogRef.close(response.data);
      }
    ).add(() => {
      this.loading = false;
      this.dialogRef.disableClose = false;
    });
  }

  closeContactFormDialog() {
    this.confirmCloseDialog();
  }

  onFormChanged(changed: boolean) {
    this.formChanged = changed;
  }

  confirmCloseDialog() {
    if (this.formChanged) {
      Swal.fire({
        text: this.translations?.PAGE_LEAVE_WITHOUT_SAVING,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F44336',
        cancelButtonColor: '#7a7a7a',
        confirmButtonText: this.translations?.YES,
        cancelButtonText: this.translations?.NO
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

}
