import {
  AuthEffects, authReducer, CountryEffects, countryReducer, NotificationEffects, notificationReducer, SalesOrganizationSettingEffects,
  salesOrganizationSettingReducer, SystemSettingEffects, systemSettingReducer, leadDraftReducer, LeadDraftEffects, recentPageReducer,
  RecentPageEffects, customListReducer, CustomListEffects, activityReducer, staticFieldReducer, StaticFieldEffects,
  customerExperienceTicketHeaderReducer, netPromoterScoreReportReducer, boardDetailReducer, boardDetailReportReducer, BoardDetailReportEffects,
  boardDetailFormDialogReducer, BoardDetailFormDialogEffects, accountReducer, AccountEffects, unsavedChangesReducer
} from '@core/store';
import { BoardDetailEffect } from './board-detail';

export * from './auth';
export * from './country';
export * from './system-setting';
export * from './sales-organization-setting';
export * from './notification';
export * from './activity';
export * from './lead-draft';
export * from './recent-pages';
export * from './static-field';
export * from './custom-list';
export * from './customer-experience-ticket';
export * from './net-promoter-score-report';
export * from './board-detail';
export * from './board-detail-report';
export * from './board-detail-form-dialog';
export * from './account';
export * from './unsaved-changes';

export const appReducer = {
  auth: authReducer,
  salesOrganizationSetting: salesOrganizationSettingReducer,
  systemSetting: systemSettingReducer,
  country: countryReducer,
  notification: notificationReducer,
  activity: activityReducer,
  leadDraft: leadDraftReducer,
  recentPage: recentPageReducer,
  staticField: staticFieldReducer,
  customList: customListReducer,
  customerExperienceTicketHeader: customerExperienceTicketHeaderReducer,
  netPromoterScoreReport: netPromoterScoreReportReducer,
  boardDetail: boardDetailReducer,
  boardDetailReport: boardDetailReportReducer,
  boardDetailFormDialog: boardDetailFormDialogReducer,
  account: accountReducer,
  unsavedChanges: unsavedChangesReducer
};

export const appEffects = [
  AuthEffects,
  SalesOrganizationSettingEffects,
  SystemSettingEffects,
  CountryEffects,
  NotificationEffects,
  LeadDraftEffects,
  RecentPageEffects,
  StaticFieldEffects,
  CustomListEffects,
  BoardDetailReportEffects,
  BoardDetailFormDialogEffects,
  AccountEffects,
  BoardDetailEffect
];
