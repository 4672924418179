export enum EntityType {
    OPPORTUNITY = '49309c21-0b9d-4706-a5ac-dbc85e5af4a5',
    CONTACT = 'f0dd9866-4c1d-4768-b6fc-342729864509',
    ACCOUNT = 'b83bb7f6-4872-45e3-9792-ba8ffb0312b7',
    ACTIVITY = '47655c07-6de6-4704-9f11-829240bf8cfa',
    CX = '8a538e34-8354-4076-873c-ecdc3e4b8a6a',
    MAIL = 'fa987cc3-b564-4fe7-8056-9857cdf25451',
    TASK = 'd28d5589-b9fa-4b95-a401-a87bb9083afa',
    NOTE = 'e031d996-194b-44c4-8387-596b6693a2de',
    DOCUMENT = '0d858158-22b9-43b5-9274-b4fa7bba6261',
    WARNING = 'a70693f3-76da-42d6-97ed-a5be34248a8a',
    BOARD = 'a922ed51-263f-4d03-83cb-3cbe73d188dd',
    ADDRESS = 'fae1829d-38c2-4bf2-9c6c-de21ddb13c41'
}
