import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { MenuItem } from '../menu-item.interface';
import { User } from '@core/api';
import { ToolbarUserChangePasswordComponent } from '../toolbar-user-change-password/toolbar-user-change-password.component';

import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { getUser, selectHasUnsavedChanges, SetUnsavedChanges } from '@core/store';
import { AuthService } from '@core/auth/auth.service';
import { PopoverRef } from '@shared/components/popover/popover-ref';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { trackById } from '@shared/utils';
import { Icon } from '@shared/enums';
import { SharedModule } from '@shared/modules';
import { combineLatest } from 'rxjs';
import Swal from 'sweetalert2';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: 'net-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class ToolbarUserDropdownComponent implements OnInit, OnDestroy {

  user: User;
  toolbarUserChangePasswordDialogRef: MatDialogRef<ToolbarUserChangePasswordComponent>;

  items: MenuItem[] = [
    {
      id: '1',
      icon: Icon.IC_TWOTONE_ACCOUNT_CIRCLE,
      label: this.translate.instant('PROFILE.MY_PROFILE'),
      description: this.translate.instant('PROFILE.PERSONAL_INFORMATION'),
      colorClass: 'text-gray-500',
      route: '/profile'
    },
    // {
    //   id: '2',
    //   icon: icMoveToInbox,
    //   label: 'My Inbox',
    //   description: 'Messages & Latest News',
    //   colorClass: 'text-primary-500',
    //   route: '/apps/chat'
    // },
    // {
    //   id: '3',
    //   icon: icListAlt,
    //   label: 'My Projects',
    //   description: 'Tasks & Active Projects',
    //   colorClass: 'text-amber-500',
    //   route: '/apps/scrumboard'
    // },
    // {
    //   id: '4',
    //   icon: icTableChart,
    //   label: 'Billing Information',
    //   description: 'Pricing & Current Plan',
    //   colorClass: 'text-purple-500',
    //   route: '/pages/pricing'
    // }
  ];

  statuses: OnlineStatus[] = [
    {
      id: 'online',
      label: 'Online',
      icon: Icon.IC_CHECK_CIRCLE,
      colorClass: 'text-green-500'
    },
    {
      id: 'away',
      label: 'Away',
      icon: Icon.IC_TWOTONE_ACCESS_TIME,
      colorClass: 'text-orange-500'
    },
    {
      id: 'dnd',
      label: 'Do not disturb',
      icon: Icon.IC_TWOTONE_DO_NOT_DISTURB,
      colorClass: 'text-red-500'
    },
    {
      id: 'offline',
      label: 'Offline',
      icon: Icon.IC_TWOTONE_OFFLINE_BOLT,
      colorClass: 'text-gray-500'
    }
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  trackById = trackById;
  icPerson = Icon.IC_TWOTONE_PERSON;
  icSettings = Icon.IC_TWOTONE_SETTINGS;
  icChevronRight = Icon.IC_TWOTONE_CHEVRON_RIGHT;
  icArrowDropDown = Icon.IC_TWOTONE_ARROW_DROP_DOWN;
  icBusiness = Icon.IC_TWOTONE_BUSINESS;
  icVerifiedUser = Icon.IC_TWOTONE_VERIFIED_USER;
  icLock = Icon.IC_TWOTONE_LOCK;
  icNotificationsOff = Icon.IC_TWOTONE_NOTIFICATIONS_OFF;

  hasUnsavedChanges: boolean;

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private cd: ChangeDetectorRef,
    private auth: AuthService,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
    private dialog: MatDialog,
    private translate: TranslateService,
    private store: Store,
  ) {

    combineLatest([
      this.store.select(getUser),
      this.store.select(selectHasUnsavedChanges)
    ]).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(([user, hasUnsavedChanges]) => {
      this.user = user;
      this.hasUnsavedChanges = hasUnsavedChanges;
    });
  }

  ngOnInit() {
    this.store.select(getUser).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => this.user = user);
  }

  setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  close() {
    this.popoverRef.close();
  }

  async logout() {
    if (!this.hasUnsavedChanges) {
      this.performLogout();
      return;
    }

    const result = await Swal.fire({
      text: this.translate.instant('GENERAL.PAGE_LEAVE_WITHOUT_SAVING'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F44336',
      cancelButtonColor: '#7a7a7a',
      confirmButtonText: this.translate.instant('GENERAL.YES'),
      cancelButtonText: this.translate.instant('GENERAL.NO')
    });

    if (result.value) {
      this.performLogout();
    }
  }

  private performLogout() {
    this.auth.logout(true, true);
    this.close();
    this.store.dispatch(new SetUnsavedChanges(false));
  }

  openUpdatePasswordDialog() {
    this.toolbarUserChangePasswordDialogRef = this.dialog
      .open(ToolbarUserChangePasswordComponent, { autoFocus: false })
      .addPanelClass('cdk-full-overlay');
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() { }
}
