import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { BaseResponse, FilterRequest, ListCreatedUser, PagedResponse, Response } from '../api.model';
import {
    CustomerExperienceQuickReply, CustomerExperienceQuickReplyDeleteRequest, CustomerExperienceQuickReplyRequest,
    CustomerExperienceQuickReplySearchRequest, CustomerExperienceQuickReplySetOrderRequest, CustomerExperienceQuickReplySetStatusRequest
} from '@core/api';

@Injectable({
    providedIn: 'root'
})
export class CustomerExperienceQuickReplyService {
    constructor(private http: HttpClient) { }
    search(request: CustomerExperienceQuickReplySearchRequest): Observable<PagedResponse<CustomerExperienceQuickReply>> {
        return this.http.post<PagedResponse<CustomerExperienceQuickReply>>(environment.apiUrl + '/api/CxQuickReply/Search', request);
    }

    insert(request: CustomerExperienceQuickReplyRequest): Observable<Response<CustomerExperienceQuickReply>> {
        return this.http.post<Response<CustomerExperienceQuickReply>>(environment.apiUrl + '/api/CxQuickReply/Insert', request);
    }

    update(request: CustomerExperienceQuickReplyRequest): Observable<Response<CustomerExperienceQuickReply>> {
        return this.http.post<Response<CustomerExperienceQuickReply>>(environment.apiUrl + '/api/CxQuickReply/Update', request);
    }

    updateStatus(request: CustomerExperienceQuickReplySetStatusRequest): Observable<Response<CustomerExperienceQuickReply>> {
        return this.http.post<Response<CustomerExperienceQuickReply>>(environment.apiUrl + '/api/CxQuickReply/SetStatus', request);
    }

    delete(request: CustomerExperienceQuickReplyDeleteRequest): Observable<BaseResponse> {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<BaseResponse>(environment.apiUrl + '/api/CxQuickReply/Delete', JSON.stringify(request.cxQuickReplyId), { headers });
    }

    setOrderBy(request: CustomerExperienceQuickReplySetOrderRequest): Observable<Response<CustomerExperienceQuickReply[]>> {
        return this.http.post<Response<CustomerExperienceQuickReply[]>>(environment.apiUrl + '/api/CxQuickReply/SetOrderBy', request);
    }

    listCreatedUsers(filter?: FilterRequest): Observable<Response<ListCreatedUser[]>> {
        return this.http.post<Response<ListCreatedUser[]>>(environment.apiUrl + '/api/CxQuickReply/ListCreatedUsers', filter);
    }

}
