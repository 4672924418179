import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterRequest, ListCreatedUser, Response } from '../api.model';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class LeadDraftInfoService {

    constructor(
        private http: HttpClient
    ) { }

    listCreatedUsers(filter?: FilterRequest): Observable<Response<ListCreatedUser[]>> {
        return this.http.post<Response<ListCreatedUser[]>>(environment.apiUrl + '/api/LeadDraftInfo/ListCreatedUsers', filter);
    }

    listOwnerUsers(filter?: FilterRequest): Observable<Response<ListCreatedUser[]>> {
        return this.http.post<Response<ListCreatedUser[]>>(environment.apiUrl + '/api/LeadDraftInfo/ListOwnerUsers', filter);
    }

}
