import { debounceTime, delay, finalize, map } from 'rxjs/operators';

import {
  User, SurveyMailTypeService, SurveyMail
} from '@core/api';
import { ApiDataSource } from '@core/api/api.data-source';
import { TranslateService } from '@ngx-translate/core';

import { SurveyMailService } from './survey-mail.service';
import { Observable, of } from 'rxjs';
import { } from './survey-mail.model';

export class SurveyMailDataSource extends ApiDataSource<SurveyMail> {

  public isOpenedNewTab = false;

  public $surveyTypes = this.surveyMailTypeService.list({}).pipe(
    map(response => response.data),
    map(types => types.map(item => {
      return {
        key: item.surveyMailListTypeId,
        value: this.translate.instant(`MAIL_LIST.${item.name}`)
      };
    }))
  );


  public $createBys = this.surveyMailService.listCreatedUsers({
    filter: {
      salesOrganizationIds: this.user.salesOrganizations.map(item => item),
    }
  }).pipe(
    map(response => response.data),
    map(statuses => statuses.map(item => {
      return {
        key: item.userId,
        value: item.displayName
      };
    }))
  );

  public $status = this.translate.get(['GENERAL']).pipe(
    map(translations => (
      [
        { key: false, value: translations.GENERAL.PASSIVE },
        { key: true, value: translations.GENERAL.ACTIVE }
      ]))
  );

  public $completionStatus = of([
    { key: false, value: this.translate.instant('MAIL_LIST.NOT_COMPLETED') },
    { key: true, value: this.translate.instant('MAIL_LIST.COMPLETED') }
  ]);

  public $recurrence = of([
    { key: '0', value: this.translate.instant('MAIL_LIST.RECURRING') },
    { key: '1', value: this.translate.instant('MAIL_LIST.ONLY_ONCE') },
    { key: '2', value: this.translate.instant('MAIL_LIST.WHEN_ACTIVITY') }
  ]).pipe(delay(100));

  constructor(
    private surveyMailService: SurveyMailService,
    private surveyMailTypeService: SurveyMailTypeService,
    private translate: TranslateService,
    private user: User,
    protected initialFilter?: any
  ) {
    super(initialFilter);
  }

  load(): void {
    if (!this.isOpenedNewTab) {


      // Init filter with data source's default filter
      const filter: any = { ...this.initialFilter, ...this.filter };

      // console.log("Initial Filter ==== >", filter);

      // If filter keyword exists, filter data
      if (this.keyword) {
        filter.searchText = this.keyword;
      }

      // Update loading state
      this.loadingSubject.next(true);

      // Create request parameters
      const request = this.getRequest();

      if (this.paginator?.pageSize) {
        request.pageSize = this.paginator.pageSize;
      }

      // Add filters to request
      request.filter = filter;

      // Fetch data
      this.surveyMailService
        .search(request)
        .pipe(
          debounceTime(400),
          finalize(() => this.loadingSubject.next(false))
        ).subscribe(response => {
          // Update count and data subjects
          this.dataSubject.next(response.data.results);
          // console.log(this.dataSubject)
          this.dataCountSubject.next(response.data.rowCount);

          // Update data source's empty based row count
          this.empty = response.data.rowCount === 0;
        });

    }
    return this.loadingSubject.next(false);
  }
}
