import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CombinedTranslationService implements TranslateLoader {

    constructor(private http: HttpClient) { }

    getTranslation(culture?: string) {

        return forkJoin([
            this.http.get<any>(`/assets/i18n/management/en.json`),
            this.http.get<any>(environment.apiUrl + '/api/Translation/All?culture=' + culture, {})
        ]).pipe(map(([staticTranslations, apiTranslations]) => {
            // Statik ve API çevirilerini birleştir
            return { ...staticTranslations, ...apiTranslations };
        }));
    }
}
