<form [class.dialog]="isDialog" [class.page]="isPage" [formGroup]="form" class="form-wrapper">
  <ng-template #content>
    <div fxLayout="column">
      <ng-container *ngIf="opportunity !== undefined">
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <mat-form-field fxFlex>
            <mat-label>{{ "GENERAL.CUSTOMER" | translate }}</mat-label>
            <net-select-account formControlName="account" [disabled]="form.get('account').disabled"
              class="customer-name-zone" [isClickable]="!!opportunity?.opportunityId"
              (selectionChange)="onSelectionChange()"></net-select-account>
          </mat-form-field>
          <mat-form-field fxFlex *ngIf="getStaticFieldActiveFlag(staticFieldEnum.OPPORTUNITY_NAME)">
            <mat-label>{{ "GENERAL.OPPORTUNITY_NAME" | translate }}</mat-label>
            <input formControlName="name" matInput netSpaceControl
              [required]="getStaticFieldRequiredFlag(staticFieldEnum.OPPORTUNITY_NAME)" />
          </mat-form-field>
        </div>

        <mat-form-field *ngIf="getStaticFieldActiveFlag(staticFieldEnum.CONTACTS)">
          <mat-label>{{ "GENERAL.CONTACTS" | translate }}</mat-label>
          <net-input-participant [customer]="form.get('account').value?.customer" [optionalDisabled]="form.disabled"
            [permissionControl]="true" [removeSelf]="false" [salesOrganizationId]="
              account
                ? account.salesOrganizationId
                : form.get('account').value?.salesOrganizationId
            " formControlName="participants" [required]="addParticipantsRequired()"
            [allowInsertContact]="account ? account?.accountType !== AccountTypeString.CONSUMER : form.get('account').value?.accountType !== AccountTypeString.CONSUMER"></net-input-participant>
        </mat-form-field>

        <div fxLayout="grid" gdColumns="1fr" gdColumns.gt-xs="1fr 1fr 1fr" fxLayout.gt-xs="row" gdGap="0px 24px">
          <mat-form-field fxFlex *ngIf="getStaticFieldActiveFlag(staticFieldEnum.ORGANIZATION)">
            <mat-label>{{ "GENERAL.ORGANIZATION" | translate }}</mat-label>
            <mat-select formControlName="organization"
              [required]="getStaticFieldRequiredFlag(staticFieldEnum.ORGANIZATION)">
              <mat-option *ngFor="let organization of organizations" [value]="organization.organizationId">
                {{ organization.organizationName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex *ngIf="form.get('account').value">
            <mat-label>{{
              "OPPORTUNITY.OPPORTUNITY_TYPE" | translate
              }}</mat-label>
            <input *ngIf="opportunity"
              [value]="opportunity.opportunityType ? ('OpportunityType.' + opportunity.opportunityType.name | translate) : ''"
              matInput [disabled]="true" />

            <net-select-opportunity-type *ngIf="!opportunity" [accountId]="
                account
                  ? account.accountId
                  : form.get('account').value.accountId
              " [organizationId]="form.get('organization').value"
              formControlName="opportunityType"></net-select-opportunity-type>
          </mat-form-field>

          <mat-form-field fxFlex floatLabel="always">
            <mat-label>{{ "OPPORTUNITY.CREATION_DATE" | translate }}</mat-label>
            <input [disabled]="form.disabled" [value]="
                (opportunity ? opportunity.createdOn : today)
                  | momentFormat : 'DD MMMM YYYY'
              " matInput readonly />
          </mat-form-field>
          <mat-form-field fxFlex *ngIf="getStaticFieldActiveFlag(staticFieldEnum.CURRENCY)">
            <mat-label>{{ "GENERAL.CURRENCY" | translate }}</mat-label>
            <net-select-currency formControlName="currency"
              [required]="getStaticFieldRequiredFlag(staticFieldEnum.CURRENCY)"
              [onlyEnabledCurrencies]="true"></net-select-currency>
          </mat-form-field>

          <mat-form-field class="mat-slider-container" fxFlex
            *ngIf="getStaticFieldActiveFlag(staticFieldEnum.PROBABILITY)">
            <mat-label>{{
              "GENERAL.PROBABILITY_OF_REALIZATION" | translate
              }}</mat-label>
            <net-input-slider formControlName="probabilityRate"
              [required]="getStaticFieldRequiredFlag(staticFieldEnum.PROBABILITY)" [max]="100" [min]="10" prefix="%"
              [step]="10">
            </net-input-slider>
          </mat-form-field>

          <mat-form-field (click)="checkExpectedDateRules() ? openExpectedDateDialog() : null" fxFlex>
            <mat-label>{{
              "GENERAL.ESTIMATED_REALIZATION_DATE" | translate
              }}</mat-label>
            <input (click)="datePicker.open()" [matDatepicker]="datePicker" [max]="max" [min]="getMin()"
              formControlName="expectedStartMonth" matInput readonly />
            <mat-datepicker #datePicker></mat-datepicker>
            <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
          </mat-form-field>
        </div>
        <net-input-dynamic-field-values [organizationId]="form.get('organization').value"
          [entityName]="DynamicFieldEntityAsString.OPPORTUNITY"
          [salesOrganizationId]="form.get('account').value?.salesOrganizationId" [serviceIds]="selectedServiceIds"
          [opportunityTypeId]="form.get('opportunityType').value"
          [opportunityStatusId]="form.get('opportunityStatus').value" [entityId]="DynamicFieldEntityId.OPPORTUNITY"
          [isDisabled]="form.disabled" [disabled]="form.disabled" [mode]="dynamicFieldMode" [columns]="3"
          [openSeparator]="openSeparator" (dynamicFieldSearch)="onDynamicFieldSearch($event)"
          formControlName="dynamicFieldValues"></net-input-dynamic-field-values>

        <mat-form-field *ngIf="staticFields && staticFields.length > 0 && revenueTypes.length > 0" floatLabel="always"
          class="overflow-hidden overflow-x-auto flex smallTablet:block">
          <mat-label>{{ "GENERAL.SERVICES" | translate }}</mat-label>
          <net-input-opportunity-services [revenueTypes]="revenueTypes" (update)="calculateExpectedRevenue($event)"
            [staticFields]="staticFields"
            [currencyCode]="form.get('currency').value?.isoCurrencyCode ?? defaultCurrency?.isoCurrencyCode"
            [optionalDisabled]="form.disabled" [organizationId]="form.get('organization').value"
            [organizationMainServiceService]="organizationMainServiceService" formControlName="services"
            (serviceLoading)="serviceLoading = $event">
          </net-input-opportunity-services>
        </mat-form-field>

        <div fxLayout="column-reverse" fxLayout.gt-xs="row" class="mb-5">
          <div *ngIf="hasGeneralConditions" fxFlex="100" fxFlex.gt-xs="53" fxFlex.gt-lg="40" fxFlexOffset.lt-sm="1em"
            fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="start center" class="general-conditions">
            <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-lg="40">
              <mat-checkbox formControlName="useGeneralConditions" color="primary"
                [disabled]="!hasGeneralConditionsPermission">
                {{ 'OPPORTUNITY.USE_GENERAL_CONDITIONS' | translate }}
              </mat-checkbox>
            </div>

            <div *ngIf="!form.get('useGeneralConditions').value" fxFlex="100" fxFlex.gt-xs="45" fxFlex.gt-lg="40"
              fxFlexOffset.lt-sm="1em">
              <mat-form-field class="mb-0" fxFlex.gt-xs="70" fxFlex.gt-lg="100">
                <mat-label>{{ "OPPORTUNITY.TEMPLATE" | translate }}</mat-label>
                <mat-select formControlName="template" [disabled]="!hasGeneralConditionsPermission"
                  (selectionChange)="onChangeTemplate($event)">
                  <mat-option *ngFor="let template of opportunityParameters" [value]="template.opportunityParameterId">
                    {{ template.templateName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div fxFlex="100" [fxFlex.gt-xs]="hasGeneralConditions ? 47 : 100"
            [fxFlex.gt-lg]="hasGeneralConditions ? 60 : 100" fxLayout="column" fxFlexOffset.lt-sm="1em"
            fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="end center" fxLayoutGap="10px">
            <div *ngIf="
              form.get('currency').value &&
              defaultCurrency &&
              form.get('currency').value.transactionCurrencyId !==
                defaultCurrency.transactionCurrencyId &&
              form.get('expectedStartMonth').value &&
              opportunityCurrencyExchange
            " class="expected-revenue exchange-rate" [ngClass]="{ 'mr-auto': !hasGeneralConditions }">
              <p class="alert alert-icon alert-primary">
                <net-iconify-icon [icon]="icInfo"></net-iconify-icon>
                <span>{{
                  "GENERAL.EXCHANGE_RATE_WITH_VALUE"
                  | translate
                  : {
                  exchangeRate: opportunityCurrencyExchange?.exchangeRate
                  }
                  }}</span>
              </p>
            </div>
            <div *ngIf="form.get('totalMonthlyRevenue').value > 0" class="expected-revenue">
              <label class="text-xs font-medium">{{
                "GENERAL.MONTHLY" | translate
                }}</label>
              <div>
                {{
                form.get("totalMonthlyRevenue").value
                | currency
                : form.get("currency").value?.isoCurrencyCode ?? defaultCurrency?.isoCurrencyCode
                : "symbol-narrow"
                | currencyReplace
                }}
                <ng-container *ngIf="
                    form.get('currency').value &&
                    defaultCurrency &&
                    form.get('currency').value.transactionCurrencyId !==
                      defaultCurrency.transactionCurrencyId
                  ">
                  /
                  {{
                  form.get("totalMonthlyRevenue").value *
                  opportunityCurrencyExchange?.exchangeRate
                  | currency
                  : defaultCurrency.isoCurrencyCode
                  : "symbol-narrow"
                  }}
                </ng-container>
              </div>
            </div>
            <div *ngIf="form.get('totalForOnceRevenue').value > 0" class="expected-revenue">
              <label class="text-xs font-medium">{{
                "GENERAL.NON_RECURRING" | translate
                }}</label>
              <div>
                {{
                form.get("totalForOnceRevenue").value
                | currency
                : form.get("currency").value?.isoCurrencyCode ?? defaultCurrency?.isoCurrencyCode
                : "symbol-narrow"
                | currencyReplace
                }}
                <ng-container *ngIf="
                    form.get('currency').value &&
                    defaultCurrency &&
                    form.get('currency').value.transactionCurrencyId !==
                      defaultCurrency.transactionCurrencyId
                  ">
                  /
                  {{
                  form.get("totalForOnceRevenue").value *
                  opportunityCurrencyExchange?.exchangeRate
                  | currency
                  : defaultCurrency.isoCurrencyCode
                  : "symbol-narrow"
                  }}
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="hasGeneralConditions" fxLayout="column" class="mb-2">
          <mat-form-field *ngIf="!form.get('useGeneralConditions').value && form.get('template').value"
            class="ck-editor-overflow">
            <mat-label>{{ "OPPORTUNITY.GENERAL_CONDITIONS" | translate }}</mat-label>
            <net-input-editor-ck [type]="'Opportunity'" formControlName="generalConditionsTemplate"
              [isDisabled]="!hasGeneralConditionsPermission" class="w-full">
            </net-input-editor-ck>
          </mat-form-field>
        </div>
        <mat-form-field *ngIf="getStaticFieldActiveFlag(staticFieldEnum.DESCRIPTION)" class="ck-editor-overflow">
          <mat-label>{{ "GENERAL.DESCRIPTION" | translate }}</mat-label>
          <net-input-editor-ck [type]="'Opportunity'" formControlName="description" (isFormChange)="setNoChange()"
            [required]="getStaticFieldRequiredFlag(staticFieldEnum.DESCRIPTION)" class="w-full"></net-input-editor-ck>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="opportunity === undefined">
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
        </div>
        <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
        </div>
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
        </div>
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
        </div>
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
        </div>
        <ngx-skeleton-loader appearance="line" class="textarea" fxFlex></ngx-skeleton-loader>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #action>
    <div class="mr-2" *ngIf="
        opportunity !== null  && opportunity?.opportunityId !== guid.EMPTY&&
        activeUser?.userId !== opportunity?.createdUser?.userId &&
          !hasOpportunityUpdateAllPermission
      ">
      <div *ngIf="
          (form.get('opportunityType').value ===
            opportunityTypeOption.VOLUME_DEVELOPMENT &&
            this.opportunityProceses.DETAILED === this.opportunityProcess) ||
          this.opportunityProceses.BASIC === this.opportunityProcess ||
          (opportunityStatus.CREATED === opportunity?.opportunityStatusId &&
            opportunityProceses.DETAILED === opportunityProcess)
        ">
        <p class="alert alert-icon alert-warning">
          <net-iconify-icon [icon]="icWarning"></net-iconify-icon>
          {{
          "OPPORTUNITY.OPPORTUNITY_DETAIL_CAN_ONLY_BE_EDIT_BY_THE_CREATOR_USER"
          | translate
          }}
        </p>
      </div>
    </div>

    <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="1rem"
      *ngIf="opportunity?.opportunityId !== null &&opportunity?.opportunityId !== guid.EMPTY">
      <net-progress-button (click)="sendMailWithOpportunity()" *ngIf="
            hasOpportunityOfferPdfPermission && hasSendingMailWithOpportunity &&
            (hasOpportunityUpdateAllPermission || activeUser?.userId === opportunity?.createdUser?.userId)
          " [active]="loading" [stroked]="true" [icon]="icPdf" [raised]="false" type="button"
        [text]="'OPPORTUNITY.SEND_OFFER' | translate">
      </net-progress-button>

      <net-progress-button (click)="$event.stopPropagation()" *ngIf="
            opportunity &&
            isPage && hasOfferPdf &&
            opportunityProcess === opportunityProceses.BASIC &&
            hasOpportunityOfferPdfPermission && !hasSendingMailWithOpportunity
          " [active]="loading" [stroked]="true" [icon]="icPdf" [matMenuTriggerFor]="menu" [raised]="false"
        type="button" [text]="'OPPORTUNITY.CREATE_PDF' | translate">
      </net-progress-button>

      <net-progress-button (btnClick)="openTerminationDialog()" *ngIf="
            opportunity !== null &&
            ![
              opportunityStatus.LOST,
              opportunityStatus.CANCELLED,
              opportunityStatus.PASSIVE,
              opportunityStatus.ACTIVE
            ].includes(opportunity?.opportunityStatusId) &&
              hasOpportunityUpdatePermission &&
              form.get('opportunityType').value !==
                opportunityTypeOption.VOLUME_DEVELOPMENT &&
              opportunityProceses.DETAILED === opportunityProcess &&
            (hasOpportunityUpdateAllPermission ||
              activeUser?.userId === opportunity?.createdUser?.userId)
          " [active]="loading" [raised]="false" [stroked]="true" [text]="'OPPORTUNITY.EDIT_OPPORTUNITY' | translate">
      </net-progress-button>

      <net-progress-button (btnClick)="copyOpportunityConfirm()" *ngIf="opportunity?.opportunityId
            && ((hasOpportunityCopyPermission && opportunity?.createdUser?.userId === activeUser?.userId)
            || (hasOpportunityUpdateAllPermission && hasOpportunityCopyPermission))
          " [active]="loading" [icon]="icCopy" [stroked]="true" [raised]="false" type="button"
        [text]="'OPPORTUNITY.COPY' | translate">
      </net-progress-button>

      <net-progress-button *ngIf="
          hasCopySummaryUsage && hasOpportunityCopySummaryPermission &&
          (hasOpportunityUpdateAllPermission || activeUser?.userId === opportunity?.createdUser?.userId)
        " type="button" [stroked]="true" [raised]="false" [active]="loading" [icon]="icTextSummaryLtr"
        (btnClick)="copySummary()" [text]="'OPPORTUNITY.COPY_SUMMARY' | translate">
      </net-progress-button>

      <net-progress-button (btnClick)="delete()"
        *ngIf="hasOpportunityDeletePermission && opportunity !== null && opportunity?.opportunityId && opportunity?.opportunityId !== guid.EMPTY"
        type="button" [stroked]="true" [raised]="false" [active]="loading" [icon]="icDelete"
        [text]="'GENERAL.DELETE' | translate">
      </net-progress-button>
    </div>

    <div class="ml-auto flex items-end">
      <net-progress-button (btnClick)="openFinancialInquiryDialog()" *ngIf="
          opportunity !== null && opportunity?.opportunityId && opportunity?.opportunityId !== guid.EMPTY &&
          opportunityStatus.OFFER === opportunity?.opportunityStatusId &&
          isFinanceUser &&
          form.get('opportunityType').value !==
            opportunityTypeOption.VOLUME_DEVELOPMENT &&
          opportunityProceses.DETAILED === opportunityProcess
        " [active]="financialInquiryLoading" [icon]="icEye" [text]="'OPPORTUNITY.FINANCIAL_INQUIRY' | translate"
        class="ml-4">
      </net-progress-button>

      <net-progress-button (btnClick)="offerPdfAction = 'save'; onSubmit('offer')" *ngIf="
          opportunity !== null &&opportunity?.opportunityId && opportunity?.opportunityId !== guid.EMPTY &&
          (systemUserStructure || hasContractAdmin) &&
            opportunityStatus.CREATED === opportunity?.opportunityStatusId &&
            hasOpportunityUpdatePermission &&
            form.get('opportunityType').value !==
              opportunityTypeOption.VOLUME_DEVELOPMENT &&
            opportunityProceses.DETAILED === opportunityProcess &&
          (hasOpportunityUpdateAllPermission ||
            activeUser?.userId === opportunity?.createdUser?.userId)
        " [active]="loading" [icon]="icList" [text]="'OPPORTUNITY.CREATE_OFFER' | translate" class="ml-4">
      </net-progress-button>

      <mat-form-field *ngIf="
          opportunityProcess &&
          (form.get('opportunityType').value ===
            opportunityTypeOption.VOLUME_DEVELOPMENT ||
            opportunityProceses.BASIC === opportunityProcess)
        " class="ml-4 opportunity-status">
        <mat-label>{{ "GENERAL.STATUS" | translate }}</mat-label>
        <net-select-opportunity-status [opportunityProcess]="opportunityProcess" [opportunity]="opportunity"
          [salesOrganizationId]="
            account
              ? account.salesOrganizationId
              : form.get('account').value?.salesOrganizationId
          " formControlName="opportunityStatus" (selectionChange)="statusChanges($event)">
        </net-select-opportunity-status>
      </mat-form-field>

      <net-progress-button (btnClick)="offerPdfAction = 'save'; onSubmit()" *ngIf="
          !opportunity ||
          opportunityStatus.CREATED === opportunity?.opportunityStatusId ||
          form.get('opportunityType').value ===
            opportunityTypeOption.VOLUME_DEVELOPMENT ||
          opportunityProceses.BASIC === opportunityProcess
        " [active]="loading || serviceLoading" [disabled]="
          (opportunity !== null &&
            ((opportunityStatus.ACTIVE === opportunity?.opportunityStatusId &&
              opportunityProceses.DETAILED === opportunityProcess) ||
              !hasOpportunityUpdatePermission) &&
              opportunity?.opportunityTypeId !==
                opportunityTypeOption.VOLUME_DEVELOPMENT) ||
          (!hasOpportunityUpdateAllPermission &&
            this.opportunity !== null &&
              this.activeUser?.userId !== this.opportunity?.createdUser?.userId)
        " [icon]="icSave" [text]="'GENERAL.SAVE' | translate" class="ml-4">
      </net-progress-button>
    </div>
  </ng-template>

  <ng-template [ngIf]="isDialog">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
      <h2 class="m-0" fxFlex="auto">
        <span *ngIf="opportunity">{{ opportunity.name }}</span>
        <span *ngIf="!opportunity">{{
          "OPPORTUNITY.NEW_OPPORTUNITY" | translate
          }}</span>
      </h2>

      <button class="text-secondary" mat-icon-button type="button" (click)="closeOpportunityFormDialog()">
        <net-iconify-icon [icon]="icClose"></net-iconify-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 mb-2 text-border"></mat-divider>
    <mat-dialog-content>
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
      <ng-container [ngTemplateOutlet]="action"></ng-container>
    </mat-dialog-actions>
  </ng-template>
  <ng-template [ngIf]="isPage">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
    <div class="w-full" fxLayout="row" fxLayoutAlign="start center">
      <ng-container [ngTemplateOutlet]="action"></ng-container>
    </div>
  </ng-template>

</form>

<ng-template #terminationDialog>
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="m-0" fxFlex="auto">
      <span>{{ "OPPORTUNITY.EDIT_OPPORTUNITY" | translate }}</span>
    </h2>

    <button class="text-secondary" (click)="closeDialog()" mat-icon-button type="button">
      <net-iconify-icon [icon]="icClose"></net-iconify-icon>
    </button>
  </div>
  <mat-divider class="-mx-6 mb-2 text-border"></mat-divider>

  <mat-dialog-content [formGroup]="terminationForm" class="pb-4" fxLayout="column">
    <div class="my-4" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
      <mat-radio-group (change)="reasonFormFields = !$event.value" formControlName="reason" gdColumns.gt-sm="1fr">
        <ng-container *ngIf="checkExpectedDateRules()">
          <mat-radio-button
            [class.opacity-50]="terminationForm.get('reason').value && terminationForm.get('reason').value !== 1"
            [value]="1" class="mr-12" class="mat-radio-group" color="primary">
            <b class="font-medium">{{
              "OPPORTUNITY.CHANGE_ESTIMATED_REALIZATION_DATE" | translate
              }}</b>
            <br />
            <span>{{
              "OPPORTUNITY.YOU_CAN_UPDATE_THE_DATE_FROM_THE_WINDOW_THAT_OPENS_WITH_THE_CONFIRM_BUTTON"
              | translate
              }}</span>
          </mat-radio-button>
          <br />
        </ng-container>

        <mat-radio-button
          [class.opacity-50]="terminationForm.get('reason').value && terminationForm.get('reason').value !== 2"
          [value]="2" class="mr-12" class="mat-radio-group" color="primary">
          <b class="font-medium">{{
            "OPPORTUNITY.REVERT_OPPORTUNITY_TO_DRAFT" | translate
            }}</b>
          <br />
          <span>{{
            "OPPORTUNITY.PRICE_LISTS_CONTRACT_AND_DOCUMENT_APPROVALS_WILL_BE_DELETED"
            | translate
            }}</span>
        </mat-radio-button>
        <br />

        <mat-radio-button
          [class.opacity-50]="terminationForm.get('reason').value && terminationForm.get('reason').value !== 3"
          [value]="3" class="mr-12" class="mat-radio-group" color="primary">
          <b class="font-medium">{{
            "OPPORTUNITY.END_OPPORTUNITY" | translate
            }}</b>
          <br />
          <span>{{
            "OPPORTUNITY.OPPORTUNITY_WILL_BE_RECORDED_AS_READONLY_THIS_ACTION_CAN_NOT_BE_UNDONE"
            | translate
            }}</span>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <ng-container *ngIf="terminationForm.get('reason').value && terminationForm.get('reason').value === 3">
      <span [innerHTML]="
          'OPPORTUNITY.YOU_ARE_TERMINATING_THE_OPPORTUNITY_FOR_ACCOUNT'
            | translate
              : { opportunity: opportunity.name, account: account.name }
        " class="mb-2">
      </span>

      <mat-form-field>
        <mat-label>{{ "OPPORTUNITY.CATEGORY" | translate }}</mat-label>
        <mat-select formControlName="status" (selectionChange)="onChangeStatus($event)">
          <mat-option [value]="opportunityStatus.LOST">{{
            "OPPORTUNITY.LOST" | translate
            }}</mat-option>
          <mat-option *ngIf="opportunity.isCancelable !== false" [value]="opportunityStatus.CANCELLED">
            {{ "OPPORTUNITY.CANCELLED" | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="isOpportunityStatusLost">
        <mat-label>{{ "OPPORTUNITY.LOST_REASON" | translate }}</mat-label>
        <mat-select formControlName="lostReasonType">
          <mat-option *ngFor="let type of lostReasonTypes" [value]="type.opportunityLostReasonTypeId">
            {{ ('OpportunityLostReasonType.' + type.name) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ (isOpportunityStatusLost ? "GENERAL.DETAIL" : "OPPORTUNITY.REASON") | translate }}</mat-label>
        <textarea cdkAutosizeMaxRows="5" cdkAutosizeMinRows="2" formControlName="failureDescription"
          matInput></textarea>
      </mat-form-field>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
    <net-progress-button (btnClick)="onSubmitTerminationReason()" *ngIf="opportunity !== null"
      [active]="loading || actionLoading" [color]="'primary'"
      [text]="'OPPORTUNITY.YES_I_ACCEPT' | translate"></net-progress-button>
  </mat-dialog-actions>
</ng-template>

<ng-template #lostInformationDialog>
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="m-0" fxFlex="auto">
      <span>{{ "OPPORTUNITY.LOST_INFORMATION" | translate }}</span>
    </h2>

    <button class="text-secondary" (click)="closeDialog()" mat-icon-button type="button">
      <net-iconify-icon [icon]="icClose"></net-iconify-icon>
    </button>
  </div>
  <mat-divider class="-mx-6 mb-2 text-border"></mat-divider>

  <mat-dialog-content [formGroup]="lostInformationForm" class="pb-4" fxLayout="column">
    <ng-container>
      <mat-form-field>
        <mat-label>{{ "OPPORTUNITY.LOST_REASON" | translate }}</mat-label>
        <mat-select formControlName="lostReasonType">
          <mat-option *ngFor="let type of lostReasonTypes" [value]="type.opportunityLostReasonTypeId">
            {{ ('OpportunityLostReasonType.' + type.name) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "GENERAL.DETAIL" | translate }}</mat-label>
        <textarea cdkAutosizeMaxRows="5" cdkAutosizeMinRows="2" [maxlength]="180" formControlName="failureDescription"
          matInput></textarea>
      </mat-form-field>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
    <button class="mr-4" color="secondary" mat-button (click)="closeDialog()">{{'GENERAL.CANCEL' | translate}}</button>
    <net-progress-button (btnClick)="onSubmitLostInformation()" [active]="loading" [color]="'primary'" [icon]="icSave"
      [text]="'GENERAL.SAVE' | translate"></net-progress-button>
  </mat-dialog-actions>
</ng-template>

<ng-template #expectedDateDialog>
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="m-0" fxFlex="auto">
      <span>{{ "GENERAL.ESTIMATED_REALIZATION_DATE" | translate }}</span>
    </h2>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <net-iconify-icon [icon]="icClose"></net-iconify-icon>
    </button>
  </div>
  <mat-divider class="-mx-6 mb-2 text-border"></mat-divider>
  <mat-dialog-content [formGroup]="expectedDateForm" fxLayout="column">
    <div class="my-2 expected-date" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
      <mat-form-field fxFlex>
        <mat-label>{{
          "GENERAL.ESTIMATED_REALIZATION_DATE" | translate
          }}</mat-label>
        <input (click)="expectedDatePicker.open()" [matDatepicker]="expectedDatePicker" [min]="today"
          formControlName="expectedDate" matInput [max]="max" readonly />
        <mat-datepicker #expectedDatePicker></mat-datepicker>
        <mat-datepicker-toggle [for]="expectedDatePicker" matSuffix></mat-datepicker-toggle>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
    <net-progress-button (btnClick)="onSubmitExpectedDate()" [active]="loading" [color]="'primary'" [icon]="icSave"
      [text]="'GENERAL.SAVE' | translate"></net-progress-button>
  </mat-dialog-actions>

  <div *ngIf="opportunityExpectedDateHistory.length > 0">
    <mat-divider class="-mx-6 mt-4 text-border"></mat-divider>
    <h2 class="my-2">{{ "GENERAL.HISTORY" | translate }}</h2>
    <div class="history-list">
      <div *ngFor="
          let history of opportunityExpectedDateHistory;
          let index = index
        " class="my-3">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
          <span>{{ history.createdOn | momentFormat : "DD MMMM YYYY HH:mm" }} -
            <strong>{{ history.createdUser.firstName }}
              {{ history.createdUser.lastName }}
            </strong>
            <span>
              - {{ history.expectedStartMonth | momentFormat : "DD MMMM YYYY" }}
            </span>
          </span>
          <span class="ml-1">
            {{ history.newDate | momentFormat : "DD MMMM YYYY" }}
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<mat-menu #menu="matMenu" xPosition="before" yPosition="below">
  <ng-template matMenuContent>
    <button (click)="$event.stopPropagation()" [matMenuTriggerFor]="templateButtons"
      [matMenuTriggerData]="{action: 'download'}" color="primary" mat-menu-item>
      <net-iconify-icon [icon]="icDownload"></net-iconify-icon>
      <span>{{ "GENERAL.DOWNLOAD" | translate }}</span>
    </button>

    <button (click)="$event.stopPropagation()" [matMenuTriggerFor]="templateButtons"
      [matMenuTriggerData]="{action: 'preview'}" color="primary" mat-menu-item>
      <net-iconify-icon [icon]="icPdfOutLine"></net-iconify-icon>
      <span>{{ "GENERAL.PREVIEW" | translate }}</span>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #templateButtons="matMenu" xPosition="before" yPosition="below">
  <ng-template matMenuContent let-action="action">
    <button *ngFor="let template of opportunityParameters" (click)="offerPdf(action, template.opportunityParameterId)"
      color="primary" mat-menu-item>
      <span>{{ template.templateName }}</span>
    </button>
  </ng-template>
</mat-menu>