import { CustomerExperienceChatModel } from '@core/api/customer-experience-ticket/customer-experience-ticket.model';
import { CustomerExperienceTicketSignalRResponseModel } from '../customer-experience-ticket-signal-r.model';
import { CustomerExperienceTicketDetailActionTypeEnum } from '../customer-experience-ticket-signal-r.enum';
import { CustomerExperienceChatMessageTypeStringEnum } from '@core/api';

export function setChat(message: CustomerExperienceTicketSignalRResponseModel, conversation: CustomerExperienceChatModel[]): CustomerExperienceChatModel[] {

    conversation = setLogic(message, conversation);

    return conversation;
}

export function setLogic(message: CustomerExperienceTicketSignalRResponseModel, conversation: CustomerExperienceChatModel[]) {

    switch (message.enumTicketActionType) {
        case CustomerExperienceTicketDetailActionTypeEnum.GET_TICKET_MESSAGES:
            handleGetTicketMessages(message, conversation);
            break;
        case CustomerExperienceTicketDetailActionTypeEnum.TICKET_MESSAGE_RESULT:
            handleTicketMessageResult(message, conversation);
            break;
        case CustomerExperienceTicketDetailActionTypeEnum.TICKET_LOG:
            handleTicketLog(message, conversation);
            break;
        case CustomerExperienceTicketDetailActionTypeEnum.TICKET_PRIVATE_NOTE:
            handlePrivateNote(message, conversation);
            break;
        case CustomerExperienceTicketDetailActionTypeEnum.TICKET_MESSAGE_REMOVED:
            handleTicketMessageRemoved(message, conversation);
            break;
    }
    return conversation;

}

function handleGetTicketMessages(message: CustomerExperienceTicketSignalRResponseModel, conversation: CustomerExperienceChatModel[]) {
    message.data.forEach(ticketMessage => {
        conversation.push(ticketMessage as CustomerExperienceChatModel);
    });
}

function handleTicketMessageResult(message: CustomerExperienceTicketSignalRResponseModel, conversation: CustomerExperienceChatModel[]) {
    message.data.forEach(ticketMessage => {
        const agentMessage: CustomerExperienceChatModel = {
            chatMessageType: CustomerExperienceChatMessageTypeStringEnum.AgentMessage,
            ticketMessage,
            createdOn: ticketMessage.createdOn,
            ticketMessageId: ticketMessage.ticketMessageId
        };
        conversation.push(agentMessage);
    });
}

function handleTicketLog(message: CustomerExperienceTicketSignalRResponseModel, conversation: CustomerExperienceChatModel[]) {
    message.data.forEach(ticketLog => {
        const log: CustomerExperienceChatModel = {
            chatMessageType: CustomerExperienceChatMessageTypeStringEnum.TicketLog,
            createdOn: ticketLog.createdOn,
            ticketLog
        };
        conversation.push(log);
    });
}

function handlePrivateNote(message: CustomerExperienceTicketSignalRResponseModel, conversation: CustomerExperienceChatModel[]) {
    message.data.forEach(ticketPrivateNote => {
        const log: CustomerExperienceChatModel = {
            chatMessageType: CustomerExperienceChatMessageTypeStringEnum.PrivateNote,
            createdOn: ticketPrivateNote.createdOn,
            ticketPrivateNote
        };
        conversation.push(log);
    });
}

function handleTicketMessageRemoved(message: CustomerExperienceTicketSignalRResponseModel, conversation: CustomerExperienceChatModel[]) {
    message.data.forEach(ticketMessage => {
        const removedMessageId = ticketMessage.ticketMessage.ticketMessageId;
        if (removedMessageId) {
            const index = conversation.findIndex(msg => msg.ticketMessage?.ticketMessageId === removedMessageId);
            if (index !== -1) {
                conversation.splice(index, 1);
            }
        }
    });
}
