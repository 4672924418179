import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { BaseResponse, PagedResponse, Response } from '../api.model';
import {
    CustomerExperienceUnwantedPhrase,
    CustomerExperienceUnwantedPhraseDeleteRequest,
    CustomerExperienceUnwantedPhraseDownload,
    CustomerExperienceUnwantedPhraseSearchRequest,
    CustomerExperienceUnwantedPhraseRequest
} from '@core/api';

@Injectable({
    providedIn: 'root'
})
export class CustomerExperienceUnwantedPhraseService {
    constructor(private http: HttpClient) { }

    get(request: string) {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<CustomerExperienceUnwantedPhrase>>(environment.apiUrl + '/api/CxUnwantedPhrase/Get',
            JSON.stringify(request), { headers });
    }
    search(request: CustomerExperienceUnwantedPhraseSearchRequest): Observable<PagedResponse<CustomerExperienceUnwantedPhrase>> {
        return this.http.post<PagedResponse<CustomerExperienceUnwantedPhrase>>(environment.apiUrl + '/api/CxUnwantedPhrase/Search', request);
    }

    insert(request: CustomerExperienceUnwantedPhraseRequest): Observable<Response<CustomerExperienceUnwantedPhrase>> {
        return this.http.post<Response<CustomerExperienceUnwantedPhrase>>(environment.apiUrl + '/api/CxUnwantedPhrase/Insert', request);
    }

    update(request: CustomerExperienceUnwantedPhraseRequest): Observable<Response<CustomerExperienceUnwantedPhrase>> {
        return this.http.post<Response<CustomerExperienceUnwantedPhrase>>(environment.apiUrl + '/api/CxUnwantedPhrase/Update', request);
    }

    delete(request: CustomerExperienceUnwantedPhraseDeleteRequest): Observable<BaseResponse> {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<BaseResponse>(environment.apiUrl + '/api/CxUnwantedPhrase/Delete', JSON.stringify(request.cxUnwantedPhraseId), { headers });
    }

    download(request: any): Observable<Response<CustomerExperienceUnwantedPhraseDownload[]>> {
        return this.http.post<Response<CustomerExperienceUnwantedPhraseDownload[]>>(environment.apiUrl + '/api/CxUnwantedPhrase/Download', request);
    }
}
