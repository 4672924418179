import { of } from 'rxjs';
import { ApiDataSource } from '../api.data-source';
import { debounceTime, finalize, map } from 'rxjs/operators';
import { CustomerExperienceSLATargetService } from './customer-experience-sla-target.service';
import { TranslateService } from '@ngx-translate/core';
import { ChartType } from '../customer-experience-chart/customer-experience-chart.enum';

export class CustomerExperienceSLATargetDataSource extends ApiDataSource<any> {

    public isOpenedNewTab = false;

    public $charts = of([
        { key: ChartType.RESOLUTION_SLA, value: this.translate.instant('CX.ResolutionSLA') },
        { key: ChartType.RESOLUTION_SLA_OVERALL, value: this.translate.instant('CX.ResolutionSLAOverall') },
        { key: ChartType.FIRST_RESPONSE_SLA, value: this.translate.instant('CX.FirstResponseSLA') },
        { key: ChartType.FIRST_RESPONSE_SLA_OVERALL, value: this.translate.instant('CX.FirstResponseSLAOverall') }
    ].sort((a, b) => a.value.localeCompare(b.value)));

    public $status = of([
        { key: true, value: this.translate.instant('GENERAL.ACTIVE') },
        { key: false, value: this.translate.instant('GENERAL.PASSIVE') }
    ]);

    public $users = this.slaTargetService.listCreatedUsers({ filter: {} }).pipe(
        map(response => response.data),
        map(users => users.map(item => {
            return {
                key: item.userId,
                value: item.displayName
            };
        }))
    );

    private filterReq: any;

    constructor(
        private slaTargetService: CustomerExperienceSLATargetService,
        private translate: TranslateService,
        protected initialFilter?: any,
    ) {
        super(initialFilter);
    }

    getFilter() {
        return this.filterReq;
    }

    load(): void {
        if (!this.isOpenedNewTab) {
            // Init filter with data source's default filter
            const filterValue: any = { ...this.initialFilter, ...this.filter };

            // If filter keyword exists, filter data
            if (this.keyword) {
                filterValue.searchText = this.keyword;
            }

            // Update loading state
            this.loadingSubject.next(true);

            // Create request parameters
            const request = this.getRequest();
            if (!request.orderType) {
                request.orderType = 'ASC';
                request.orderBy = 'createdOn';
            }

            if (this.paginator?.pageSize) {
                request.pageSize = this.paginator.pageSize;
            }

            // Add filters to request
            request.filter = filterValue;

            this.filterReq = { ...request };

            // Fetch data
            this.slaTargetService
                .search(request)
                .pipe(
                    debounceTime(400),
                    finalize(() => this.loadingSubject.next(false))
                ).subscribe(response => {
                    // Update count and data subjects
                    this.dataSubject.next(response.data.results);
                    this.dataCountSubject.next(response.data.rowCount);

                    // Update data source's empty based row count
                    this.empty = response.data.rowCount === 0;
                });

        }

        return this.loadingSubject.next(false);
    }

}
