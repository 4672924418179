import { DestroyRef, inject, Injectable } from '@angular/core';
import { SystemUserService, User } from '@core/api';
import { GetRecentPageSuccess, getAccount, getUser } from '@core/store';
import { Store } from '@ngrx/store';
import { RecentPage } from './recent-pages.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class RecentPageService {

  recentPages: RecentPage[] = [];
  private user: User = null;

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private systemUserService: SystemUserService,
    private store: Store,
  ) {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user && this.user.recentPage) {
      this.recentPages = JSON.parse(this.user.recentPage);
    } else {
      this.recentPages = [];
    }
  }

  async setRecentPages(addedPage: RecentPage) {

    if (this.recentPages.length === 0) {
      this.recentPages = [];
    }

    const index = this.recentPages.findIndex(recent => recent.url === addedPage.url);

    // recent pages kısmında card ismi yerine board ismi göründüğü için ikinci koşul eklendi
    if (index === 0 && !this.recentPages[index].url.includes('/board/')) {
      return;
    }

    if (index !== -1) {
      this.recentPages = this.recentPages.filter(recentPage => recentPage.url !== addedPage.url);
    }

    this.recentPages = [addedPage, ...this.recentPages];

    this.recentPages = this.recentPages.length > 10 ? this.recentPages.splice(0, 10) : this.recentPages;
    this.systemUserService.updateRecentPageConfigs(
      this.user?.userId,
      JSON.stringify(this.recentPages)
    ).subscribe(() => {
      this.store.dispatch(new GetRecentPageSuccess(this.recentPages));
    });
  }

  addItem(addedPage: RecentPage) {
    this.setRecentPages(addedPage);
  }

}
