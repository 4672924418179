<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
  <h2 class="m-0" fxFlex="auto" fxLayout="column">
    <span>{{customer.name}}</span>
    <span class="text-sm">{{'GENERAL.FINANCIAL_INQUIRY_REQUEST' | translate}}</span>
  </h2>

  <button type="button" class="text-secondary" mat-dialog-close mat-icon-button>
    <net-iconify-icon [icon]="icClose"></net-iconify-icon>
  </button>
</div>

<mat-divider class="-mx-6 mb-4 text-border"></mat-divider>
<mat-dialog-content>
  <ng-container *ngIf="!dialogLoading;">
    <span *ngIf="!showStep()"
      class="block pb-4 text-sm">{{'GENERAL.PLEASE_ENTER_THE_TITLE_INFORMATION_CORRECTLY_AND_COMPLETELY' |
      translate}}</span>

    <form fxLayout="column" [formGroup]="form">

      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px">
        <mat-form-field fxFlex="50">
          <mat-label>{{'GENERAL.TAX_OFFICE' | translate}}</mat-label>
          <net-select-tax-office formControlName="taxOffice"></net-select-tax-office>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{'GENERAL.TAX_NUMBER' | translate}}</mat-label>
          <net-input-number formControlName="taxNo" [removeMask]="true" [allowDecimal]="false" [pattern]="taxNoPattern"
            [isTextInput]="true" [maxLength]="11"></net-input-number>
          <mat-error *ngIf="form.controls.taxNo.errors?.pattern">
            {{'GENERAL.THE_TAX_NUMBER_MUST_CONSIST_OF_AT_LEAST_10_AND_AT_MOST_11_CHARACTERS' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field>
        <mat-label>{{'GENERAL.TITLE' | translate}}</mat-label>
        <input type="text" formControlName="title" matInput>
      </mat-form-field>

      <mat-divider class="-mx-6 mb-4 text-border"></mat-divider>
      <ng-container *ngIf="showStep()">
        <div fxLayout="grid" gdColumns="1fr" gdColumns.gt-sm="1fr 2fr" gdGap.gt-sm="16px">
          <mat-form-field>
            <mat-label>{{'GENERAL.COMPANY_ESTABLISHMENT_DATE' | translate}}</mat-label>
            <mat-select formControlName="companyCreateDate">
              <mat-option [value]="year" *ngFor="let year of years">{{year}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'GENERAL.STATUS' | translate}}</mat-label>
            <input type="text" formControlName="status" matInput>
          </mat-form-field>
        </div>

        <div fxLayout="grid" gdColumns="1fr" gdColumns.gt-sm="1fr 1fr 1fr" gdGap.gt-sm="16px">
          <mat-form-field>
            <mat-label>{{'GENERAL.CAPITAL' | translate}}</mat-label>
            <net-input-money formControlName="fund" [currencyCode]="defaultCurrency?.isoCurrencyCode"
              [allowDecimal]="false">
            </net-input-money>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'GENERAL.VALIDITY_DATE' | translate}}</mat-label>
            <input matInput formControlName="validityDate" placeholder="{{'GENERAL.VALIDITY_DATE' | translate}}"
              readonly [matDatepicker]="datePicker" (click)="datePicker.open()">
            <mat-datepicker #datePicker></mat-datepicker>
            <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'GENERAL.RECOMMENDED_MATURITY_DAYS' | translate}}</mat-label>
            <net-input-number formControlName="expiry"></net-input-number>
          </mat-form-field>
        </div>

        <mat-form-field>
          <mat-label>{{'GENERAL.NOTE' | translate}}</mat-label>
          <textarea formControlName="note" matInput cdkTextareaAutosize cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="5"></textarea>
        </mat-form-field>
      </ng-container>

    </form>
  </ng-container>
  <ng-container *ngIf="dialogLoading;">
    <div fxLayout="column">
      <div fxLayout="row" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px">
        <ngx-skeleton-loader fxFlex animation="pulse" appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex animation="pulse" appearance="line"></ngx-skeleton-loader>
      </div>
      <ngx-skeleton-loader fxFlex animation="pulse" count="1" appearance="line"></ngx-skeleton-loader>
      <div fxLayout="row" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px">
        <ngx-skeleton-loader fxFlex animation="pulse" appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex animation="pulse" appearance="line"></ngx-skeleton-loader>
      </div>
      <div fxLayout="row" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px">
        <ngx-skeleton-loader fxFlex animation="pulse" appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex animation="pulse" appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex animation="pulse" appearance="line"></ngx-skeleton-loader>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!dialogLoading;">
  <net-progress-button *ngIf="financialInquiry && !isFinanceUser &&
      (
        financialInquiry.financialInquiryStatusId === status.COMPLETE
        || financialInquiry.financialInquiryStatusId === status.UPDATED
      )" [color]="'warn'" [disabled]="!isSalesman" [icon]="icQueryBuilder" [active]="loading" (btnClick)="resetForm()"
    text="{{'GENERAL.REQUEST_AGAIN' | translate}}"></net-progress-button>

  <net-progress-button
    *ngIf="financialInquiry && financialInquiry.financialInquiryStatusId === status.RETURN && !isFinanceUser && user.userId === financialInquiry.createdBy"
    [color]="'warn'" (btnClick)="cancelFinancialInquiry()"
    text="{{'GENERAL.CANCEL_REQUEST' | translate}}"></net-progress-button>

  <net-progress-button
    *ngIf="financialInquiry && financialInquiry.financialInquiryStatusId === status.RETURN && isFinanceUser"
    [color]="'warn'" (btnClick)="openRejectFinancialInquiryDialog()"
    text="{{'GENERAL.REJECT' | translate}}"></net-progress-button>

  <net-progress-button class="ml-auto" [icon]="icSave" [active]="loading || dialogLoading"
    [text]="(financialInquiry ? 'GENERAL.UPDATE_FINANCIAL_INQUIRY' : 'GENERAL.FINANCIAL_INQUIRY_REQUEST') | translate"
    (btnClick)="onSubmit()"
    [disabled]="(financialInquiry && isSalesman && !isFinanceUser) || (!financialInquiry && isFinanceUser) || (!isSalesman && !isFinanceUser)"></net-progress-button>
</mat-dialog-actions>

<ng-template #rejectFinancialInquiryDialog>
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <span class="m-0" fxFlex="auto">{{'GENERAL.REJECT_FINANCIAL_INQUIRY_REQUEST' | translate}}</span>

    <button type="button" class="text-secondary" (click)="close()" mat-icon-button>
      <net-iconify-icon [icon]="icClose"></net-iconify-icon>
    </button>
  </div>
  <mat-divider class="-mx-6 mb-4 text-border"></mat-divider>

  <mat-dialog-content class="action-form pb-4" fxLayout="column">
    <p class="mb-3">{{'GENERAL.PLEASE_ENTER_YOUR_REASON_FOR_REJECTION' | translate}}</p>
    <mat-form-field>
      <mat-label>{{'GENERAL.REASON_FOR_REJECTION' | translate}}</mat-label>
      <textarea matInput [formControl]="rejectExplationControl"></textarea>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <net-progress-button [color]="'warn'" class="ml-auto" [active]="dialogLoading"
      (btnClick)="onSubmitRejectFinancialInquiry()" [text]="'GENERAL.REJECT' | translate">
    </net-progress-button>
  </mat-dialog-actions>
</ng-template>