<ng-container [formGroup]="form">
  <input (click)="datePicker.open()" [disabled]="isDisabled" [matDatepicker]="datePicker" [readOnly]="isDisabled"
    class="cursor-pointer flex-[1_1_100%] max-w-[65%]" formControlName="date" matInput readonly>
  <button (click)="datePicker.open()" [disabled]="isDisabled" class="mx-4 flex items-center justify-center "
    mat-icon-button type="button">
    <net-iconify-icon [icon]="icToday" size="13.6"></net-iconify-icon>
  </button>
  <net-input-time [isDisabled]="isDisabled" [start]="0" [end]="23"
    class="cursor-pointer flex-[1_1_100%] max-w-[25%] mr-[5px]" formControlName="time"
    [defaultValue]="form.get('date').value && !form.get('time').value ? '00:00' : null"></net-input-time>
  <button (click)="onClearValue()" *ngIf="form.get('date').value || form.get('time').value" [disabled]="isDisabled"
    class="button-clear-trigger  flex items-center justify-center" mat-icon-button type="button">
    <net-iconify-icon [icon]="icClear" size="13.6"></net-iconify-icon>
  </button>
</ng-container>
<mat-datepicker #datePicker></mat-datepicker>