import { Component, inject, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'net-iconify-icon',
  template: `
  @if (svg) {
    <span [innerHTML]="svg" [class]="customClass +' flex items-center justify-center text-icon'" [style.width]="size + 'px'" [style.height]="size + 'px'"></span>
  } @else {
    <span [class]="icon + ' ' + customClass +' flex items-center justify-center text-icon'" [style.width]="size + 'px'" [style.height]="size + 'px'" [style.color]="color"
    [style.background-color]="backgroundColor"></span>
  }
  `,
})
export class IconifyIconComponent {

  @Input({ required: false }) set icon(value: string) {
    this._icon = `iconify ${value}`;
  }

  get icon(): string {
    return this._icon;
  }

  private _icon = '';

  @Input() set customClass(value: string) {
    this._customClass = value;
  }

  get customClass(): string {
    return this._customClass;
  }

  private _customClass = '';
  private _svg: SafeHtml = '';

  @Input() size = '24';

  @Input() width = `${this.size}px`;

  @Input() height = `${this.size}px`;

  @Input() set color(value) {
    if (value === 'primary') {
      this._color = '#5570ff';

      return;
    }

    this._color = value;
  }

  get color() {
    return this._color;
  }

  private _color = 'currentColor';

  @Input() backgroundColor = 'currentColor';

  @Input() set svg(value: string) {
    if (value) {
      this._svg = this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }
  get svg(): SafeHtml {
    return this._svg;
  }

  private sanitizer = inject(DomSanitizer);

}
