import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ApiService, Customer, Phone, PhoneInsertRequest } from '@core/api';
import { ReplaySubject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedModule } from '@shared/modules';
import { Icon } from '@shared/enums';
import { InputCustomerPhonesComponent } from '../input-customer-phones/input-customer-phones.component';
import { AppValidators } from 'src/app/app.validators';

@Component({
  selector: 'net-phone-insert-dialog',
  templateUrl: './phone-insert-dialog.component.html',
  styleUrls: ['./phone-insert-dialog.component.scss'],
  imports: [
    SharedModule,
    InputCustomerPhonesComponent
  ],
  standalone: true
})
export class PhoneInsertDialogComponent {

  icClose = Icon.IC_TWOTONE_CLOSE;
  icSave = Icon.IC_SAVE;

  loading = false;

  submit$: ReplaySubject<string> = new ReplaySubject<string>();

  customer: Customer;

  phoneForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: Customer,
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private phoneInsertDialogRef: MatDialogRef<PhoneInsertDialogComponent>,
  ) {
    this.customer = this.data;

    this.phoneForm = this.formBuilder.group({
      phone: [[], AppValidators.phonesWithNullCheck]
    });
  }

  onSubmit() {
    this.phoneForm.markAllAsTouched();

    if (this.phoneForm.valid) {
      const phone = this.phoneForm.get('phone').value[0] as Phone;

      const request: PhoneInsertRequest = {
        customerId: this.customer.customerId,
        phoneNumber: phone.phoneNumber,
        phoneTypeId: phone.phoneTypeId,
        countryId: phone.country.countryId,
      };

      this.apiService.phone.insert(request).subscribe(response => {
        if (response.success) {
          this.phoneInsertDialogRef.close(response.data);
        }
      });
    }
  }
}
