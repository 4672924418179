import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, ElementRef, Inject, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { STEP_STATE, StepperSelectionEvent } from '@angular/cdk/stepper';
import { debounceTime, filter, skip, switchMap, take } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import {
  AccountInsertRequest,
  AccountService,
  AccountTypeString,
  CustomerCheckNameRequest,
  CustomerService,
  ErrorCode,
  SalesOrganization,
  SalesRouteDefinition,
  SalesRouteDefinitionFilterRequest,
  SalesRouteDefinitionService,
  SalesRouteDefinitionStatusType,
  User
} from '@core/api';
import { getAccountTypeBySalesOrganization, getUser, hasUserPermission } from '@core/store';
import { Permission } from '@core/auth/auth.enum';

import { CreateLeadlessCustomerWizardDialogModel } from './create-leadless-customer-wizard-dialog.model';
import { Icon } from '@shared/enums';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SharedModule } from '@shared/modules';
import { SelectSalesOrganizationComponent } from '@shared/components/select-sales-organization/select-sales-organization.component';
import { isEqual } from 'lodash';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'net-create-leadless-customer-wizard-dialog',
  templateUrl: './create-leadless-customer-wizard-dialog.component.html',
  styleUrls: ['./create-leadless-customer-wizard-dialog.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    SelectSalesOrganizationComponent
  ]
})
export class CreateLeadlessCustomerWizardDialogComponent implements OnInit, AfterViewInit {

  icClose = Icon.IC_TWOTONE_CLOSE;

  user: User;
  activeRoutes: SalesRouteDefinition[] = [];
  filteredActiveRoutes: SalesRouteDefinition[] = [];

  // Steps
  salesOrganizationsStep: boolean;
  salesRoutesStep: boolean;
  customerInfosStep: boolean;
  accountTypeSelectionStep: boolean;

  customerInfosGroup = this.formBuilder.group({
    name: [null as string, Validators.required],
    customerShortName: [null as string, Validators.required],
    phones: [null]
  });
  salesOrganizationsGroup = this.formBuilder.group({
    salesOrganization: [null as SalesOrganization, Validators.required]
  });
  salesRouteDefinitionsGroup = this.formBuilder.group({
    salesRouteDefinitionId: [null as string, Validators.required]
  });
  accountTypeSelectionGroup = this.formBuilder.group({
    accountType: [AccountTypeString.BUSINESS, Validators.required],
    acceptPDPL: [false]
  });

  // Search Text for Active Routes
  searchText = '';
  customerNameParam = '';

  previousStepCount = 0;
  isAtStart: boolean;
  isAtEnd: boolean;

  existingIds: string[] = [];

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('searchInput') searchInputRef!: ElementRef<HTMLInputElement>;

  accountTypeSelection = false;
  onlyShowConsumer = false;
  accountTypeOptions = AccountTypeString;
  externalSalesOrganizations: SalesOrganization[] = [];
  hasCustomerId = false;

  initialFormValue: any;
  isInitialized = false;
  private formChanged = false;
  translations: { [key: string]: string };

  private readonly destroyRef = inject(DestroyRef);
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: CreateLeadlessCustomerWizardDialogModel,
    private accountService: AccountService,
    private customerService: CustomerService,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<CreateLeadlessCustomerWizardDialogComponent>,
    private formBuilder: FormBuilder,
    private salesRouteDefinitionService: SalesRouteDefinitionService,
    private store: Store,
    private translate: TranslateService
  ) {
    this.store.select(getUser).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => this.user = user);
    this.externalSalesOrganizations = this.data.externalSalesOrganizations;

    translate.get(['GENERAL']).subscribe(translations => {
      this.translations = {
        ...translations.GENERAL
      };
    });

    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseDialog();
    });
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.confirmCloseDialog();
      }
    });
  }

  ngOnInit(): void {
    this.onSalesOrganizationChange();
    this.onAccountTypeChange();

    const { wizardStepsShown, salesOrganization, salesRouteDefinitionId, customer } = this.data;

    this.salesOrganizationsStep = wizardStepsShown.salesOrganizationsStep;
    this.salesRoutesStep = wizardStepsShown.salesRoutesStep;
    this.customerInfosStep = wizardStepsShown.customerInfosStep;

    this.customerNameParam = customer.name;

    if ('customerId' in customer) {
      this.hasCustomerId = true;
      this.existingIds = customer.accounts.map(account => account.salesOrganization.salesOrganizationId);
      this.accountTypeSelectionGroup.get('accountType').setValue(customer.isConsumer ? AccountTypeString.CONSUMER : AccountTypeString.BUSINESS);
      this.accountTypeSelectionGroup.get('acceptPDPL').setValue(true);
    } else {
      this.customerInfosGroup.patchValue(customer);
    }

    if (this.salesOrganizationsStep) {
      this.salesOrganizationsGroup.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), skip(1)).subscribe(formValues => {
        if (this.salesRouteDefinitionsGroup.touched) {
          this.salesRouteDefinitionsGroup.patchValue({ salesRouteDefinitionId: null });
        }

        this.getSalesRoutes(formValues.salesOrganization);
      });
    } else if (!this.salesOrganizationsStep && this.salesRoutesStep) {
      this.salesOrganizationsGroup.patchValue({ salesOrganization });

      this.getSalesRoutes(salesOrganization);
    } else {
      this.salesOrganizationsGroup.patchValue({ salesOrganization });
      this.salesRouteDefinitionsGroup.patchValue({ salesRouteDefinitionId });
    }

  }

  ngAfterViewInit(): void {
    this.stepper._getIndicatorType = () => STEP_STATE.NUMBER;
    this.previousStepCount = this.stepper.steps.length;
    this.detectStartEndSteps();
    this.cdr.detectChanges();

    this.stepper.steps.forEach((step, i, thisArray) => {
      const element = document.querySelectorAll<HTMLElement>('.mat-step-icon')[i];

      if (element) {
        if (i <= this.stepper.selectedIndex) {
          element.style.backgroundColor = this.getStepColor(i);
        } else {
          element.style.backgroundColor = '#e0e7f1';
          element.style.color = '#bdc5ce';
        }
      }

      if (i <= this.stepper.selectedIndex && i < thisArray.length) {
        const stepLine = document.querySelectorAll<HTMLElement>('.mat-stepper-horizontal-line')[i];
        if (stepLine) {
          stepLine.style.borderTopColor = this.getStepColor(i);
        }
      }
    });

    if (!this.isInitialized) {
      this.initialFormValue = this.salesOrganizationsGroup.getRawValue();
      this.isInitialized = true;
      this.formChangeDetection();
    }
  }

  private formChangeDetection() {
    this.salesOrganizationsGroup.valueChanges.pipe(
      debounceTime(400),
      takeUntilDestroyed(this.destroyRef),
      skip(1)
    ).subscribe(() => {
      const currentValue = this.salesOrganizationsGroup.getRawValue();
      const hasChanges = !isEqual(this.initialFormValue, currentValue);

      if (this.formChanged !== hasChanges) {
        this.formChanged = hasChanges;
      }
    });
  }

  closeCustomerFormDialog() {
    this.confirmCloseDialog();
  }

  confirmCloseDialog() {
    if (this.formChanged) {
      Swal.fire({
        text: this.translations?.PAGE_LEAVE_WITHOUT_SAVING,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F44336',
        cancelButtonColor: '#7a7a7a',
        confirmButtonText: this.translations?.YES,
        cancelButtonText: this.translations?.NO
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  onSalesOrganizationChange() {
    this.salesOrganizationsGroup.get('salesOrganization').valueChanges.pipe(
      filter(salesOrganization => !!salesOrganization),
      switchMap(salesOrganization => {
        const { salesOrganizationId } = salesOrganization;
        return this.store.select(getAccountTypeBySalesOrganization(salesOrganizationId)).pipe(take(1));
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(value => {
      this.accountTypeSelection = value.business && value.consumer;
      this.onlyShowConsumer = value.consumer && !value.business;
      if (!this.hasCustomerId) {
        const accountType = this.accountTypeSelection
          ? AccountTypeString.BUSINESS
          : (value.consumer ? AccountTypeString.CONSUMER : AccountTypeString.BUSINESS);
        this.accountTypeSelectionGroup.get('accountType').setValue(accountType);
      }
      if (this.accountTypeSelection || this.onlyShowConsumer) {
        setTimeout(() => {
          const secondLastStepIndex = this.stepper.steps.length - 2;
          this.updateStepColor(secondLastStepIndex, { backgroundColor: '#e0e7f1', color: '#bdc5ce' });
          const stepLine = document.querySelectorAll<HTMLElement>('.mat-stepper-horizontal-line')[secondLastStepIndex - 1];
          if (stepLine) {
            stepLine.style.borderTopColor = '#e0e7f1';
          }
        }, 0);
      }
    });
  }

  private onAccountTypeChange() {
    this.accountTypeSelectionGroup.get('accountType').valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (value === AccountTypeString.CONSUMER) {
        this.accountTypeSelectionGroup.get('acceptPDPL').setValidators([Validators.requiredTrue]);
      } else {
        this.accountTypeSelectionGroup.get('acceptPDPL').removeValidators([Validators.requiredTrue]);
      }
      this.accountTypeSelectionGroup.get('acceptPDPL').updateValueAndValidity();
    });
  }

  createCustomer() {
    const salesOrganizationId = this.salesOrganizationsGroup.value.salesOrganization.salesOrganizationId;
    const salesRouteDefinitionId = this.salesRouteDefinitionsGroup.value.salesRouteDefinitionId;
    const customer = this.data.customer;
    const accountType = this.accountTypeSelectionGroup.get('accountType').value;
    const acceptPDPL = this.accountTypeSelectionGroup.get('acceptPDPL').value;

    const request: AccountInsertRequest = {
      salesOrganizationId,
      salesRouteDefinitionId,
      pdplFlag: acceptPDPL,
      accountType
    };

    if ('customerId' in customer) {
      request.customerId = customer.customerId;
      request.customer = {
        name: customer.name,
        customerShortName: customer.name.substring(0, 30),
        phones: customer.phones,
        emails: customer.emails
      };
    } else {
      const customerInfos = this.customerInfosGroup.value;

      request.customer = {
        name: customerInfos.name,
        customerShortName: customerInfos.customerShortName,
        phones: customerInfos.phones,
        emails: customer.emails
      };
    }

    this.accountService.insert(request).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(response => {
      if (response.success) {
        this.dialogRef.close(response.data);
      } else if ([ErrorCode.DUPLICATE_CUSTOMER_NAME, ErrorCode.DUPLICATE_CUSTOMER_SHORT_NAME].includes(response.errorCode as ErrorCode)) {
        this.customerInfosStep = true;

        this.cdr.detectChanges();

        const _customerInfosStep = this.findStepById('customer-infos-step');
        _customerInfosStep.stepFound.select();
        _customerInfosStep.stepFound.completed = false;

        this.customerInfosGroup.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(formValues => {
          _customerInfosStep.stepFound.completed = false;
          this.customerNameParam = formValues.name;
        });
        this.updateStepColor(_customerInfosStep.stepIndex, { backgroundColor: this.getStepColor(_customerInfosStep.stepIndex), color: '#fff' });
      }
    });
  }

  controlCustomerName() {
    if (this.customerInfosGroup.valid) {
      const { name, customerShortName } = this.customerInfosGroup.value;
      const customerInfosStep = this.findStepById('customer-infos-step');

      const request: CustomerCheckNameRequest = {
        name,
        customerShortName
      };

      this.customerService.checkName(request).subscribe(response => {
        if (response.success) {
          customerInfosStep.stepFound.completed = true;

          this.stepper.next();
        } else {
          customerInfosStep.stepFound.completed = false;
        }
      });
    }
  }

  onStepChange(event: StepperSelectionEvent) {
    this.updateStepColors(event);
    this.detectStartEndSteps(event);
  }

  // Active Routes Step Search Functions
  onKey() {
    const lowercaseSearchText = this.searchText.toLocaleLowerCase('tr-TR');

    this.filteredActiveRoutes = this.activeRoutes.filter(option => {
      const nameMatches = option.name.toLocaleLowerCase('tr-TR').includes(lowercaseSearchText);
      const displayNameMatches = option.assignedUser.displayName.toLocaleLowerCase('tr-TR').includes(lowercaseSearchText);
      return nameMatches || displayNameMatches;
    });
  }

  checkKey(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.stopPropagation();
    }
  }

  openedChange(isOpened: boolean) {
    if (isOpened) {
      this.searchInputRef.nativeElement.focus();
    }

    this.searchText = '';
    this.onKey();
  }
  // Active Routes Step Search Functions

  private getSalesRoutes(salesOrganization: SalesOrganization) {
    this.store.select(hasUserPermission(Permission.ACCOUNT_FILTER_SALES_ORGANIZATION)).subscribe(hasAccountFilterSOPermission => {
      const { salesOrganizationId } = salesOrganization;
      const hierarchyFound = this.user.userStructures?.find(item => item.salesOrganizationId === salesOrganizationId);

      if (hasAccountFilterSOPermission || hierarchyFound) {
        const request: SalesRouteDefinitionFilterRequest = {
          filter: {
            salesOrganizationId,
            systemUserStructureId: hierarchyFound?.systemUserStructureId
          }
        };

        this.salesRouteDefinitionService.search(request).subscribe(response => {
          const salesRoutes = response.data.results;
          this.activeRoutes = salesRoutes.filter(route => route.salesRouteDefinitionStatusId === SalesRouteDefinitionStatusType.ACTIVE);
          this.filteredActiveRoutes = this.activeRoutes;
        });
      } else {
        if (this.filteredActiveRoutes.length > 0) {
          this.filteredActiveRoutes = [];
        }
      }
    });
  }

  private getStepColor(index: number) {
    const stepColors = ['#68b9c8', '#564c98', '#4474b5', '#30529b'];

    return stepColors[index % stepColors.length];
  }

  private updateStepColors(event: StepperSelectionEvent) {
    const stepIconStyle = {
      backgroundColor: null,
      color: null
    };

    if (event.selectedIndex > event.previouslySelectedIndex) {
      stepIconStyle.color = '#fff';

      for (let i = event.previouslySelectedIndex + 1; i <= event.selectedIndex; i++) {
        stepIconStyle.backgroundColor = this.getStepColor(i);

        this.updateStepColor(i, stepIconStyle);
      }
    } else {
      stepIconStyle.backgroundColor = '#e0e7f1';
      stepIconStyle.color = '#bdc5ce';

      const currentStepCount = this.stepper.steps.length;
      const startingIndex = event.previouslySelectedIndex + (currentStepCount > this.previousStepCount ? currentStepCount - this.previousStepCount : 0);
      this.previousStepCount = currentStepCount;

      for (let i = startingIndex; i > event.selectedIndex; i--) {
        this.updateStepColor(i, stepIconStyle);
      }
    }
  }

  private updateStepColor(i: number, style: { backgroundColor: string; color: string; }) {
    const { backgroundColor, color } = style;

    const stepIcon = document.querySelectorAll<HTMLElement>('.mat-step-icon')[i];
    if (stepIcon) {
      stepIcon.style.backgroundColor = backgroundColor;
      stepIcon.style.color = color;
    }

    if (i < (this.stepper.steps.length - 1)) {
      const stepLine = document.querySelectorAll<HTMLElement>('.mat-stepper-horizontal-line')[i];
      if (stepLine) {
        stepLine.style.borderTopColor = backgroundColor;
      }
    }
  }

  private detectStartEndSteps(event?: StepperSelectionEvent) {
    this.isAtStart = (event?.selectedIndex ?? this.stepper.selectedIndex) === 0;
    this.isAtEnd = (event?.selectedIndex ?? this.stepper.selectedIndex) === this.stepper.steps.length - 1;
  }

  private findStepById(id: string) {
    let stepIndex: number;
    const stepFound = this.stepper.steps.find((step, index) => {
      const stepId = (step.content.elementRef.nativeElement as HTMLElement).parentElement.id;
      stepIndex = index;
      return stepId === id;
    });

    return { stepFound, stepIndex };
  }
}
