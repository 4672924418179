import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BaseResponse,
  BaseStatusModel,
  CustomerExperienceCategory,
  CustomerExperienceCategoryRequest,
  CustomerExperienceCategoryStatus,
  CustomerExperienceCategoryStatusFilter,
  CustomerExperienceStatusRequest,
  CustomerExperienceStatusSearchRequest,
  FilterRequest,
  ListCreatedUser,
  PagedResponse,
  Response
} from '@core/api';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerExperienceCategoriesService {
  constructor(private http: HttpClient) {
  }

  get(request: string) {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<CustomerExperienceCategory>>(environment.apiUrl + '/api/CxCategory/Get', JSON.stringify(request), { headers });
  }

  insert(request: CustomerExperienceCategoryRequest) {
    return this.http.post<Response<CustomerExperienceCategory>>(environment.apiUrl + '/api/CxCategory/Insert', request);
  }

  list() {
    return this.http.post<Response<CustomerExperienceCategory[]>>(environment.apiUrl + '/api/CxCategory/List', {});
  }

  search(request: any) {
    return this.http.post<PagedResponse<CustomerExperienceCategory>>(environment.apiUrl + '/api/CxCategory/Search', request);
  }

  setOrderBy(request: CustomerExperienceCategoryRequest) {
    return this.http.post<Response<CustomerExperienceCategory[]>>(environment.apiUrl + '/api/CxCategory/SetOrderBy', request);
  }

  setStatus(request: { cxCategoryId: string, activeFlag: boolean }) {
    return this.http.post<Response<CustomerExperienceCategory>>(environment.apiUrl + '/api/CxCategory/SetStatus', request);
  }

  update(request: CustomerExperienceCategoryRequest) {
    return this.http.post<Response<CustomerExperienceCategory>>(environment.apiUrl + '/api/CxCategory/Update', request);
  }

  delete(request: string) {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<BaseResponse>(environment.apiUrl + '/api/CxCategory/Delete', JSON.stringify(request), { headers });
  }

  getDefinedStatuses() {
    return this.http.post<Response<BaseStatusModel[]>>(environment.apiUrl + '/api/CxStatus/BaseStatusList', {});
  }

  statusSearch(request: CustomerExperienceStatusSearchRequest) {
    return this.http.post<PagedResponse<CustomerExperienceCategoryStatus>>(environment.apiUrl + '/api/CxStatus/Search', request);
  }

  statusInsert(request: CustomerExperienceStatusRequest) {
    return this.http.post<PagedResponse<CustomerExperienceCategoryStatus>>(environment.apiUrl + '/api/CxStatus/Insert', request);
  }

  statusUpdate(request: CustomerExperienceStatusRequest) {
    return this.http.post<PagedResponse<CustomerExperienceCategoryStatus>>(environment.apiUrl + '/api/CxStatus/Update', request);
  }

  categoryStatusSearch(request: CustomerExperienceStatusSearchRequest) {
    return this.http.post<PagedResponse<CustomerExperienceCategoryStatus>>(environment.apiUrl + '/api/CxCategoryStatus/Search', request);
  }

  categoryStatusFilterList(isSubStatus = false) {
    // tslint:disable-next-line:max-line-length
    return this.http.post<Response<CustomerExperienceCategoryStatusFilter[]>>(environment.apiUrl + `/api/CxCategoryStatus/FilterList?SubStatus=${isSubStatus}`, {});
  }

  listCreatedUsers(filter?: FilterRequest): Observable<Response<ListCreatedUser[]>> {
    return this.http.post<Response<ListCreatedUser[]>>(environment.apiUrl + '/api/CxCategory/ListCreatedUsers', filter);
  }

}
