import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

import { Component, DestroyRef, ElementRef, inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService, Contact, SearchResult } from '@core/api';
import { SearchItem } from './search.component.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Icon } from '@shared/enums';
import { LayoutService } from '@shared/services';
import { SharedModule } from '@shared/modules';
import { Store } from '@ngrx/store';
import { GetAccount } from '@core/store';

@Component({
  selector: 'net-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SearchComponent implements OnInit, OnDestroy {

  result: SearchResult;
  dialogAlertRef: MatDialogRef<any>;

  selectedContact;

  show$ = this.layoutService.searchOpen$;
  loading = false;
  searchCtrl = new UntypedFormControl();

  icClose = Icon.IC_TWOTONE_CLOSE;
  icMoreVert = Icon.IC_MORE_VERT;
  icKeyboardArrowRight = Icon.IC_KEYBOARD_ARROW_RIGHT;

  @ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement>;
  @ViewChild('trigger', { static: true }) trigger: MatAutocompleteTrigger;
  @ViewChild('accountAlert', { static: true }) accountAlert: TemplateRef<HTMLElement>;

  private readonly destroyRef = inject(DestroyRef);
  constructor(
    private router: Router,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private apiService: ApiService,
    private api: ApiService,
    private store: Store
  ) { }

  private quickActionRoute(item: SearchItem): any[] {
    let url;

    // Decide quick action routes
    switch (item.type) {
      case 'account':
        url = ['/leads', item.content.accountId];
        this.store.dispatch(new GetAccount(item.content.accountId));
        break;

      case 'contact':
        url = ['contacts', item.content.contactId];

        if (item.content.customer.accounts[0] && item.content.customer.accounts[0].accountId) {
          url = ['/leads', item.content.customer.accounts[0].accountId, 'contacts', item.content.contactId];
          this.store.dispatch(new GetAccount(item.content.customer.accounts[0].accountId));
        }
        break;

      default:
        throw new Error('Unknown search item type, please define url redirect schema');
    }

    return url;
  }

  ngOnInit() {
    // Bind open state to layout service
    this.show$.pipe(
      filter(show => show),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => this.input.nativeElement.focus());

    // Watch for keyword changes and fetch auto complete result
    this.searchCtrl.valueChanges.pipe(
      tap((value) => {
        if (!value) {
          this.result = null;
        }
      }),
      filter(value => !!value && typeof value === 'string'),
      map(value => value.toString().trim()),
      filter(value => value.length > 2),
      debounceTime(300),
      tap(() => {
        this.result = null;
        this.loading = true;
      }),
      switchMap(value => this.api.search({ text: value, pageSize: 5 })),
      tap(() => this.loading = false)
    ).subscribe(response => {
      if (this.searchCtrl.value) {
        this.result = response.data;
      }
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() { }

  displayName(item: SearchItem) {
    if (!item) {
      return '';
    }

    switch (item.type) {
      case 'contact':
        return item.content.firstName + ' ' + item.content.lastName;

      case 'account':
        return item.content.name;
    }
  }

  displayCommunicationInfo(item: any) {
    if (!item) {
      return '';
    }

    const communicationInfo = [];

    if (item.phone) {
      communicationInfo.push(item.phone);
    }

    if (item.email) {
      communicationInfo.push(item.email);
    }

    return communicationInfo.join(' - ');

  }

  close() {
    this.layoutService.closeSearch();

    this.loading = false;
    this.trigger.closePanel();
    this.input.nativeElement?.blur();
    this.searchCtrl.setValue(undefined);
  }

  onSelect(option: any) {
    if (option.value.type === 'contact') {
      this.getContactById(option.value.content.contactId);
    } else {

      const url = this.quickActionRoute(option.value);

      setTimeout(() => {
        this.router.navigate(url);
        this.close();
      }, 250);
    }
  }

  getContactById(contactId: string) {
    this.apiService.contact.get(contactId).pipe(
      map(response => response.data),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(contact => {
      this.selectedContact = contact;
      this.goTo(this.selectedContact);
    });
  }

  goTo(contact: Contact) {
    if (contact?.customer?.accounts?.length > 1) {
      this.dialogAlertRef = this.dialog.open(this.accountAlert, { autoFocus: false });
      this.dialogAlertRef
        .addPanelClass('confirm-dialog')
        .afterClosed()
        .subscribe(() => {
          this.result = null;
          this.close();
        });
    } else if (contact?.customer?.accounts?.length === 1) {
      this.close();

      this.store.dispatch(new GetAccount(contact?.customer?.accounts[0]?.accountId));
      setTimeout(() => {
        this.router.navigate(['/leads', contact?.customer?.accounts[0]?.accountId, 'contacts', contact.contactId]);
      }, 250);
    } else {
      this.close();
      this.router.navigate(['/contacts', contact?.customer?.leadDraft?.leadDraftId]);
    }
  }

  onSelectContactWithAccount(contactId: string, accountId: string) {
    this.dialogAlertRef.close();
    this.close();
    this.store.dispatch(new GetAccount(accountId));
    setTimeout(() => {
      this.router.navigate(['/leads', accountId, 'contacts', contactId]);
    }, 250);
  }

  onKeyupEnter() {
    if (this.searchCtrl.value?.type) {
      this.onSelect(this.searchCtrl);
    } else {
      this.router.navigate(['/search'], { queryParams: { keyword: this.searchCtrl.value } });
      this.close();
    }
  }

  getHashtag(list) {
    let tag = list[0].hashtag.tag;
    list.forEach(element => {
      if (element.hashtag.tag.includes(this.searchCtrl.value)) {
        tag = element.hashtag.tag;
      }
    });
    return tag;
  }
}
