import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, Subject } from 'rxjs';
import { FilterRequest, ListCreatedUser, PagedResponse, Response } from '../api.model';
import {
    CustomerExperienceChatModel,
    CustomerExperienceChatRequest,
    CustomerExperienceTicket,
    CustomerExperienceTicketDownload,
    CustomerExperienceTicketSearchRequest,
    SplitTicketCodesModel,
    TicketPriority
} from '@core/api';

@Injectable({
    providedIn: 'root'
})
export class CustomerExperienceTicketService {

    private splitTicketsEvent = new Subject<SplitTicketCodesModel>();

    emitSplitTicketsEvent(splitTicketCode: SplitTicketCodesModel) {
        this.splitTicketsEvent.next(splitTicketCode);
    }

    onSplitTickets() {
        return this.splitTicketsEvent.asObservable();
    }

    constructor(
        private http: HttpClient
    ) { }
    search(request: CustomerExperienceTicketSearchRequest): Observable<PagedResponse<CustomerExperienceTicket>> {
        return this.http.post<PagedResponse<CustomerExperienceTicket>>(environment.apiUrl + '/api/Ticket/Search', request);
    }

    exportExcel(request: CustomerExperienceTicketSearchRequest): Observable<Response<CustomerExperienceTicketDownload[]>> {
        return this.http.post<Response<CustomerExperienceTicketDownload[]>>(environment.apiUrl + '/api/Ticket/Download', request.filter);
    }

    listPriority() {
        return this.http.post<Response<TicketPriority[]>>(environment.apiUrl + '/api/Ticket/PriorityList', {});
    }

    insert(request: { ticketMessageId: string; cxChannelId: string; contactName: string; contactId?: string; contactInfo?: string; }) {
        return this.http.post<Response<CustomerExperienceTicket[]>>(environment.apiUrl + '/api/Ticket/Insert', request);
    }

    get(uuid: string) {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<CustomerExperienceTicket>>(environment.apiUrl + '/api/Ticket/Get', JSON.stringify(uuid), { headers });
    }

    getChat(request: CustomerExperienceChatRequest): Observable<PagedResponse<CustomerExperienceChatModel>> {
        return this.http.post<PagedResponse<CustomerExperienceChatModel>>(environment.apiUrl + '/api/Ticket/GetChat', request);
    }

    updateContact(request: { ticketId: string, contactId: string }) {
        return this.http.post<Response<CustomerExperienceTicket>>(environment.apiUrl + '/api/Ticket/TicketUpdateContact', request);
    }

    upload(ticketConversationId: string, cxChannelId: string, messageType: string, file: File) {
        const form = new FormData();
        form.append('file', file);

        return this.http.post<Response<any>>(
            `${environment.apiUrl}/api/TicketMessage/Upload?ticketConversationId=${ticketConversationId}&messageType=${messageType}&cxChannelId=${cxChannelId}`,
            form,
        );
    }

    listCreatedUsers(filter?: FilterRequest): Observable<Response<ListCreatedUser[]>> {
        return this.http.post<Response<ListCreatedUser[]>>(environment.apiUrl + '/api/Ticket/ListAssignedUsers', filter);
    }


}
