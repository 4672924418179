import { Board, BoardHashtag, BoardInfo, BoardSystemUser } from '@core/api';

export enum BoardDetailActionTypes {
  SET_BOARD_DETAIL_HASHTAG = '[Board Detail] SET_BOARD_DETAIL_HASHTAG',
  SET_BOARD_DETAIL_SYSTEM_USER = '[Board Detail] SET_BOARD_DETAIL_SYSTEM_USER',
  SET_BOARD_DETAIL_BOARD_INFO = '[Board Detail] SET_BOARD_DETAIL_BOARD_INFO',
  SET_BOARD_DETAIL = '[Board Detail] SET_BOARD_DETAIL',
  GET_BOARD_DETAIL_CREATED_USERS = '[Board Detail] GET_BOARD_DETAIL_CREATED_USERS',
  SET_BOARD_DETAIL_CREATED_USERS = '[Board Detail] SET_BOARD_DETAIL_CREATED_USERS',
  GET_BOARD_DETAIL_ASSIGNED_USERS = '[Board Detail] GET_BOARD_DETAIL_ASSIGNED_USERS',
  SET_BOARD_DETAIL_ASSIGNED_USERS = '[Board Detail] SET_BOARD_DETAIL_ASSIGNED_USERS'
}

export class SetBoardDetail {
  readonly type = BoardDetailActionTypes.SET_BOARD_DETAIL;
  constructor(public board: Board) { }
}

export class SetBoardDetailBoardInfo {
  readonly type = BoardDetailActionTypes.SET_BOARD_DETAIL_BOARD_INFO;
  constructor(public boardInfos: BoardInfo[]) { }
}

export class GetBoardDetailCreatedUsers {
  readonly type = BoardDetailActionTypes.GET_BOARD_DETAIL_CREATED_USERS;
  constructor(public boardId: string) { }
}

export class SetBoardDetailCreatedUsers {
  readonly type = BoardDetailActionTypes.SET_BOARD_DETAIL_CREATED_USERS;
  constructor(public createdUsers: BoardSystemUser[]) { }
}

export class GetBoardDetailAssignedUsers {
  readonly type = BoardDetailActionTypes.GET_BOARD_DETAIL_ASSIGNED_USERS;
  constructor(public boardId: string) { }
}

export class SetBoardDetailAssignedUsers {
  readonly type = BoardDetailActionTypes.SET_BOARD_DETAIL_ASSIGNED_USERS;
  constructor(public assignedUsers: BoardSystemUser[]) { }
}

export class SetBoardDetailSystemUser {
  readonly type = BoardDetailActionTypes.SET_BOARD_DETAIL_SYSTEM_USER;
  constructor(public boardSystemUsers: BoardSystemUser[]) { }
}

export class SetBoardDetailHashtag {
  readonly type = BoardDetailActionTypes.SET_BOARD_DETAIL_HASHTAG;
  constructor(public boardHashtags: BoardHashtag[]) { }
}

export type BoardDetailActions =
  SetBoardDetailHashtag |
  SetBoardDetailSystemUser |
  SetBoardDetailBoardInfo |
  GetBoardDetailCreatedUsers |
  SetBoardDetailCreatedUsers |
  GetBoardDetailAssignedUsers |
  SetBoardDetailAssignedUsers |
  SetBoardDetail;
