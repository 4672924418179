import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { Response } from '../api.model';
import { EntityModel } from './entity.model';

@Injectable({
    providedIn: 'root'
})
export class EntityService {

    private readonly http: HttpClient = inject(HttpClient);

    getAccountCommunicationEntities(): Observable<Response<EntityModel[]>> {
        return this.http.post<Response<EntityModel[]>>(environment.apiUrl + '/api/Entity/GetAccountCommunicationEntities', {});
    }
}
