import {
    Board, Card, BoardResponse, SocketSearchRequest, CardActionType, BoardGroupType, BoardInfo, CardStatusOption
} from '@core/api';
import moment from 'moment';
import { getDescription } from './card-detail.helper';
import { v4 as uuidv4 } from 'uuid';
import * as signalR from '@microsoft/signalr';
import {
    filterSectionCards, applyFilterForCard, applyFilter, checkDateIsWithinRange, checkCardComplete,
    checkHashtag, checkAssignedUserFilter, checkAttachment, checkCRMLinks, checkDescription
} from './card-filter.helper';
import { Guid } from '@core/api/api.enum';
import { SECTION_BOARDINFO_CARD_SIZE } from '@shared/utils';
import { GetBoardDetailAssignedUsers, GetBoardDetailCreatedUsers } from '@core/store';
import { Store } from '@ngrx/store';

export function setCardProperties(cardsResponse: BoardResponse<Card>[], board: Board, store: Store, request?: SocketSearchRequest, currentFilter?: SocketSearchRequest, type?: string, hubConnection?: signalR.HubConnection) {

    if (Array.isArray(cardsResponse)) {
        cardsResponse.map(cardResponse => {
            if (board.skipNextCardAction && type === BoardGroupType.Kanban) {
                return;
            }
            setCardPropertiesLogic(cardResponse, board, store, currentFilter, type, request, hubConnection);
        });
    } else {

        setCardPropertiesLogic(cardsResponse, board, store, currentFilter, type, request, hubConnection);
    }
    board.skipNextCardAction = false;
    return filterSectionCards(board, request);
}

export function setCardPropertiesLogic(cardResponse: BoardResponse<Card>, board: Board, store: Store, currentFilter?: SocketSearchRequest, type?: string, request?: SocketSearchRequest, hubConnection?: signalR.HubConnection) {
    switch (cardResponse.enumCardActionType) {
        case CardActionType.CardAdd:
        case CardActionType.CardCopy:
            add(cardResponse, board, store, type);
            break;
        case CardActionType.CardDelete:
            cardDelete(cardResponse, board, store);
            break;
        case CardActionType.CardNameUpdate:
            nameUpdate(cardResponse, board);
            break;
        case CardActionType.CardSectionChange:
        case CardActionType.CardBulkSectionChange:
            sectionChange(cardResponse, board);
            break;
        case CardActionType.CardSetOrder:
            setOrder(cardResponse, board, currentFilter, type, request, hubConnection);
            break;
        case CardActionType.ChildCardSetOrder:
            childCardSetOrder(cardResponse, board);
            break;
        case CardActionType.CardReminderDateUpdate:
            reminderDateUpdate(cardResponse, board, currentFilter);
            break;
        case CardActionType.CardSetComplete:
        case CardActionType.CardBulkSetComplete:
            setComplete(cardResponse, board, currentFilter);
            break;
        case CardActionType.CardParentCardConvertToSubcard:
            convertToSubcard(cardResponse, board);
            break;
        case CardActionType.CardSubcardConvertToParentCard:
            convertToParentCard(cardResponse, board);
            break;
        case CardActionType.CardAssigneeUserUpdate:
        case CardActionType.CardBulkAssigneeUserUpdate:
            assigneeUserUpdate(cardResponse, board, store, currentFilter);
            break;
        case CardActionType.CardAttachmentAdd:
            attachmentAdd(cardResponse, board, currentFilter);
            break;
        case CardActionType.CardAttachmentUpdate:
            attachmentUpdate(cardResponse, board);
            break;
        case CardActionType.CardAttachmentDelete:
            attachmentDelete(cardResponse, board, currentFilter);
            break;
        case CardActionType.CardAttachmentCount:
            attachmentCountUpdate(cardResponse, board);
            break;
        case CardActionType.CardCRMLinkUpdate:
            objectsUpdate(cardResponse, board, currentFilter);
            break;
        case CardActionType.CardCommentAdd:
            break;
        case CardActionType.CardCommentDelete:
            break;
        case CardActionType.CardCommentUpdate:
            break;
        case CardActionType.CardCommentCount:
            commentCountUpdate(cardResponse, board, type);
            break;
        case CardActionType.CardDueDateUpdate:
        case CardActionType.CardBulkDueDateUpdate:
            dueDateUpdate(cardResponse, board, currentFilter);
            break;
        case CardActionType.CardDynamicFieldUpdate:
            dynamicFieldUpdate(cardResponse, board);
            break;
        case CardActionType.CardDescriptionUpdate:
            descriptionUpdate(cardResponse, board, currentFilter);
            break;
        case CardActionType.CardHashtagUpdate:
        case CardActionType.CardBulkHashtagUpdate:
            hashtagUpdate(cardResponse, board, currentFilter);
            break;
        case CardActionType.CardHistoryAdd:
            break;
        case CardActionType.ChildCardAdd:
            childCardAdd(cardResponse, board);
            break;
        case CardActionType.ChildCardCountUpdate:
            childCardCountUpdate(cardResponse, board);
            break;
        case CardActionType.CardCommentSystemUserIsReaded:
            resetUserCommentContextCount(cardResponse, board);
            break;
        case CardActionType.CardGet:
            cardGet(cardResponse, board, currentFilter);
            break;
        case CardActionType.CardDependencyUpdate:
            cardDependencyUpdate(cardResponse, board);
            break;
        case CardActionType.GetChildCards:
            getChildCards(cardResponse, board);
            break;
    }

    return board;
}

export function dynamicFieldUpdate(cardResponse: BoardResponse<Card>, board: Board) {

    const section: BoardInfo = board.boardInfos.find(boardInfo => boardInfo.boardInfoId === cardResponse.boardInfoId);

    if (!section) {
        return;
    }
    section.boardInfoUuid = uuidv4();
    cardResponse.data.map(_card => {
        const card: Card = section.cards.find(c => c.cardId === _card.cardId);

        if (!card) {
            return;
        }

        card.dynamicFieldValues = _card.dynamicFieldValues;
    });

}

export function getChildCards(cardResponse: BoardResponse<Card>, board: Board) {

    const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === cardResponse.boardInfoId);

    cardResponse.data.map(_card => {

        if (!section) {
            return;
        }

        const cardIndex = section.cards.findIndex(c => c.cardId === _card.cardId);

        if (cardIndex !== -1) {
            section.cards[cardIndex].childCards = _card.childCards.sort((a, b) => a.orderBy - b.orderBy);
        }
    });
}

export function cardDependencyUpdate(cardResponse: BoardResponse<Card>, board: Board) {
    cardResponse.data.map(_card => {

        const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === _card.boardInfoId);

        if (!section) {
            return;
        }

        const cardIndex = section.cards.findIndex(c => c.cardId === _card.cardId);

        if (cardIndex !== -1) {
            section.cards[cardIndex].sourceCardIds = _card.sourceCardIds;
            section.cards[cardIndex].targetCardIds = _card.targetCardIds;
        }

    });
}

export function cardGet(cardResponse: BoardResponse<Card>, board: Board, currentFilter) {
    cardResponse.data.map(_card => {

        if (currentFilter) {
            if (!applyFilterForCard(_card, currentFilter)) {
                return;
            }
        }

        const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === _card.boardInfoId);

        if (!section || section.collapse) {
            return;
        }

        if (_card.parentCardId) {

            const mainCard: Card = section.cards.find(c => c.cardId === _card.parentCardId);
            const subCardIndex = mainCard.childCards.findIndex(c => c.cardId === _card.cardId);

            if (subCardIndex === -1) {
                mainCard.childCards.push(_card);
                mainCard.childCards.sort((a, b) => a.orderBy - b.orderBy);
            }
        }
        else {
            const cardIndex = section.cards.findIndex(c => c.cardId === _card.cardId);

            if (cardIndex === -1) {
                section.cards.push(_card);
                section.cards.sort((a, b) => a.orderBy - b.orderBy);
                section.boardInfoUuid = uuidv4();
                section.cardCount++;
                section.totalCardCount++;
                if (_card.cardStatusId === CardStatusOption.COMPLETED) {
                    section.completedCardCount++;
                }
            }
        }
    });
}

export function resetUserCommentContextCount(cardResponse: BoardResponse<Card>, board: Board) {
    const section: BoardInfo = board.boardInfos.find(boardInfo => boardInfo.boardInfoId === cardResponse.boardInfoId);

    if (!section) {
        return;
    }

    const card = section.cards.find(c => c.cardId === cardResponse.cardId);

    if (!card) {
        return;
    }

    card.userContextCommentCount = 0;
}

export function add(cardResponse: BoardResponse<Card>, board: Board, store: Store, type?: string) {

    const section: BoardInfo = board.boardInfos.find(boardInfo => boardInfo.boardInfoId === cardResponse.boardInfoId);

    if (!section) {
        return;
    }


    cardResponse.data.map(_card => {

        if (_card.parentCardId) {

            const mainCard: Card = section.cards.find(c => c.cardId === _card.parentCardId);

            if (!mainCard.childCards) {

                mainCard.childCards = [];

            }

            mainCard.childCards.push(_card);

            mainCard.childCards.sort((a, b) => a.orderBy > b.orderBy ? 1 : -1);
            mainCard.cardUuid = uuidv4();

        } else {

            if (!section.cards) {

                section.cards = [];

            }

            if (type === BoardGroupType.Kanban) {

                if (section.cards.length === 0 || section.cards.length % SECTION_BOARDINFO_CARD_SIZE !== 0 || section.page >= section.cardPageCount) {

                    _card.cardUuid = uuidv4();
                    section.cards.push(_card);
                    section.cards = section.cards.filter(c => !!c.cardId);
                    ++section.cardCount;

                    return;

                }

                const lastCard = section.cards[section.cards.length - 1];

                if (lastCard.orderBy > _card.orderBy) {

                    _card.cardUuid = uuidv4();
                    section.cards.push(_card);
                    section.cards = section.cards.filter(c => !!c.cardId);
                    ++section.cardCount;

                    return;

                }

            } else {

                if (section.cards.length % 50 !== 0 || section.cardCount === section.cards.length) {
                    const cardExists = checkCardExisting(section, _card);
                    if (cardExists) {
                        return;
                    }

                    _card.cardUuid = uuidv4();
                    section.cards.push(_card);
                    section.cards = section.cards.filter(c => !!c.cardId || c.checkIsCardAdd);
                    ++section.cardCount;
                    ++section.totalCardCount;

                    return;

                }

                const lastCard = section.cards[section.cards.length - 1];

                if (lastCard.orderBy > _card.orderBy) {

                    _card.cardUuid = uuidv4();
                    section.cards.push(_card);
                    section.cards = section.cards.filter(c => !!c.cardId || c.checkIsCardAdd);
                    ++section.cardCount;
                    ++section.totalCardCount;

                    return;

                }

            }
        }
    });

    section.cards.sort((a, b) => a.orderBy > b.orderBy ? 1 : -1);

    store.dispatch(new GetBoardDetailCreatedUsers(board.boardId));

}

function checkCardExisting(section, _card) {
    const existingCards = section.cards.filter(c => c.name === _card.name && c.boardInfoId === _card.boardInfoId && c.checkIsCardAdd);

    if (existingCards.length > 0) {
        const cardWithoutId = existingCards.find(c => !c.cardId);
        if (cardWithoutId) {
            section.cards = section.cards.filter(c => c !== cardWithoutId);
        } else {
            return true;
        }
    }
    return false;
}

export function cardDelete(cardResponse: BoardResponse<Card>, board: Board, store: Store) {

    const section: BoardInfo = board.boardInfos.find(boardInfo => boardInfo.boardInfoId === cardResponse.boardInfoId);

    if (!section) {
        return;
    }

    cardResponse.data.map(_card => {

        if (_card.parentCardId) {

            const mainCard: Card = section.cards.find(c => c.cardId === _card.parentCardId);
            const subCardIndex: number = mainCard.childCards.findIndex(c => c.cardId === _card.cardId);

            if (subCardIndex !== -1) {

                mainCard.childCards.splice(subCardIndex, 1);

            }

        } else {

            const cardIndex: number = section.cards.findIndex(c => c.cardId === _card.cardId);

            if (cardIndex !== -1) {

                section.cards.splice(cardIndex, 1);

                --section.cardCount;
                --section.totalCardCount;
                if (_card.cardStatusId === CardStatusOption.COMPLETED) {
                    --section.completedCardCount;
                }
            }

            updateSectionTimeline(section, _card);
        }
    });

    store.dispatch(new GetBoardDetailAssignedUsers(board.boardId));

}

export function nameUpdate(cardResponse: BoardResponse<Card>, board: Board) {

    const section: BoardInfo = board.boardInfos.find(boardInfo => boardInfo.boardInfoId === cardResponse.boardInfoId);

    if (!section) {
        return;
    }

    cardResponse.data.map(_card => {

        if (_card.parentCardId) {

            const mainCard: Card = section.cards.find(c => c.cardId === _card.parentCardId);
            const subCard = mainCard.childCards.find(c => c.cardId === _card.cardId);

            if (subCard) {

                subCard.name = _card.name;

            }

        } else {

            const card: Card = section.cards.find(c => c.cardId === _card.cardId);

            if (card) {

                card.name = _card.name;

            }

        }
    });
}

export function setOrder(cardResponse: BoardResponse<Card>, board: Board, currentFilter?: SocketSearchRequest, type?: string, request?: SocketSearchRequest, hubConnection?: signalR.HubConnection) {

    const section: BoardInfo = board.boardInfos.find(boardInfo => boardInfo.boardInfoId === cardResponse.boardInfoId);

    if (!section) {
        return;
    }

    const cardCountForSection = section.cards.length;

    // eğer section'ın sonundaki kart taşınır ise burası çalışmaktadır. Eski section'ın timeline'ı güncellenmektedir.
    if (cardResponse.data.length > 0 && cardResponse.data[0].cardId === Guid.EMPTY) {
        updateSectionTimeline(section, cardResponse.data[0]);
        return;
    }

    cardResponse.data.map(_card => {

        if (_card.parentCardId) {

            const mainCard: Card = section.cards.find(c => c.cardId === _card.parentCardId);
            const subCard = mainCard.childCards.find(c => c.cardId === _card.cardId);

            if (subCard) {

                subCard.orderBy = _card.orderBy;

            }

        } else {
            updateSectionTimeline(section, _card); // eski section'ın timeline'ı güncellenmektedir.

            const card = section.cards.find(c => c.cardId === _card.cardId);

            if (card) {

                card.orderBy = _card.orderBy;

                return;

            }

            const lastCardInSection = section.cards[section.cards.length - 1];

            if (
                !lastCardInSection || _card.orderBy <= lastCardInSection.orderBy + 1 &&
                ((type === BoardGroupType.Kanban && section.cardCount <= SECTION_BOARDINFO_CARD_SIZE) ||
                    ((type === BoardGroupType.Listview || type === BoardGroupType.Timeline) && section.cardCount <= 50))
            ) {

                // section.cards.push(_card);

                const cardRequest = {
                    cardId: _card.cardId,
                    boardViewType: type
                };
                hubConnection.invoke(CardActionType.CardGet, cardRequest);

                return;
            }
        }
    });

    if (type === BoardGroupType.Kanban) {

        section.cards.sort((a, b) => a.orderBy > b.orderBy ? 1 : -1);
        if (section.page >= request.page) {

            section.cards = section.cards.slice(0, ((section.page ?? 1) * SECTION_BOARDINFO_CARD_SIZE));
        }

    } else {

        section.cards.sort((a, b) => a.orderBy > b.orderBy ? 1 : -1);
        section.cards = section.cards.slice(0, (request.page * 50));

    }

    section.cards.map(card => {
        card.childCards?.sort((a, b) => a.orderBy > b.orderBy ? 1 : -1);
    });

    const clone = section.cards.length > 0 ? section.cards[0] : null;

    if (cardResponse.data.length > 1) {
        applyFilter(section, currentFilter);
    }

    if (clone) {
        let isInclude = false;
        for (const card of section.cards) {
            if (card.cardId === clone.cardId) {
                isInclude = true;
                return;
            }
        }
        if (!isInclude) {
            section.cards.unshift(clone);
        }
    }

}

export function childCardSetOrder(cardResponse: BoardResponse<Card>, board: Board) {
    let card: Card;

    cardResponse.data.map(cardItem => {
        const section: BoardInfo = board.boardInfos.find(boardInfo => boardInfo.boardInfoId === cardItem.boardInfoId);

        if (!section) {
            return;
        }

        card = section.cards.find(sectionCard => sectionCard.cardId === cardResponse.cardId);

        card.cardUuid = uuidv4();

        card.childCards.map(child => {
            child.orderBy = cardResponse.data.find(c => c.cardId === child.cardId)?.orderBy ?? child.orderBy;
            return child;
        });
        card.childCards.sort((a, b) => a.orderBy > b.orderBy ? 1 : -1);
    });
}

export function sectionChange(cardResponse: BoardResponse<Card>, board: Board) {

    const oldSection: BoardInfo = board.boardInfos.find(boardInfo => boardInfo.boardInfoId === cardResponse.boardInfoId);

    cardResponse.data.filter(_card => {

        const newSection: BoardInfo = board.boardInfos.find(boardInfo => boardInfo.boardInfoId === _card.boardInfoId);

        const cardIndex = oldSection?.cards?.findIndex(c => c.cardId === _card.cardId);

        let card = oldSection?.cards?.find(c => c.cardId === _card.cardId) ?? newSection?.cards.find(c => c.cardId === _card.cardId);

        if (cardIndex !== undefined && cardIndex !== -1) {

            oldSection.cards = [...oldSection.cards.filter(c => c.cardId !== _card.cardId)];
        }

        if (!newSection) {
            return;
        }

        if (oldSection) {
            oldSection.boardInfoUuid = uuidv4();

            oldSection.cardCount--;
            oldSection.totalCardCount--;
            if (_card.cardStatusId === CardStatusOption.COMPLETED) {
                oldSection.completedCardCount--;
            }
        }

        if (newSection && !newSection?.cards?.find(c => c.cardId === card?.cardId)) {

            card = {
                ...card,
                boardInfo: _card.boardInfo,
                boardInfoId: _card.boardInfoId,
                cardUuid: uuidv4()
            };

            _card = {
                ..._card,
                cardUuid: uuidv4()
            };

            if (card?.cardId) {
                newSection.boardInfoUuid = uuidv4();
                newSection.cards.push(card);
                newSection.cardCount++;
                newSection.totalCardCount++;
                if (_card.cardStatusId === CardStatusOption.COMPLETED) {
                    newSection.completedCardCount++;
                }
            }

            updateSectionTimeline(newSection, _card); // burada yeni section'ın timeline'ı güncellenmektedir. Eski section setOrder'da güncellenmektedir.
        }
    });
}

export function childCardAdd(cardResponse: BoardResponse<Card>, board: Board) {

    let card;

    cardResponse.data.map(_card => {

        const section: BoardInfo = board.boardInfos.find(boardInfo => boardInfo.boardInfoId === _card.boardInfoId);

        if (!section) {
            return;
        }

        card = section.cards.find(c => c.cardId === _card.parentCardId);

        if (!card) {
            return;
        }

        if (!card.childCards) {
            card.childCards = [];
        }

        if (card.childCards.length > 0 && card.childCards[card.childCards.length - 1].cardId !== _card.cardId) {
            card.childCards.push(_card);
            card.cardUuid = uuidv4();
        }

        card.childCards = card.childCards.filter(x => !!x.cardId);
    });

}

export function childCardCountUpdate(cardResponse: BoardResponse<Card>, board: Board) {

    cardResponse.data.map(_card => {

        const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === _card.boardInfoId);

        if (!section) {
            return;
        }

        const card = section.cards.find(c => c.cardId === _card.cardId);

        if (!card) {
            return;
        }

        card.childCardsCount = _card.childCardsCount;

        card.cardUuid = uuidv4();
    });

}

export function dueDateUpdate(cardResponse: BoardResponse<Card>, board: Board, currentFilter?: SocketSearchRequest) {
    let isWithinDateRange = true;
    cardResponse.data.map(_card => {
        if (currentFilter && currentFilter.filter?.cardDueDateMin && currentFilter.filter?.cardDueDateMax) {
            if (!checkDateIsWithinRange(moment(_card.dueDate), currentFilter.filter.cardDueDateMin, currentFilter.filter.cardDueDateMax)) {
                isWithinDateRange = false;
            }
        }

        const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === _card.boardInfoId);

        if (!section) {
            return;
        }

        if (_card.parentCardId) {

            const mainCard: Card = section.cards.find(c => c.cardId === _card.parentCardId);
            const subCardIndex = mainCard.childCards.findIndex(c => c.cardId === _card.cardId);

            if (subCardIndex !== -1) {

                mainCard.childCards[subCardIndex].dueDate = _card.dueDate;
                mainCard.childCards[subCardIndex].startDate = _card.startDate;
                mainCard.childCards[subCardIndex].cardUuid = uuidv4();

                if (!isWithinDateRange) {
                    mainCard.childCards.splice(subCardIndex, 1);
                }

            }

        } else {

            const cardIndex = section.cards.findIndex(c => c.cardId === _card.cardId);

            if (cardIndex !== -1) {

                section.cards[cardIndex].dueDate = _card.dueDate;
                section.cards[cardIndex].startDate = _card.startDate;
                section.cards[cardIndex].cardUuid = uuidv4();

                if (!isWithinDateRange) {
                    section.cards.splice(cardIndex, 1);
                }
            }

            updateSectionTimeline(section, _card);
        }

    });
}

export function reminderDateUpdate(cardResponse: BoardResponse<Card>, board: Board, currentFilter?: SocketSearchRequest) {
    let isWithinDateRange = true;
    cardResponse.data.map(_card => {
        if (currentFilter && currentFilter.filter?.cardReminderDateMin && currentFilter.filter?.cardReminderDateMax) {
            if (!checkDateIsWithinRange(moment(_card.reminderDate), currentFilter.filter.cardReminderDateMin, currentFilter.filter.cardReminderDateMax)) {
                isWithinDateRange = false;
            }
        }

        const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === _card.boardInfoId);

        if (!section) {
            return;
        }

        if (_card.parentCardId) {

            const mainCard: Card = section.cards.find(c => c.cardId === _card.parentCardId);
            const subCardIndex = mainCard.childCards.findIndex(c => c.cardId === _card.cardId);


            if (subCardIndex !== -1) {

                mainCard[subCardIndex].reminderDate = _card.reminderDate;
                mainCard[subCardIndex].cardUuid = uuidv4();

                if (!isWithinDateRange) {
                    mainCard.childCards.splice(subCardIndex, 1);
                }

            }

        } else {

            const cardIndex = section.cards.findIndex(c => c.cardId === _card.cardId);

            if (cardIndex !== -1) {

                section.cards[cardIndex].reminderDate = _card.reminderDate;
                section.cards[cardIndex].cardUuid = uuidv4();
                if (!isWithinDateRange) {
                    section.cards.splice(cardIndex, 1);
                }
            }
        }
    });
}

export function setComplete(cardResponse: BoardResponse<Card>, board: Board, currentFilter?: SocketSearchRequest) {
    let isVisible = true;
    cardResponse.data.map(_card => {

        if (currentFilter) {
            if (!checkCardComplete(_card.cardStatusId, currentFilter)) {
                isVisible = false;
            }
        }

        const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === _card.boardInfoId);

        if (!section) {
            return;
        }

        if (_card.parentCardId) {

            const mainCard: Card = section.cards.find(c => c.cardId === _card.parentCardId);
            const subCardIndex = mainCard.childCards.findIndex(c => c.cardId === _card.cardId);

            if (subCardIndex !== -1) {

                mainCard.childCards[subCardIndex].cardStatus = _card.cardStatus;
                mainCard.childCards[subCardIndex].cardStatusId = _card.cardStatusId;
                mainCard.childCards[subCardIndex].cardUuid = uuidv4();
            }

            if (_card.cardStatusId === CardStatusOption.COMPLETED) {
                mainCard.completedChildCardsCount++;
            } else {
                mainCard.completedChildCardsCount--;
            }
            mainCard.cardUuid = uuidv4();

        }
        else {
            const cardIndex = section.cards.findIndex(c => c.cardId === _card.cardId);

            if (cardIndex !== -1) {

                section.cards[cardIndex].cardStatus = _card.cardStatus;
                section.cards[cardIndex].cardStatusId = _card.cardStatusId;

                // if (_card.cardStatusId === CardStatusOption.COMPLETED) {
                //     section.completedCardCount++;
                // } else {
                //     section.completedCardCount--;
                // }

                section.cards[cardIndex].cardUuid = uuidv4();

                if (!isVisible) {
                    section.cards.splice(cardIndex, 1);
                }

            }

            if (_card.cardStatusId === CardStatusOption.COMPLETED) {
                section.completedCardCount++;
            } else {
                section.completedCardCount--;
            }
        }
    });
}

export function hashtagUpdate(cardResponse: BoardResponse<Card>, board: Board, currentFilter?: SocketSearchRequest) {
    let isVisible = true;
    cardResponse.data.map(_card => {
        if (currentFilter && currentFilter.filter?.boardHashtagInfoIds) {
            if (!checkHashtag(_card.cardHashtags, currentFilter.filter.boardHashtagInfoIds)) {
                isVisible = false;
            }
        }

        const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === _card.boardInfoId);

        if (!section) {
            return;
        }

        if (_card.parentCardId) {

            const mainCard: Card = section.cards.find(c => c.cardId === _card.parentCardId);
            const subCardIndex = mainCard.childCards.findIndex(c => c.cardId === _card.cardId);

            if (subCardIndex !== -1) {

                mainCard.childCards[subCardIndex].cardHashtags = _card.cardHashtags;
                mainCard.childCards[subCardIndex].cardBoardHashtags = _card.cardHashtags;
                mainCard.childCards[subCardIndex].cardUuid = uuidv4();
                if (!isVisible) {
                    mainCard.childCards.splice(subCardIndex, 1);
                }

            }

        } else {

            const cardIndex = section.cards.findIndex(c => c.cardId === _card.cardId);

            if (cardIndex !== -1) {

                section.cards[cardIndex].cardHashtags = _card.cardHashtags;
                section.cards[cardIndex].cardBoardHashtags = _card.cardHashtags;
                section.cards[cardIndex].cardUuid = uuidv4();
                if (!isVisible) {
                    section.cards.splice(cardIndex, 1);
                }

            }
        }

    });

}

export function assigneeUserUpdate(cardResponse: BoardResponse<Card>, board: Board, store: Store, currentFilter?: SocketSearchRequest) {
    let isIncludeUser = true;

    cardResponse.data.map(_card => {

        if (currentFilter && currentFilter.filter?.cardSystemUserIds) {
            if (!checkAssignedUserFilter(currentFilter.filter?.cardSystemUserIds, _card.cardSystemUsers)) {
                isIncludeUser = false;
            }
        }

        const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === _card.boardInfoId);

        if (!section) {
            return;
        }

        if (_card.parentCardId) {

            const mainCard: Card = section.cards.find(c => c.cardId === _card.parentCardId);
            const subCardIndex = mainCard.childCards.findIndex(c => c.cardId === _card.cardId);

            if (subCardIndex !== -1) {

                mainCard.childCards[subCardIndex].cardSystemUsers = _card.cardSystemUsers;
                mainCard.childCards[subCardIndex].cardUuid = uuidv4();

                if (!isIncludeUser) {
                    mainCard.childCards.splice(subCardIndex, 1);
                }
            }

        } else {

            const cardIndex = section.cards.findIndex(c => c.cardId === _card.cardId);

            if (cardIndex !== -1) {

                section.cards[cardIndex].cardSystemUsers = _card.cardSystemUsers;
                section.cards[cardIndex].cardUuid = uuidv4();

                if (!isIncludeUser) {
                    section.cards.splice(cardIndex, 1);
                }
            }
        }

    });

    store.dispatch(new GetBoardDetailAssignedUsers(board.boardId));

}

export function convertToSubcard(cardResponse: BoardResponse<Card>, board: Board) {
    const oldSection: BoardInfo = board.boardInfos.find(boardInfo => boardInfo.boardInfoId === cardResponse.boardInfoId);

    cardResponse.data.map(_card => {
        const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === _card.boardInfoId);

        if (section) {
            const parentCard = section.cards.find(c => c.cardId === _card.parentCardId);

            if (parentCard) {
                if (!parentCard.childCards) {
                    parentCard.childCards = [];
                }

                if (parentCard.showChildCards || parentCard.childCards.length > 0) {
                    const card = oldSection.cards.find(c => c.cardId === _card.cardId);
                    if (card) {
                        parentCard.childCards.push(card);

                    }
                }
                parentCard.childCardsCount += 1;
                parentCard.cardUuid = uuidv4();
                if (_card.cardStatusId === CardStatusOption.COMPLETED) {
                    parentCard.completedChildCardsCount++;
                }
            }
        }

        if (oldSection) {
            oldSection.cards = [...oldSection.cards.filter(c => c.cardId !== _card.cardId)];
            oldSection.totalCardCount--;
            if (_card.cardStatusId === CardStatusOption.COMPLETED) {
                oldSection.completedCardCount--;
            }
        }
    });
}

export function convertToParentCard(cardResponse: BoardResponse<Card>, board: Board) {

    cardResponse.data.map(_card => {

        const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === _card.boardInfoId);

        if (!section) {
            return;
        }
        _card.cardUuid = uuidv4();

        // Create card's instance to section as a parent card
        section.cards.push(_card);
        section.totalCardCount++;
        if (_card.cardStatusId === CardStatusOption.COMPLETED) {
            section.completedCardCount++;
        }

        // Remove card's instance from child card
        section.cards.forEach(parentCard => {
            const foundCardIndex = parentCard.childCards.findIndex(y => y.cardId === _card.cardId);
            if (foundCardIndex !== -1) {
                parentCard.childCards.splice(foundCardIndex, 1);
                parentCard.childCardsCount--;
                if (_card.cardStatusId === CardStatusOption.COMPLETED) {
                    parentCard.completedChildCardsCount--;
                }
                return false;
            }
        });

    });

}

export function attachmentAdd(cardResponse: BoardResponse<Card>, board: Board, currentFilter?: SocketSearchRequest) {
    let isVisible = true;
    const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === cardResponse.boardInfoId);

    if (!section) {
        return;
    }

    cardResponse.data.map(_card => {
        if (currentFilter) {
            if (!checkAttachment(_card, currentFilter.filter)) {
                isVisible = false;
            }
        }

        const cardIndex = section.cards.findIndex(c => c.cardId === _card.cardId);

        if (cardIndex === -1) {
            return;
        }

        _card.cardAttachments.map(attachment => {

            const attachmentIndex = section.cards[cardIndex].cardAttachments.findIndex(attach => attach.cardAttachmentId === attachment.cardAttachmentId);

            if (attachmentIndex === -1) {
                section.cards[cardIndex].cardAttachments.push(attachment);

            } else {
                section.cards[cardIndex].cardAttachments[attachmentIndex].isBase = attachment.isBase;
            }

            section.cards[cardIndex].cardUuid = uuidv4();
        });

        if (!isVisible) {
            section.cards.splice(cardIndex, 1);
        }

    });

}

export function attachmentUpdate(cardResponse: BoardResponse<Card>, board: Board) {

    const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === cardResponse.boardInfoId);

    if (!section) {
        return;
    }

    cardResponse.data.map(_card => {

        const card = section.cards.find(c => c.cardId === _card.cardId);

        if (!card) {
            return;
        }

        _card.cardAttachments.map(attachment => {

            const index = card.cardAttachments.findIndex(a => a.cardAttachmentId === attachment.cardAttachmentId);

            if (index !== -1) {

                card.cardAttachments[index].isBase = attachment.isBase;

            }
        });

        card.cardAttachments = [...card.cardAttachments];

        card.cardUuid = uuidv4();

    });

}

export function attachmentDelete(cardResponse: BoardResponse<Card>, board: Board, currentFilter?: SocketSearchRequest) {

    const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === cardResponse.boardInfoId);

    if (!section) {
        return;
    }

    cardResponse.data.map(_card => {
        let isVisible = true;
        if (currentFilter) {
            if (!checkAttachment(_card, currentFilter.filter, true)) {
                isVisible = false;
            }
        }

        const cardIndex = section.cards.findIndex(c => c.cardId === _card.cardId);

        if (cardIndex === -1) {
            return;
        }

        _card.cardAttachments.map(attachment => {

            const index = section.cards[cardIndex].cardAttachments.findIndex(a => a.cardAttachmentId === attachment.cardAttachmentId);

            if (index !== -1) {

                section.cards[cardIndex].cardAttachments.splice(index, 1);

                section.cards[cardIndex].cardAttachments = [...section.cards[cardIndex].cardAttachments];
                section.cards[cardIndex].cardUuid = uuidv4();
            }
        });

        if (!isVisible) {
            section.cards.splice(cardIndex, 1);
        }

    });
}

export function attachmentCountUpdate(cardResponse: BoardResponse<Card>, board: Board) {

    const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === cardResponse.boardInfoId);

    if (!section) {
        return;
    }

    cardResponse.data.map(_card => {

        const card = section.cards.find(c => c.cardId === _card.cardId);

        if (!card) {
            return;
        }
        card.attachmentCount = _card.attachmentCount;

        card.cardUuid = uuidv4();

    });

}

export function commentAdd(cardResponse: BoardResponse<Card>, board: Board) {

    const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === cardResponse.boardInfoId);

    if (!section) {
        return;
    }

    cardResponse.data.map(_card => {

        const card = section.cards.find(c => c.cardId === _card.cardId);

        if (!card) {
            return;
        }

        card.cardUuid = uuidv4();


    });

}

export function commentDelete(cardResponse: BoardResponse<Card>, board: Board) {

    const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === cardResponse.boardInfoId);

    if (!section) {
        return;
    }

    cardResponse.data.map(_card => {

        const card = section.cards.find(c => c.cardId === _card.cardId);

        if (!card) {
            return;
        }

        card.cardUuid = uuidv4();

    });

}

export function commentCountUpdate(cardResponse: BoardResponse<Card>, board: Board, type?: string) {

    const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === cardResponse.boardInfoId);

    if (!section) {
        return;
    }

    cardResponse.data.map(_card => {
        if (_card.parentCardId) {
            const parentCard = section.cards.find(card => card.cardId === _card.parentCardId);
            parentCard.childCards.map(childCard => {
                if (childCard.cardId === _card.cardId) {
                    childCard.commentCount = _card.commentCount;

                    return;
                }
            });
        } else {
            const card = section.cards.find(c => c.cardId === _card.cardId) ?? section.cards.find(c => c.cardId === cardResponse.cardId);

            if (!card) {
                return;
            }

            if (type === BoardGroupType.UserComment) {
                card.userContextCommentCount = _card.userContextCommentCount;

                return;
            }

            card.commentCount = _card.commentCount;
            card.cardUuid = uuidv4();
        }
    });
}

export function objectsUpdate(cardResponse: BoardResponse<Card>, board: Board, currentFilter?: SocketSearchRequest) {

    const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === cardResponse.boardInfoId);

    if (!section) {
        return;
    }

    cardResponse.data.map(_card => {
        let isVisible = true;

        const cardIndex = section.cards.findIndex(c => c.cardId === _card.cardId);

        if (cardIndex === -1) {
            return;
        }

        if (currentFilter) {
            if (!checkCRMLinks(_card.cardObjects, currentFilter)) {
                isVisible = false;
            }
        }

        section.cards[cardIndex].cardObjects = _card.cardObjects;
        section.cards[cardIndex].cardUuid = uuidv4();

        if (!isVisible) {
            section.cards.splice(cardIndex, 1);
        }
    });

}

export function descriptionUpdate(cardResponse: BoardResponse<Card>, board: Board, currentFilter?: SocketSearchRequest) {
    let isVisible = true;

    cardResponse.data.map(_card => {
        const section: BoardInfo = board?.boardInfos?.find(boardInfo => boardInfo.boardInfoId === _card.boardInfoId);

        if (!section) {
            return;
        }
        if (currentFilter) {
            if (!checkDescription(_card.description, currentFilter.filter?.description)) {
                isVisible = false;
            }
        }

        if (_card.parentCardId) {

            const mainCard: Card = section.cards.find(c => c.cardId === _card.parentCardId);
            const subCardIndex = mainCard.childCards.findIndex(c => c.cardId === _card.cardId);

            if (subCardIndex !== -1) {

                mainCard[subCardIndex].description = getDescription(_card.description);
                mainCard[subCardIndex].cardUuid = uuidv4();

                if (!isVisible) {
                    mainCard.childCards.splice(subCardIndex, 1);
                }
            }

        } else {

            const cardIndex = section.cards.findIndex(c => c.cardId === _card.cardId);

            if (cardIndex !== -1) {

                section.cards[cardIndex].description = getDescription(_card.description);
                section.cards[cardIndex].cardUuid = uuidv4();

                if (!isVisible) {
                    section.cards.splice(cardIndex, 1);
                }
            }
        }
    });

}

function updateSectionTimeline(section: BoardInfo, card: Card) {
    section.startDueDate = card.boardInfo?.startDueDate ?? section.startDueDate;
    section.endDueDate = card.boardInfo?.endDueDate ?? section.endDueDate;
}
