import { Pipe, PipeTransform } from '@angular/core';
import { Activity } from '@core/api';

@Pipe({
  name: 'activityParticipants',
  standalone: true
})
export class ActivityParticipantsPipe implements PipeTransform {

  transform(row: Activity, joinChar = '; '): string {
    const items: string[] = [];

    if (row.activityContacts) {
      row.activityContacts.forEach(activityContact => {
        items.push([
          activityContact.contact.firstName,
          activityContact.contact.middleName,
          activityContact.contact.lastName
        ].join(' '));
      });
    }

    if (row.activitySystemUsers) {
      row.activitySystemUsers.forEach(activitySystemUser => {
        items.push([
          activitySystemUser.systemUser.firstName,
          activitySystemUser.systemUser.lastName
        ].join(' '));
      });
    }

    return items.join(joinChar);
  }

}
