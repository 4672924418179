import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { BaseResponse, FilterRequest, ListCreatedUser, PagedResponse, Response } from '@core/api';
import { AuthorizedUsersUpdateRequest, ChannelRequest, ChannelType, CustomerExperienceChannel, UserAuthCxChannelListRequest } from './customer-experience-channel-settings.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CustomerExperienceChannelSettingsService {
    constructor(private http: HttpClient) {
    }

    get(request: string) {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<CustomerExperienceChannel>>(environment.apiUrl + '/api/CxChannel/Get', JSON.stringify(request), { headers });
    }

    insert(request: ChannelRequest) {
        return this.http.post<Response<any>>(environment.apiUrl + '/api/CxChannel/Insert', request);
    }

    setStatus(request: { cxChannelId: string, activeFlag: boolean }) {
        return this.http.post<Response<any>>(environment.apiUrl + '/api/CxChannel/SetStatus', request);
    }

    delete(request: { cxChannelId: string }) {
        return this.http.post<BaseResponse>(environment.apiUrl + '/api/CxChannel/Delete', request);
    }

    updateAuthorizedUsers(request: AuthorizedUsersUpdateRequest) {
        return this.http.post<Response<CustomerExperienceChannel>>(environment.apiUrl + '/api/CxChannel/UpdateAuthorizedUsers', request);
    }

    update(request: ChannelRequest) {
        return this.http.post<Response<any>>(environment.apiUrl + '/api/CxChannel/Update', request);
    }

    search(request: any) {
        return this.http.post<PagedResponse<any>>(environment.apiUrl + '/api/CxChannel/Search', request);
    }

    getChannelTypes() {
        return this.http.post<Response<ChannelType[]>>(environment.apiUrl + '/api/CxChannel/CxChannelTypeList', {});
    }

    getUserAuthCxChannelList(request: UserAuthCxChannelListRequest) {
        return this.http.post<Response<CustomerExperienceChannel[]>>(environment.apiUrl + '/api/CxChannel/UserAuthCxChannelList', request);
    }

    listCreatedUsers(filter?: FilterRequest): Observable<Response<ListCreatedUser[]>> {
        return this.http.post<Response<ListCreatedUser[]>>(environment.apiUrl + '/api/CxChannel/ListCreatedUsers', filter);
    }

    loginMeta(cxChannelId: string, cxChannelTypeId: string, connectionId: string) {
        const params = {
            cxChannelId,
            cxChannelTypeId,
            connectionId,
        };

        return this.http.get<Response<string>>(`${environment.apiUrl}/api/CxChannel/GetFacebookLoginUrl`, { params }).pipe(map(response => response.data));
    }

}
