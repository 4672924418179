import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    CustomerExperienceAssignTicket, CustomerExperienceAssignTicketDeleteRequest, CustomerExperienceAssignTicketInsertRequest,
    CustomerExperienceAssignTicketSearchRequest, CustomerExperienceAssignTicketSetOrderRequest, CustomerExperienceAssignTicketSetStatusRequest,
    CustomerExperienceAssignTicketUpdateRequest, FilterRequest, ListCreatedUser, PagedResponse, Response
} from '@core/api';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CustomerExperienceAssignTicketService {

    constructor(
        private http: HttpClient
    ) { }

    search(request: CustomerExperienceAssignTicketSearchRequest): Observable<PagedResponse<CustomerExperienceAssignTicket>> {
        return this.http.post<PagedResponse<CustomerExperienceAssignTicket>>(environment.apiUrl + '/api/CxAssignTicketRule/Search', request);
    }

    insert(request: CustomerExperienceAssignTicketInsertRequest): Observable<Response<CustomerExperienceAssignTicket>> {
        return this.http.post<Response<CustomerExperienceAssignTicket>>(environment.apiUrl + '/api/CxAssignTicketRule/Insert', request);
    }

    update(request: CustomerExperienceAssignTicketUpdateRequest): Observable<Response<CustomerExperienceAssignTicket>> {
        return this.http.post<Response<CustomerExperienceAssignTicket>>(environment.apiUrl + '/api/CxAssignTicketRule/Update', request);
    }

    setStatus(request: CustomerExperienceAssignTicketSetStatusRequest): Observable<Response<CustomerExperienceAssignTicket>> {
        return this.http.post<Response<CustomerExperienceAssignTicket>>(environment.apiUrl + '/api/CxAssignTicketRule/SetStatus', request);
    }

    delete(request: CustomerExperienceAssignTicketDeleteRequest): Observable<Response<CustomerExperienceAssignTicket>> {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<CustomerExperienceAssignTicket>>(environment.apiUrl + '/api/CxAssignTicketRule/Delete',
            JSON.stringify(request.cxAssignTicketRuleId), { headers });
    }

    get(uuid: string): Observable<Response<CustomerExperienceAssignTicket>> {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<CustomerExperienceAssignTicket>>(environment.apiUrl + '/api/CxAssignTicketRule/Get', JSON.stringify(uuid), { headers });
    }

    setOrderBy(request: CustomerExperienceAssignTicketSetOrderRequest): Observable<Response<CustomerExperienceAssignTicket>> {
        return this.http.post<Response<CustomerExperienceAssignTicket>>(environment.apiUrl + '/api/CxAssignTicketRule/SetOrderBy', request);
    }

    listCreatedUsers(filter?: FilterRequest): Observable<Response<ListCreatedUser[]>> {
        return this.http.post<Response<ListCreatedUser[]>>(environment.apiUrl + '/api/CxAssignTicketRule/ListCreatedUsers', filter);
    }

}
