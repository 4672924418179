import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Address, AddressRequest, Customer, AddressService } from '@core/api';
import { AddressDialogData } from './address-form-dialog.component.model';
import { TranslateService } from '@ngx-translate/core';
import { AddressFormComponent } from '../address-form/address-form.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'net-address-form-dialog',
  templateUrl: './address-form-dialog.component.html',
  styleUrls: ['./address-form-dialog.component.scss'],
  standalone: true,
  imports: [
    AddressFormComponent
  ]
})
export class AddressFormDialogComponent {

  customer: Customer;
  address: Address;

  loading = false;
  formChanged = false;
  translations: { [key: string]: string };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AddressDialogData,
    private dialogRef: MatDialogRef<AddressFormDialogComponent>,
    private addressService: AddressService,
    private translate: TranslateService
  ) {
    if (data && data.customer) {
      this.customer = data.customer;
    }

    if (data && data.address) {
      this.address = data.address;
    } else {
      this.address = null;
    }

    translate.get(['GENERAL']).subscribe(translations => {
      this.translations = {
        ...translations.GENERAL,
      };
    });
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseDialog();
    });
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.confirmCloseDialog();
      }
    });

  }

  onSave(request: AddressRequest) {
    this.loading = true;

    if (this.address) {
      request.addressId = this.address.addressId;

      this.addressService.update(request).subscribe(
        response => {
          this.dialogRef.close(response.data);
        }
      ).add(() => this.loading = false);
      return;
    }
    request.enabled = true;
    this.addressService.save(request).subscribe(
      response => {
        this.dialogRef.close(response.data);
      }
    ).add(() => this.loading = false);
  }


  closeAddressFormDialog() {
    this.confirmCloseDialog();
  }
  onFormChanged(changed: boolean) {
    this.formChanged = changed;
  }
  confirmCloseDialog() {
    if (this.formChanged) {
      Swal.fire({
        text: this.translations?.PAGE_LEAVE_WITHOUT_SAVING,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F44336',
        cancelButtonColor: '#7a7a7a',
        confirmButtonText: this.translations?.YES,
        cancelButtonText: this.translations?.NO
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

}
